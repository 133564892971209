import { useEffect, useState } from 'react';
import Calendar from 'react-awesome-calendar';

function CalendarView ({data, handleCardClick, update}) {
    type calendarDataType = {
        id: number,
        title: any,
        description: any,
        color: string,
        from: string,
        to: string
    };
    var moment = require('moment');
    const [calendarData, setCalendarData] = useState<any>({})
    const generateCalendarData = (arrData) => {
        let calendarData: Array<calendarDataType> = [];
        arrData.map((item, ind) => {
            //var temp = moment.parseZone(item.dueTime+'z').local().format();
            let tempTaskObj = {
            "id": item.id,
            "title": <div onClick={() => handleCardClick(item)}>{item.taskTitle}</div>,
            "description": <div onClick={() => handleCardClick(item)}>{item.taskDescription}</div>,
            "color": '#3694DF',
            "from": item.dueTime,//moment.parseZone(temp).utc(true).format(),
            "to": item.dueTime//moment.parseZone(temp).utc(true).format()
        }
        calendarData.push(tempTaskObj);
        return null
        });

        setCalendarData(calendarData)
    }
    useEffect(() => {
        generateCalendarData(data)
    }, [data,update])

    return (
        <Calendar
            events={calendarData}
        />
    );
}

export default CalendarView;