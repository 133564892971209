import { boolean } from "yup/lib/locale";
import {MajorContactInfo} from "./Contact";

export default class TaskTemplate {
    public id:number;
    public caseId?:number;
    public templateTitle:string;
    public templateDescription:string;
    public ownerContact?:MajorContactInfo;
    public ownerContactDisplayName?:MajorContactInfo;
    public insurerId?:number;
    public isPrivate?:string;   

    public createdBy?: string;
    public modifiedBy?: string;
    public created?: Date;
    public modified?: Date;

    public isDeleting?:boolean;

    constructor(id:number,templateTitle:string,templateDescription:string,ownerContact?:MajorContactInfo,insurerId?:number,isPrivate?:string, created?: Date, modifiedBy?: string, createdBy?: string, modified?: Date,caseId?:number){        
        this.id=id;
        this.caseId=caseId;
        this.templateTitle=templateTitle;
        this.templateDescription=templateDescription;
        this.ownerContact=ownerContact;
        this.insurerId=insurerId;
        this.isPrivate=isPrivate;
        
        
        this.createdBy=createdBy;
        this.created=created;
        this.modifiedBy=modifiedBy;
        this.modified=modified;


    }

}
export interface ITaskTemplateViewModel {
    id:number;
    caseId?:number;
    templateTitle:string;
    templateDescription:string;
    ownerContact?:MajorContactInfo;
    ownerContactDisplayName?:string;
    insurerId?:number;
    isPrivate?:string;   
    isDeleting?:boolean;

    createdBy?: string;
    modifiedBy?: string;
    created?: Date;
    modified?: Date;
}

export const mapTaskTemplateToVieModel = (data: TaskTemplate): ITaskTemplateViewModel => {

    return {
        id: data.id,
        caseId: data.caseId,
        templateTitle: data.templateTitle,
        templateDescription: data.templateDescription,
        ownerContact: data.ownerContact,
        insurerId: data.insurerId,
        ownerContactDisplayName:data.ownerContactDisplayName?.displayName,
        isPrivate:data.isPrivate,
        createdBy: data.createdBy,
        created: data.created,
        modifiedBy: data.modifiedBy,
        modified: data.modified,
        isDeleting:data.isDeleting

    }
}