import { useState, useEffect, useContext } from 'react';
import { CaseInfoCard } from '../../components/CaseInfoCard';
import { alertService, caseManagerService, clientService, doctorService, employeerService } from '../../services/Index';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulbOn, faThumbsUp, faBallotCheck, faCalendarAlt } from '@fortawesome/pro-light-svg-icons'
import { useLocation, useParams } from 'react-router-dom';
import DashboardLineChart from '../../components/charts/DashboardLineChart';
import CaseTrendLineChart from '../../components/charts/CaseTrendLineChart';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';

const Dashboard=()=> {
    const { id} = useParams();
    const { t } = useContext(MultiLanguageSupportContext);
    const [caseItem, setCase] = useState<any>();
    const [healthInfo, sethealthInfo] = useState<any>();
    const { authUserData, setAuthUserData } = useContext(LoggedUserDataContext);
    const { setCaseData } = useContext(CaseDataContext);
    
    useEffect(() => {
            var caseDetails = caseManagerService.getSingleCaseWithDetails(parseInt(id!));
            var feedbackCaseInfo = employeerService.getCaseFeedbackMajorInfo(parseInt(id!));
            var assessementInfo = doctorService.getCaseAssessementMajorInfo(parseInt(id!));
            var reflectionInfo = clientService.getCaseReflectionMajorInfo(parseInt(id!));
            Promise.all([caseDetails, feedbackCaseInfo, assessementInfo, reflectionInfo]).then(async (responses) => {
                setCase(responses[0]);
                sethealthInfo({ feedback: responses[1], assessement: responses[2], reflection: responses[3] });
                setAuthUserData(
                    {
                        ...authUserData,
                        selectedCaseId: parseInt(id!),
                        referenceNumberOfInsurance: responses[0].referenceNumberOfInsurance!
                    });
                setCaseData(
                    {
                        selectedCaseId: responses[0].caseData.id,
                        caseNumber: responses[0].caseNumber,
                        caseClienName: responses[0].clientData ? responses[0].clientData.displayName : '',
                        caseClient: responses[0].clientData,
                        caseDoctor: responses[0].doctorData,
                        caseEmployeer: responses[0].employeerData,
                        caseCaseManager: responses[0].caseManagerData,
                        caseInsurerReference: responses[0].caseData.referenceNumberOfInsurance,
                        insurerProfileImage: responses[0].insurerProfileImage,
                        startOfIntegration:responses[0].caseData.startOfIntegration,
                        incapacityOfWork:responses[0].caseData.incapacityOfWork,
                        insurerId:responses[0].caseData.insurerId

                    });
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }, []);

    return (
        <div className="container">
            {!caseItem && <div className="loader">{t("ALL.Common.Loading")}</div>}
            {caseItem && healthInfo && <><CaseInfoCard caseItem={caseItem} />
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card-stats">
                            <div className="card-header card-header-warning card-header-icon">
                                <div className="card-icon">
                                    <FontAwesomeIcon icon={faLightbulbOn} />
                                </div>
                                <p className="card-category" style={{ color: "#E97932" }}>{t("ALL.DashBoard.ReflectionAddedTitle")}</p>
                                <h3 className="card-title">{healthInfo.reflection.amountOf}</h3>
                            </div>
                            <div className="card-footer">
                                <div className="stats">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                    <span style={{ marginTop: "-5px", marginLeft: "5px" }}>{t("ALL.DashBoard.LastModfiedAt")} {healthInfo.reflection.lastFillDate} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card-stats">
                            <div className="card-header card-header-success card-header-icon">
                                <div className="card-icon">
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                </div>
                                <p className="card-category" style={{ color: "#76ABBC" }}>{t("ALL.DashBoard.FeedbackAddedTitle")}</p>
                                <h3 className="card-title">{healthInfo.feedback.amountOf}</h3>
                            </div>
                            <div className="card-footer">
                                <div className="stats">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                    <span style={{ marginTop: "-5px", marginLeft: "5px" }}>{t("ALL.DashBoard.LastModfiedAt")} {healthInfo.feedback.lastFillDate} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card-stats">
                            <div className="card-header card-header-danger card-header-icon">
                                <div className="card-icon">
                                    <FontAwesomeIcon icon={faBallotCheck} />
                                </div>
                                <p className="card-category" style={{ color: "#B0C15B" }}>{t("ALL.DashBoard.AssessmentAddedTitle")}</p>
                                <h3 className="card-title">{healthInfo.assessement.amountOf}</h3>
                            </div>
                            <div className="card-footer">
                                <div className="stats">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                    <span style={{ marginTop: "-5px", marginLeft: "5px" }}>{t("ALL.DashBoard.LastModfiedAt")} {healthInfo.assessement.lastFillDate} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <DashboardLineChart />
                </div>
                <div className="row">
                    <CaseTrendLineChart caseId={parseInt(id!)} />
                </div>
            </>
            }
        </div>
    );

}

export { Dashboard };