import { useContext, useEffect, useState } from 'react';
import { caseManagerService, alertService } from '../../services/Index';

import { ManageGroupMembers } from '../../containers/casemanager/ManageGroupMembers';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
interface IListContactsProps{
    role: string
}
const ListContacts=({role}: IListContactsProps)=> {
    let navigate =useNavigate();
    const { authUserData } = useContext(LoggedUserDataContext);
    const { caseData } = useContext(CaseDataContext);
    const [caseItem, setCase] = useState<any>();
    const { t } = useContext(MultiLanguageSupportContext);
    useEffect(() => {
        var caseId=role === "Client" ? authUserData.currUserData.relatedData[0].id : caseData.selectedCaseId
        if (caseId&&caseId!==-1) {
            caseManagerService.getSingleCase(caseId).then(i => {
                setCase({
                    id: i.id,
                    insurerId: i.insurerId,
                    mainGoal: i.mainGoal,
                    referenceNumberOfInsurance: i.referenceNumberOfInsurance,
                    delayDays: i.delayDays,
                    dailyAllowanceAmount: i.dailyAllowanceAmount,
                    incapacityOfWork: i.incapacityOfWork,
                    endOfIntegration: i.endOfIntegration,
                    startOfIntegration: i.startOfIntegration,
                    endDailyAllowances: i.endDailyAllowances,
                    endOfCaseDesk: i.endOfCaseDesk,
                    caseDeskEnded: i.caseDeskEnded,
                    clnGroupOpenId: i.clnGroupOpenId,
                    docGroupOpenId: i.docGroupOpenId,
                    empGroupOpenId: i.empGroupOpenId,
                    camGroupOpenId: i.camGroupOpenId
                });
            }).catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true });
                    navigate("../")
                }
                
            );

        } else {
            navigate("../../");
        }

    }, []);
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-10 col-md-12">
                    <p className="component-header">{t("ALL.Contact.List")}</p>
                    <p><FontAwesomeIcon icon={faChevronLeft} /><Link to={role === "Client" ? "../" :caseData.selectedCaseId&&caseData.selectedCaseId!==-1 ?`/${authUserData.currUserData.roleType.toLowerCase()}/dashboard/${caseData.selectedCaseId}`:`/${authUserData.currUserData.roleType.toLowerCase()}`} className="back-btn">{t("ALL.Button.Back")}</Link></p>
                </div>
                <hr></hr>
            </div>
            <hr></hr>
            {!caseItem && <div className="loader">{t("ALL.Common.Loading")}</div>}
            {caseItem && <>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="card">
                            <div className='card-header  card-header-warning dark-header-top-doc'>
                                <h4 className="card-title">{t('ALL.Dictionary.Role.Doctor')}</h4>
                            </div>
                            <ManageGroupMembers groupId={caseItem.docGroupOpenId!} groupName="Doctor" caseId={caseItem.id} role={role}/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="card">
                            <div className='card-header card-header-warning dark-header-top-emp'>
                                <h4 className="card-title">{t('ALL.Dictionary.Role.Employeer')}</h4>
                            </div>
                            <ManageGroupMembers groupId={caseItem.empGroupOpenId!} groupName="Employeer" caseId={caseItem.id} role={role}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="card">
                            <div className='card-header card-header-warning dark-header-top-cam'>
                                <h4 className="card-title">{t('ALL.Dictionary.Role.CaseManager')}</h4>
                            </div>
                            <ManageGroupMembers groupId={caseItem.camGroupOpenId!} groupName="Case Manager" caseId={caseItem.id} role={role}/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="card">
                            <div className='card-header card-header-warning dark-header-top-cln'>
                                <h4 className="card-title">{t('ALL.Dictionary.Role.Client')}</h4>
                            </div>
                            <ManageGroupMembers groupId={caseItem.clnGroupOpenId!} groupName="Client" caseId={caseItem.id} role={role}/>
                        </div>
                    </div>
                </div>
            </>
            }
        </div>
    );
}

export { ListContacts };