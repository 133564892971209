import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { alertService, notificationService, tasksService } from '../../services/Index';
import {Task} from '../../models/Task';
import 'moment/min/locales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faIndustry, faShieldAlt, faStethoscope, faUserInjured } from '@fortawesome/pro-light-svg-icons'
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { NotificationContext } from '../../ctx/NotificationProvider';
import moment from 'moment';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';



export const ViewTask=() =>{
    const { caseData } = useContext(CaseDataContext);
    const { id, notId } = useParams();
    const { t } = useContext(MultiLanguageSupportContext);
    const [taskItem, setTask] = useState<Task>();
    const {notificationsDetails, setNotifications} = useContext(NotificationContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    moment.locale(authUserData.currUserData.profileLanguage);
    useEffect(() => {
        tasksService.getSingleTask(parseInt(id!)).then(rp => {
            setTask(rp);
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        if (notId !== "No") {
            notificationService.changeNotificationStatus(parseInt(notId!), "Read")
            setNotifications({
                ...notificationsDetails,
                notificationNumber:notificationsDetails.notificationNumber-1
            })
            //LogedUserProps.manageUserNotifications();
        }

    }, [notId]);


    return (<>
        {taskItem &&
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-md-12">
                        <div className="card display-card">
                            <div className="card-header card-header-warning header-top">
                                <h4 className="card-title">{t("ALL.Tasks.Title")}</h4>
                                <hr></hr>
                                <p className="card-category"><FontAwesomeIcon icon={faChevronLeft}/><Link to={notId !== "No" && caseData.caseNumber === undefined ?`/${authUserData.currUserData.rolePath}`:`/${authUserData.currUserData.rolePath}/tasks-management`} className="btn btn-md btn-icon-grey mr-1">{t("ALL.Button.Back")}</Link></p>
                            </div>
                            <div className="card-body">
                            <div className="form-row">
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("ALL.Tasks.DueDate")}:</label>
                                        <div>{moment(taskItem.dueTime).format("L")}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("ALL.Tasks.Title")}:</label>
                                        <div>{taskItem.taskTitle}</div>
                                    </div>
                                </div>
                                <div className="form-row">

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        }
    </>


    );
}
