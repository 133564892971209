import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext } from 'react';
import Feedback from '../../models/Feedback';
import { employeerService } from '../../services/casedesk/employeer/EmployeerService';

import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { alertService, caseManagerService, notificationService } from '../../services/Index';
import { Tabs, Tab } from "react-bootstrap";
import { addDays, addMonths } from 'date-fns';
import moment from 'moment';
import 'moment/min/locales';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import "bootstrap/js/src/collapse.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as rdrLocales from 'date-fns/locale';
import { helperFunctions } from '../../helpers/HelperFunctions';
import { StandardListHeader } from '../../components/list/StandardListHeader';
import { StandardReportHeader } from '../../components/list/StandardReportHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { NotificationContext } from '../../ctx/NotificationProvider';

const ListFeedback = () => {
    let navigate = useNavigate();
    const ref = React.createRef<HTMLDivElement>();
    const { authUserData } = useContext(LoggedUserDataContext);
    const { caseData } = useContext(CaseDataContext);
    moment.locale(authUserData.currUserData.profileLanguage);
    const { t } = useContext(MultiLanguageSupportContext);
    const [feedbacks, setFeedbacks] = useState<Feedback[]>();
    const [calendarIsShown, setCalendarVisibility] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    let { id, notId } = useParams();
    const {notificationsDetails, setNotifications} = useContext(NotificationContext);
    const caseId=id===undefined?caseData.selectedCaseId!:parseInt(id);
    const [calendarState, setCalendarState] = useState([
        {
            startDate: addMonths(new Date(), -3),
            endDate: addDays(new Date(), 1),
            key: 'selection'
        }
    ]);

    const [calendarLocale, setCalendarLocale] = useState<any>(rdrLocales.enAU);
    useEffect(() => {
        switch (authUserData.currUserData.profileLanguage.toLowerCase()) {
            case "en": setCalendarLocale(rdrLocales.enAU);
                break;
            case "de": setCalendarLocale(rdrLocales.de);
                break;
            case "fr": setCalendarLocale(rdrLocales.fr);
                break;
        }
        if (caseData.selectedCaseId === undefined && id===undefined) {
            navigate(`/${authUserData.currUserData.roleType.toLowerCase()}`);
        }
        else {
            employeerService.getCaseFeedbacksInDateRange(caseId, moment(calendarState[0].startDate).format('DD.MM.YYYY'), moment(calendarState[0].endDate).format('DD.MM.YYYY')).then(x => setFeedbacks(x)).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
        }
        if (notId !== undefined) {
            notificationService.changeNotificationStatus(parseInt(notId!), "Read");
            setNotifications({
                ...notificationsDetails,
                notificationNumber:notificationsDetails.notificationNumber-1
            })
        }
    }, [notId]);
    function changeDateRange(ranges: any) {
        console.log(ranges);
        setCalendarVisibility(!calendarIsShown);
        employeerService.getCaseFeedbacksInDateRange(caseData.selectedCaseId!, moment(ranges.selection.startDate).format('DD.MM.YYYY'), moment(ranges.selection.endDate).format('DD.MM.YYYY')).then(x => setFeedbacks(x)).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        setCalendarState([ranges.selection]);

    }

    function generateReport(checked) {
        setLoading(true);
        var content = document.getElementById("feedback")!.outerHTML;
        caseManagerService.generateRaport(content, checked, authUserData.currUserData.profileLanguage).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'FeedbackReport.pdf');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        });

    }
    return (<div className="container">
        <Tabs defaultActiveKey="home">
            <Tab eventKey="home" title={t("ALL.Common.ViewList")}>{!feedbacks &&
                <div className="loader">{t("ALL.Common.Loading")}</div>
            }
                {feedbacks && <>
                    <div className="row table-row">
                        <div className="col-md-12">
                            <div className="card">
                                <StandardListHeader changeDateRange={changeDateRange} calendarState={calendarState} headerTitle="EMP.Feedback.Title" headerClass="header-top-emp" />
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead className="text-primary-emp">
                                                <tr>
                                                    <th style={{ width: '30%' }}>{t("EMP.Feedback.Date")}</th>
                                                    <th style={{ width: '30%' }}>{t("EMP.Feedback.TaskQuality")}</th>
                                                    <th style={{ width: '30%' }}>{t("EMP.Feedback.Limitations")}</th>
                                                    <th style={{ width: '10%' }}>{t("ALL.Button.Actions")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {feedbacks && feedbacks.map(f => <>
                                                    <tr key={f.id}
                                                        className="collapsed"
                                                        data-toggle="collapse"
                                                        data-target={".multi-collapse" + f.id!.toString()}
                                                        aria-controls={"multiCollapse" + f.id!.toString()}>
                                                        <td>{moment(f.dateOfFeedback).format("L")}</td>
                                                        <td>{t(`EMP.Feedback.TaskQualityOption.${f.taskQuality}`)}</td>
                                                        <td>{Boolean(f.limitationRespected) ? t("ALL.Button.Yes") : t("ALL.Button.No")}</td>
                                                        <td><FontAwesomeIcon icon={faChevronRight} /></td>
                                                    </tr>
                                                    <tr></tr>
                                                    <tr className={"collapse multi-collapse" + f.id!.toString()} id={"multiCollapse" + f.id!.toString()}>
                                                        <td colSpan={4}><p className="table-collaps-header">{t("CLN.Reflection.GeneralRemarks")}</p><p>{f.remarks}</p></td>

                                                    </tr></>
                                                )}
                                                {!feedbacks &&
                                                    <tr>
                                                        <td colSpan={4} className="text-center">
                                                            <div className="spinner-border spinner-border-lg align-center"></div>
                                                        </td>
                                                    </tr>
                                                }
                                                {feedbacks && !feedbacks.length &&
                                                    <tr>
                                                        <td colSpan={4} className="text-center">
                                                            <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
            </Tab>
            <Tab eventKey="report" title={t("ALL.Common.ViewReport")}>{!feedbacks &&
                <div className="loader">{t("ALL.Common.Loading")}</div>
            }
                {feedbacks && <>
                    <StandardReportHeader changeDateRange={changeDateRange} calendarState={calendarState} isLoading={isLoading} generateReport={generateReport} />

                    <div className="report-preview" id="feedback" style={{ backgroundColor: "white", padding: "15px" }} ref={ref} >
                        <div className="row">
                            <div className="col-sm-6">
                                <p>{t("ALL.Common.ReportCreated")}:<b>{moment().format("L")}</b></p>
                            </div>
                            <div className="col-sm-6  text-right" style={{ textAlign: 'right' }}>
                                <img
                                    src={helperFunctions.getLogoBase64Url()}
                                    width="170px"
                                    height="48px"
                                    alt="Logo"
                                />
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="col-sm-6">
                                <p>{t("CLN.Reflection.DateRange")}:<b> {moment(calendarState[0].startDate).format('DD.MM.YYYY')}-{moment(calendarState[0].endDate).format('DD.MM.YYYY')}</b></p>
                                <p>{t("CLN.Reflection.ClientName")}: <b>{caseData.caseClienName}</b></p>
                                <p>{t("CLN.Reflection.WeekNumber")}: <b>{(moment(calendarState[0].startDate).week() === moment(calendarState[0].endDate).week()) ? moment(calendarState[0].endDate).week() : `${moment(calendarState[0].startDate).week()} - ${moment(calendarState[0].endDate).week()}`}</b></p>
                                <p>{t("INS.Common.InsurerRefNumber")}: <b>{caseData.caseInsurerReference}</b></p>
                                <p>{t("CAM.Case.CaseDeskNumber")}: <b>{caseData.caseNumber}</b></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="white-container col-12">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '30%' }}>{t("EMP.Feedback.Date")}</th>
                                                <th style={{ width: '30%' }}>{t("EMP.Feedback.TaskQuality")}</th>
                                                <th style={{ width: '30%' }}>{t("EMP.Feedback.Limitations")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {feedbacks && feedbacks.map(f => <>
                                                <tr key={`report-${f.id}`}>
                                                    <td>{moment(f.dateOfFeedback).format("L")}</td>
                                                    <td>{t(`EMP.Feedback.TaskQualityOption.${f.taskQuality}`)}</td>
                                                    <td>{Boolean(f.limitationRespected) ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr></tr>
                                                <tr className={"collapse multi-collapse" + f.id!.toString()} id={"multiCollapse" + f.id!.toString()}>
                                                    <td colSpan={2}><p className="table-collaps-header">{t("CLN.Reflection.TaskPerformed")}</p>
                                                        <p>{f.limitationRespected}</p></td>
                                                    <td colSpan={2}><p className="table-collaps-header">{t("CLN.Reflection.GeneralRemarks")}</p><p>{f.remarks}</p></td>

                                                </tr></>
                                            )}
                                            {!feedbacks &&
                                                <tr>
                                                    <td colSpan={3} className="text-center">
                                                        <div className="spinner-border spinner-border-lg align-center"></div>
                                                    </td>
                                                </tr>
                                            }
                                            {feedbacks && !feedbacks.length &&
                                                <tr>
                                                    <td colSpan={3} className="text-center">
                                                        <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
            </Tab>

        </Tabs>
    </div>
    );

}


export { ListFeedback };