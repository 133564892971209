import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { alertService, notificationService, notesService } from '../../services/Index';

import Note from '../../models/Note';

import moment from 'moment';
import 'moment/min/locales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faIndustry, faShieldAlt, faStethoscope, faUserInjured } from '@fortawesome/pro-light-svg-icons'
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { NotificationContext } from '../../ctx/NotificationProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';


const ViewNote = () => {
    const { id, notId } = useParams();
    const { authUserData } = useContext(LoggedUserDataContext);
    const { t } = useContext(MultiLanguageSupportContext);
    const { notificationsDetails, setNotifications } = useContext(NotificationContext);
    const [noteItem, setNote] = useState<Note>();
    const { caseData } = useContext(CaseDataContext);
    moment.locale(authUserData.currUserData.profileLanguage);
    useEffect(() => {
        notesService.getSingleNote(parseInt(id!)).then(rp => {
            setNote(rp);
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        if (notId !== "No") {
            notificationService.changeNotificationStatus(parseInt(notId!), "Read");
            setNotifications({
                ...notificationsDetails,
                notificationNumber: notificationsDetails.notificationNumber - 1
            })
        }

    }, [notId]);


    return (

        <div className="container">
            {!noteItem &&
                <div className="loader">{t("ALL.Common.Loading")}</div>
            }
            {noteItem &&
                <div className="row">
                    <div className="col-lg-10 col-md-12">
                        <div className="card display-card">
                            <div className="card-header card-header-warning header-top">
                                <h4 className="card-title">{t("ALL.Notes.Title")}</h4>
                                <hr></hr>
                                <p className="card-category"><FontAwesomeIcon icon={faChevronLeft} /><Link to={notId !== "No" && caseData.caseNumber === undefined ? `/${authUserData.currUserData.roleType.toLowerCase()}` : `/${authUserData.currUserData.roleType.toLowerCase()}/notes-management`} className="btn btn-md btn-icon-grey mr-1">{t("ALL.Button.Back")}</Link></p>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("ALL.Notes.Date")}:</label>
                                        <div>{moment(noteItem.noteDate).format("L")}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("ALL.Notes.Title")}:</label>
                                        <div>{noteItem.noteTitle} </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("ALL.Notes.Text")}:</label>
                                        <div>{noteItem.noteText}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-sm-12">
                                        <div className='legend' style={{ paddingLeft: "0px" }}>
                                            <div className='legend-title'>{t("ALL.Notes.SharedWith")}</div>
                                            <div className='legend-scale'>
                                                <ul className='legend-labels'>
                                                    {noteItem.recipients.includes("CLN") &&
                                                    <li>
                                                        <span className="btn-lg btn-icon-cln"><FontAwesomeIcon icon={faUserInjured} /></span>
                                                        <label className="form-check-label">{t("ALL.Notes.SharedWithCLN")}</label>
                                                    </li>}
                                                    {noteItem.recipients.includes("DOC") &&
                                                        <li>
                                                            <span className="btn-lg btn-icon-doc"><FontAwesomeIcon icon={faStethoscope} /></span>
                                                            <label className="form-check-label">{t("ALL.Notes.SharedWithDOC")}</label>
                                                        </li>}
                                                    {noteItem.recipients.includes("CAM") &&
                                                        <li>
                                                            <span className="btn-lg btn-icon-cam"><FontAwesomeIcon icon={faShieldAlt} /></span>
                                                            <label className="form-check-label">{t("ALL.Notes.SharedWithCAM")}</label>
                                                        </li>}
                                                    {noteItem.recipients.includes("EMP") &&
                                                        <li>
                                                            <span className="btn-lg btn-icon-emp"><FontAwesomeIcon icon={faIndustry} /></span>
                                                            <label className="form-check-label">{t("ALL.Notes.SharedWithEMP")}</label>
                                                        </li>}

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-sm-12">
                                        <div className='legend' style={{ paddingLeft: "0px" }}>
                                            <div className='legend-title'>{t("ALL.Notes.Author")}</div>
                                            <div className='legend-scale'>
                                                <ul className='legend-labels'>
                                                    {noteItem.createdByContactRole === "Client" && <li>
                                                        <span className="btn-lg btn-icon-cln"><FontAwesomeIcon icon={faUserInjured} /></span>
                                                        <label className="form-check-label">{t("ALL.Notes.SharedWithCLN")}</label>
                                                    </li>}
                                                    {noteItem.createdByContactRole === "Doctor" &&
                                                        <li>
                                                            <span className="btn-lg btn-icon-doc"><FontAwesomeIcon icon={faStethoscope} /></span>
                                                            <label className="form-check-label">{t("ALL.Notes.SharedWithDOC")}</label>
                                                        </li>}
                                                    {noteItem.createdByContactRole === "CaseManager" &&
                                                        <li>
                                                            <span className="btn-lg btn-icon-cam"><FontAwesomeIcon icon={faShieldAlt} /></span>
                                                            <label className="form-check-label">{t("ALL.Notes.SharedWithCAM")}</label>
                                                        </li>}
                                                    {noteItem.createdByContactRole === "Employeer" &&
                                                        <li>
                                                            <span className="btn-lg btn-icon-emp"><FontAwesomeIcon icon={faIndustry} /></span>
                                                            <label className="form-check-label">{t("ALL.Notes.SharedWithEMP")}</label>
                                                        </li>}

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

    );
}

export { ViewNote };