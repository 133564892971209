import { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { alertService, tasksService } from '../../services/Index';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import TaskTemplate from '../../models/TaskTemplate';
import { date } from 'yup/lib/locale';
import { ITaskViewModel, mapTaskToVieModel } from '../../models/Task';
import { ListTasks } from './ListTasks';
import { Task } from '../../models/Task';

interface IAddEditTaskTemplateProps {
    taskTemplate?: any;
    close: any,
    editTemplate: any,
    addTemplate: any,
    cleanSelectedNode: any,
    taskTagsOptions:any[],
    casePhaseOptions:any[],
    taskPlanOptions:any[]
}

const AddEditTaskTemplate = ({ taskTemplate, cleanSelectedNode, addTemplate, close, editTemplate,taskTagsOptions, casePhaseOptions, taskPlanOptions }: IAddEditTaskTemplateProps) => {

    const [tasks, setTasks] = useState<Array<ITaskViewModel>>();
    const { authUserData } = useContext(LoggedUserDataContext);
    const { t } = useContext(MultiLanguageSupportContext);
    const [loading, setLoading] = useState(true);
    const { caseData } = useContext(CaseDataContext);
    const isAddMode: boolean = Object.entries(taskTemplate).length === 0
    useEffect(() => {
        if (!isAddMode) {
            setLoading(true);
            setValue("id", taskTemplate.id);
            setValue("templateTitle", taskTemplate.templateTitle);
            setValue("templateDescription", taskTemplate.templateDescription);
            setValue("ownerContact", taskTemplate.ownerContact);
            setValue("insurerId", taskTemplate.insurerId);
            setValue("isPrivate", taskTemplate.isPrivate);

            tasksService.getTasksForTemplate(taskTemplate.id).then((rp) => {
                let response = rp.map((data) => {
                    return mapTaskToVieModel(data, t,taskTagsOptions, casePhaseOptions,taskPlanOptions,authUserData.currUserData.profileLanguage)

                })
                setTasks(response);
                setLoading(false);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
        }
    }, [taskTemplate]);

    const validationSchema = Yup.object().shape({
        templateTitle: Yup.string().required(t("ALL.Tasks.Templates.ValidateTitle")),
        isPrivate: Yup.string().required(t("ALL.Tasks.Templates.ValidateMessage"))
    });
    const deleteTask = (taskId: number) => {
        setTasks(tempTask =>tempTask!.map(x => {
            if (x.id === taskId) { x.isDeleting = true; }
            return x;
        }));
        tasksService.deleteTask(taskId).then((rp) => {
            alertService.success(t("ALL.Tasks.Messages.TaskDeleted"), { keepAfterRouteChange: true });
            setTasks(tempTask =>tempTask!.filter(x => x.id !== taskId));
        }).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                setTasks(tempTask =>tempTask!.map(x => {
                    if (x.id === taskId) { x.isDeleting = true; }
                    return x;
                }));
            }

        );
    }
    const addTask = (data: Task) => {
        let task = mapTaskToVieModel(data, t,taskTagsOptions, taskPlanOptions,casePhaseOptions,authUserData.currUserData.profileLanguage)
        setTasks(tasks => [task, ...tasks!]);
    }
    const editTask = (task: Task) => {
        setTasks(tempTask =>tempTask!.map(x => {
            if (x.id === task.id) { return mapTaskToVieModel(task, t,taskTagsOptions,casePhaseOptions,taskPlanOptions,authUserData.currUserData.profileLanguage) }
            return x;
        }));

    }

    const { register, handleSubmit, control, reset, setValue, getValues, errors, formState } = useForm({
        defaultValues: {
            templateTitle: '',
            templateDescription: '',
            ownerContact: {},
            insurerId: -1,
            isPrivate: false,

        },
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data: TaskTemplate)=> {
        return isAddMode
            ? await createTaskTemplate(data)
            : await updateTaskTemplate(data);
    }

    const createTaskTemplate= async (data: TaskTemplate)=> {
        data.caseId = caseData.selectedCaseId;
        data.ownerContact = {
            id: authUserData.currUserData.contactId!
        }
        // data.isPrivate = data.isPrivate === null ? false : true;
        data.insurerId = authUserData.currUserData.insurerId;
        data.createdBy = authUserData.currUserData.profileId;
        //cleanSelectedNode({});
        await tasksService.postNewTaskTemplate(data)
            .then((rp) => {
                alertService.success(t("ALL.Tasks.Messages.TemplateAdded"), { keepAfterRouteChange: true });
                addTemplate(rp.Data);
                reset();
                close(false);
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                    reset();
                    close(false);
                }
            );
    }
    const updateTaskTemplate= async (data: TaskTemplate)=> {
        data.id=taskTemplate.id;
        data.modifiedBy = authUserData.currUserData.profileId;
        cleanSelectedNode({});
        await tasksService.postUpdateTaskTemplate(data)
            .then((rp) => {
                alertService.success(t("ALL.Tasks.Messages.TemplateUpdated"), { keepAfterRouteChange: true });
                editTemplate(rp);
                reset();
                close(false);
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                    reset();
                    close(false);
                }

            );
    }
    const listTasksProps = {
        deleteTask: deleteTask,
        add: addTask,
        edit: editTask,
        loading: loading,
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <div className="form-group col-sm-6">
                                <label>{t("ALL.Tasks.Templates.Title")}*</label>
                                <input name="templateTitle" type="text" ref={register} className={`form-control ${errors.templateTitle ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.templateTitle?.message}</div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-sm-12">
                                <label>{t("ALL.Tasks.Templates.Description")}</label>
                                <textarea name="templateDescription" rows={5} ref={register} className={`form-control ${errors.templateDescription ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.templateDescription?.message}</div>
                            </div>
                        </div>
                        <div className="form-row">
                            <label className="col-sm-4 col-form-label">{t("ALL.Tasks.Templates.Private")}*</label>
                            <div className={`col-sm-6 ${errors.isPrivate ? 'is-invalid-radio' : ''}`}>
                                <div className="form-check form-check-inline">
                                    <input name="isPrivate" type="radio" value="True" ref={register} />
                                    <label className="form-check-label">{t("ALL.Button.Yes")}</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input name="isPrivate" type="radio" value="False" ref={register} />
                                    <label className="form-check-label">{t("ALL.Button.No")}</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row justify-content-center">
                            <div className="form-group col-sm-4">
                                <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    {t("ALL.Button.Save")}
                                </button>
                            </div>
                            <div className="form-group col-sm-4">
                                <button className="btn btn-grey" onClick={(event) => {
                                    event.preventDefault();
                                    reset();
                                    cleanSelectedNode({});
                                    close(false)
                                }}>
                                    {t("ALL.Button.Cancel")}
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            {!isAddMode &&
                <ListTasks {...listTasksProps} tasks={tasks} taskTemplateId={taskTemplate.id} taskTagsOptions={taskTagsOptions} casePhaseOptions={casePhaseOptions} taskPlanOptions={taskPlanOptions}/>
            }
        </div>

    );
}


export { AddEditTaskTemplate };
