import { useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { faPlusCircle, faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import ContactAutoComplete from './ContactAutoComplete';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';


interface IManageAddingContactProps {
    groupId?: string,
    groupName: string,
    caseId?: number,
    insurerId?: number,
    role?: string,
    requiered: boolean,
    caseContact: boolean,
    members: any,
    loadingMembers:boolean,
    action: (add: any, action: string) => void;
}
function ManageAddingContact({ role, members, action, caseId, groupId, insurerId, groupName, requiered, caseContact,loadingMembers }: IManageAddingContactProps) {
    const { t } = useContext(MultiLanguageSupportContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    const [resteAutocomplete, setResetAutocomplete] = useState((new Date()).toString());
    const validationSchema = Yup.object().shape({
        userEmail: Yup.string().email()
            .required(t("ALL.Contact.Validation.EMail")),
        confirmEmail: Yup.string()
            .transform(x => x === '' ? undefined : x)
            .when('userEmail', (userEmail: any, schema: { required: (arg0: string) => any; }) => {
                if (userEmail || requiered) return schema.required(t("ALL.Common.ConfirmEMail"));
            })
            .oneOf([Yup.ref('userEmail')], t("ALL.Contact.Validation.EMailMatch"))

    });
    const { register, handleSubmit, reset, errors, formState: { isSubmitting } , control } = useForm({
        resolver: yupResolver(validationSchema)
    });
    useEffect(() => {
        setResetAutocomplete((new Date()).toString());
        reset();

    }, [members]);
    function onSubmit(data: any) {
        return action(data, 'add');
    }
    function deleteMember(data: any) {
        data.isDeleting = true;
        action({ id: data.contact.id, adId: data.contact.userActveDirectoryId, isDeleting: true }, "delete")
    }
    return (
        <div>{!loadingMembers&&<>
            {(role !== "Client" && role !== "Doctor" && role !== "Employeer") && ((members && members.length === 0 && caseContact) || !caseContact) &&
                <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                    <div className="form-row" style={{ paddingBottom: "5px", paddingLeft: "0px" }}>
                        <div className="form-group col-sm-10">
                            {groupName === "Client" && <>
                                <label>{t("ALL.Contact.EMail")}</label>
                                <input name="userEmail" type="text" ref={register} className={`form-control ${errors.userEmail ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.userEmail?.message}</div>
                            </>
                            }
                            {groupName !== "Client" && <>
                                <ContactAutoComplete  key={resteAutocomplete} role={groupName} insurerId={insurerId!} caseId={caseId!} control={control} error={errors.userEmail?.message} caseContact={caseContact} />
                            </>}
                        </div>
                    </div>
                    <div className="form-row" style={{ paddingTop: "5px", paddingLeft: "0px" }}>
                        <div className="form-group col-sm-10">
                            <label>{t("ALL.Common.ConfirmEMail")}</label>
                            <input name="confirmEmail" type="text" ref={register} className={`form-control ${errors.confirmEmail ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.confirmEmail?.message}</div>
                        </div>

                    </div>
                    <div className="form-row">
                        <div className="col-12 justify-content-end">
                            <button type="submit" disabled={isSubmitting} className={`btn btn-action-${groupName!.replace(/\s/g, '').toLowerCase()} mr-1`}>
                                <FontAwesomeIcon icon={faPlusCircle} size="lg" />&nbsp;{t("ALL.Button.Add")} {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            </button>
                        </div>
                    </div>
                </form>
            }
            <div style={{marginTop:"10px"}}>
                {members && members.map((member: any) =>
                    <div className="form-row" key={member.contact.id}>
                        <div className="form-group col-8">{member.contact.name===undefined||member.contact.name===null?`${member.contact.email}`:`${member.contact.name} ${member.contact.surname}`}</div>
                        <div className="form-group col-sm-4 justify-content-end">
                            {(role !== "Client" && role !== "Doctor" && role !== "Employeer") &&
                                <button onClick={() => deleteMember(member)} className={`btn btn-action-${groupName!.replace(/\s/g, '').toLowerCase()} mr-1`} disabled={member.isDeleting}>
                                    <FontAwesomeIcon icon={faTrash} />&nbsp;{t("ALL.Button.Remove")}{member.isDeleting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                </button>
                            }
                        </div>
                    </div>
                )}
                </div></>}
                <div>
                {loadingMembers &&
                    <tr>
                        <td colSpan={4} className="text-center">
                            <p>{t("ALL.Messages.LoadUserGroups")}</p>
                            <div className="spinner-border spinner-border-lg align-center"></div>
                        </td>
                    </tr>
                }
            </div>
        </div>
    );
}

export { ManageAddingContact };