import React, {useContext } from 'react';
import ProfilePicture from "@dsalvagni/react-profile-picture"
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css"
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { adminService, alertService, contactService } from '../../services/Index';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';

interface IProfilePictureProps {
  image: string
  //pictureUpdateAction: any
  pictureType: string
  profileId: number,
  pictureFormat: string
}
export const ProfilePictureCustom = ({ pictureType, image, profileId, pictureFormat }: IProfilePictureProps) => {
  let profilePictureRef: any = React.createRef();
  //let profilePicture: any;
  const { caseData, setCaseData } = useContext(CaseDataContext);
  const { t } = useContext(MultiLanguageSupportContext);
  const { authUserData, setAuthUserData } = useContext(LoggedUserDataContext);
  const handleUpload = (isAdd:boolean) => {

    const PP = profilePictureRef.current;
    //const imageData = PP.getData();
    //const file = imageData.file;
    //const imageAsDataURL = PP.getImageAsDataUrl();
    var data = {
      id: profileId,
      profileImage: isAdd?PP.getImageAsDataUrl():null

    };
    if (pictureType === "user") {
      contactService.postUploadProfilePicture(data)
        .then((rp) => {
          alertService.success(t("ALL.Contact.Updated"), { keepAfterRouteChange: true });
          setAuthUserData(
            {
              ...authUserData,
              currUserData: {
                ...authUserData.currUserData!,
                profileImg: data.profileImage
              },
            });

        })
        .catch(
          (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    }
    else {
      adminService.postUploadProfilePicture(data)
        .then((rp) => {
          alertService.success(t("INS.Common.PictureUpdated"), { keepAfterRouteChange: true });
          setCaseData({
            ...caseData,
            insurerProfileImage: data.profileImage
          });
        })
        .catch(
          (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    }

  }
  return (
    <div className="profile-picture-control"> <ProfilePicture
      ref={profilePictureRef}
      useHelper={true}
      debug={true}
      image={image}
      minImageSize="20"
      frameFormat={pictureFormat}
      onImageRemoved={()=>handleUpload(false)}
    />
      <button onClick={()=>handleUpload(true)} className="btn btn-action">{t("ALL.Button.Upload")}</button>
    </div>

  );
}
