import { useContext, useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Insurer from '../../models/Insurer';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons'

import { adminService, alertService } from '../../services/Index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';

interface IAddEditInsurerProps {
    reSetInsurers?: any;
}
const AddEditInsurer=({reSetInsurers }: IAddEditInsurerProps)=> {
    let navigate = useNavigate();
    let { id } = useParams();
    const {authUserData} = useContext(LoggedUserDataContext);
    const isAddMode: boolean = !id;
    const { t } = useContext(MultiLanguageSupportContext);
    const validationSchema = isAddMode ? Yup.object().shape({
        insurerPrefix: Yup.string()
            .required()
            .matches(/[A-Za-z0-9]*/, t("INS.Validation.RefNumberLettersAndDigits"))
            .min(3, t("INS.Validation.RefNumberLettersAndDigits"))
            .max(6, t("INS.Validation.RefNumberLettersAndDigits"))

    }) : Yup.object().shape({
    });
    const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data: Insurer) {
        return isAddMode
            ? createInsurer(data)
            : updateInsurer(parseInt(id!), data);
    }

    function createInsurer(data: Insurer) {
        data.createdBy = authUserData.currUserData.profileId;
        return adminService.postNewInsurer(data)
            .then((rp) => {
                alertService.success(t("INS.Common.Created"), { keepAfterRouteChange: true });
                reSetInsurers(rp.Data);
                reset();
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    function updateInsurer(id: number, data: Insurer) {
        data.insurerPrefix = insurer!.insurerPrefix;
        data.id = id;
        data.modifiedBy = authUserData.currUserData.profileId
        return adminService.postUpdateInsurer(data)
            .then(() => {
                alertService.success(t("INS.Common.Updated"), { keepAfterRouteChange: true });
                navigate('..');
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    const [insurer, setInsurer] = useState<Insurer>();

    useEffect(() => {
        if (!isAddMode) {
            adminService.getSingleInsurer(parseInt(id!)).then(i => {
                setValue("insurerName", i.insurerName);
                setValue("insurerId", i.id);
                setValue("insurerPrefix", i.insurerPrefix);
                setValue("contactPersonName", i.contactPersonName);
                setValue("contactPersonSurname", i.contactPersonSurname);
                setValue("sexOfcontactPerson", i.sexOfcontactPerson);
                setValue("insurerPhoneNumber", i.insurerPhoneNumber);
                setValue("responsibleEmail", i.responsibleEmail);
                setValue("insurerStreeNbr", i.insurerStreeNbr);
                setValue("insurerCity", i.insurerCity);
                setValue("insurerPostCode", i.insurerPostCode);
                setValue("insurerLand", i.insurerLand);
                setValue("invoiceType", i.invoiceType);
                setInsurer({
                    insurerName: i.insurerName,
                    insurerPrefix: i.insurerPrefix,
                    contactPersonName: i.contactPersonName,
                    contactPersonSurname: i.contactPersonSurname,
                    sexOfcontactPerson: i.sexOfcontactPerson,
                    insurerPhoneNumber: i.insurerPhoneNumber,
                    responsibleEmail: i.responsibleEmail,
                    insurerStreeNbr: i.insurerStreeNbr,
                    insurerCity: i.insurerCity,
                    insurerPostCode: i.insurerPostCode,
                    insurerLand: i.insurerLand,
                    invoiceType: i.invoiceType,
                    profileImage:i.profileImage,
                    insurerADMGroupOpenId: i.insurerADMGroupOpenId,
                    insurerCAMGroupOpenId: i.insurerCAMGroupOpenId,
                    createdBy: i.createdBy
                });
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
        }
    }, []);

    return (
        <div className="container">
            {!isAddMode &&<> <p className="component-header">{t("CAM.Case.Edit")}</p>
            <hr></hr></>}
            <div className=" white-container">
                <div>
                    <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        {!isAddMode &&
                            <div className="form-row">
                                <div className="form-group col-sm-5">
                                    <label>{t("ALL.Contact.Company")}</label>
                                    <input name="insurerName" type="text" ref={register} className={`form-control ${errors.insurerName ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.insurerName?.message}</div>
                                </div>
                            </div>
                        }
                        <div className="form-row">
                            <div className="form-group col-sm-5">
                                <label>{t("INS.Common.Prefix")}</label>
                                <input name="insurerPrefix" type="text" ref={register} className={`form-control ${errors.insurerPrefix ? 'is-invalid' : ''}`} disabled={!isAddMode} />
                                <div className="invalid-feedback">{errors.insurerPrefix?.message}</div>
                            </div>
                            {isAddMode &&
                                <div className="form-group col-sm-5">
                                    <button type="submit" disabled={formState.isSubmitting} className="btn btn-action btn-simple">
                                        {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        <FontAwesomeIcon icon={faUserPlus} /> {t("INS.Common.Add")}
                                    </button>
                                </div>
                            }
                        </div>
                        {!isAddMode &&
                            <div>
                                <div className="form-row">
                                    <div className="form-group col-sm-5">
                                        <label>{t("ALL.Contact.Firstname")}</label>
                                        <input name="contactPersonName" type="text" ref={register} className={`form-control ${errors.contactPersonName ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.contactPersonName?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-5">
                                        <label>{t("ALL.Contact.Lastname")}</label>
                                        <input name="contactPersonSurname" type="text" ref={register} className={`form-control ${errors.contactPersonSurname ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.contactPersonSurname?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-5">
                                        <label>{t("ALL.Contact.Salutation")}</label>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-5">
                                        <div className="form-check form-check-inline">
                                            <input name="sexOfContactPerson" type="radio" value="Male" ref={register} className={`${errors.sexOfContactPerson ? 'is-invalid' : ''}`} />
                                            <label className="form-check-label">{t("ALL.Contact.SalutationMR")}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input name="sexOfContactPerson" type="radio" value="Female" ref={register} className={`${errors.sexOfContactPerson ? 'is-invalid' : ''}`} />
                                            <label className="form-check-label">{t("ALL.Contact.SalutationMRS")}</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.sexOfContactPerson?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-5">
                                        <label>{t("ALL.Contact.Phone")}</label>
                                        <input name="insurerPhoneNumber" type="text" ref={register} className={`form-control ${errors.insurerPhoneNumber ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.insurerPhoneNumber?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-5">
                                        <label>{t("ALL.Contact.EMailResponsible")}</label>
                                        <input name="responsibleEmail" type="text" ref={register} className={`form-control ${errors.responsibleEmail ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.responsibleEmail?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-5">
                                        <label>{t("ALL.Contact.Street")}</label>
                                        <input name="insurerStreeNbr" type="text" ref={register} className={`form-control ${errors.insurerStreeNbr ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.insurerStreeNbr?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-5">
                                        <label>{t("ALL.Contact.City")}</label>
                                        <input name="insurerCity" type="text" ref={register} className={`form-control ${errors.insurerCity ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.insurerCity?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-5">
                                        <label>{t("ALL.Contact.PostCode")}</label>
                                        <input name="insurerPostCode" type="text" ref={register} className={`form-control ${errors.insurerPostCode ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.insurerPostCode?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-5">
                                        <label>{t("ALL.Contact.Country")}</label>
                                        <input name="insurerLand" type="text" ref={register} className={`form-control ${errors.insurerLand ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.insurerLand?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-5">
                                        <label>{t("INS.Common.InvoiceSendingPer")}</label>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-5">
                                        <div className="form-check form-check-inline">
                                            <input name="invoiceType" type="radio" value="Email" ref={register} className={`${errors.invoiceType ? 'is-invalid' : ''}`} />
                                            <label className="form-check-label">
                                                {t("INS.Common.InvoicePerEMail")}
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input name="invoiceType" type="radio" value="Physical document" ref={register} className={`${errors.invoiceType ? 'is-invalid' : ''}`} />
                                            <label className="form-check-label">
                                                {t("INS.Common.InvoicePerLetter")}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">{errors.invoiceType?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-4 justify-content-md-center">
                                        <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                            {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            {t("ALL.Button.Save")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
}

export { AddEditInsurer };