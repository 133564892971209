import { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { adminService, alertService} from '../../services/Index';
import { faList } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminSetting from '../../models/AdminSetting';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';

const SettingsManagement=()=> {
    const { path } = useParams();
    const [settings, setSettings] = useState<AdminSetting[]>();
    const { t } = useContext(MultiLanguageSupportContext);
    useEffect(() => {
        adminService.getAllSettings().then(
            (x) => {
                setSettings(x);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );

    }, [path]);
    return (
        <div className="container">
            <p className="component-header">{t("ALL.Settings.Overview")}</p>
            <hr></hr>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: '15%' }}>{t("ALL.Settings.Title")}</th>
                        <th style={{ width: '20%' }}>{t("ALL.Settings.ValueNumber")}</th>
                        <th style={{ width: '15%' }}>{t("ALL.Settings.ValueText")}</th>
                        <th style={{ width: '10%' }}>{t("ALL.Settings.Unit")}</th>
                        <th style={{ width: '20%' }}>{t("ALL.Settings.ValueInfo")}</th>
                        <th style={{ width: '20%' }}>{t("ALL.Button.Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {settings && settings.map(s =>
                        <tr key={s.id}>
                            <td>{s.title}</td>
                            <td>{s.valueNumber}</td>
                            <td>{s.valueText}</td>
                            <td>{s.valueUnit}</td>
                            <td>{s.info}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`../edit-setting/${s.id}`} className="btn btn-md btn-icon-blue mr-1"><FontAwesomeIcon icon={faList} /></Link>
                            </td>
                        </tr>
                    )}
                    {!settings &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="spinner-border spinner-border-lg align-center"></div>
                            </td>
                        </tr>
                    }
                    {settings && !settings.length &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );

}

export { SettingsManagement };