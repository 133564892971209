import { useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { LoggedUserDataContext } from '../ctx/LoggedUserDataProvider';


export const PrivateRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { authUserData } = useContext(LoggedUserDataContext);
  const { pathname } = useLocation();
  if (isAuthenticated && authUserData.currUserData!==undefined && pathname.indexOf(authUserData.currUserData.roleType.toLowerCase()) > -1) {      
      return children
  }
  return <Navigate to="/" />
}