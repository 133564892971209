
import React, { useContext, useEffect, useState } from 'react';
import '../../assets/css/tabcomponent.css';
import '@asseinfo/react-kanban/dist/styles.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import TableChartIcon from '@mui/icons-material/TableChart';

import KanbanBoard from "./KanbanBoard";
import CalendarView from "./CalendarView";
import { TasksManagement } from './TasksManagement';

import { helperFunctions } from '../../helpers/HelperFunctions';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { useNavigate } from 'react-router-dom';
import { adminService, alertService, tasksService } from '../../services/Index';
import { ITaskViewModel, mapTaskToVieModel, Task } from '../../models/Task';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Modal, ModalBody } from 'reactstrap';
import { AddEditTask } from './AddEditTask';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';

interface ITabComponentProps {
  caseId?: number
  taskTableOnly?: boolean
}
function TabComponent({ caseId,taskTableOnly }: ITabComponentProps) {
  let navigate = useNavigate();

  const { caseData } = useContext(CaseDataContext);
  const [loading, setLoading] = useState(true);
  const { t } = useContext(MultiLanguageSupportContext);
  const { authUserData } = useContext(LoggedUserDataContext);
  const [taskTagsOptions, setTaskTagsOptions] = useState<any[]>([]);
  const [casePhaseOptions, setCasePhaseOptions] = useState<any[]>([]);
  const [taskPlanOptions, setTaskPlanOptions] = useState<any[]>([]);

  const [value, setValue] = React.useState(0);
  const [resData, setResData] = React.useState<Array<ITaskViewModel>>([]);
  const noCaseId = caseId === undefined ? caseData.selectedCaseId : caseId;

  const [cardData, setCardData] = useState<any>({})
  const [selectedChip, setSelectedChip] = useState<String>("Task_Status");
  const [open, setOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState(false);
  const [, setSelectedTask] = useState<any>({})
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (noCaseId === undefined) navigate('..');
    if (noCaseId !== undefined) {
      setLoading(true);
      var getTaskTagsDic = adminService.getScopedDictionaries(0, [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId }], authUserData.currUserData.profileLanguage.toUpperCase());
      var getTaskPlanDic = adminService.getScopedDictionaries(2, [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId }], authUserData.currUserData.profileLanguage.toUpperCase());
      var getCasePhaseDic = adminService.getScopedDictionaries(3, [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId }], authUserData.currUserData.profileLanguage.toUpperCase());
      Promise.all([getTaskTagsDic, getTaskPlanDic, getCasePhaseDic]).then(async (tagDicResponse) => {
        setTaskTagsOptions(helperFunctions.getUniqueDictionaryPerLanguage(tagDicResponse[0], "dicGuid", authUserData.currUserData.profileLanguage.toUpperCase()));
        setTaskPlanOptions(helperFunctions.getUniqueDictionaryPerLanguage(tagDicResponse[1], "dicGuid", authUserData.currUserData.profileLanguage.toUpperCase()));
        setCasePhaseOptions(helperFunctions.getUniqueDictionaryPerLanguage(tagDicResponse[2], "dicGuid", authUserData.currUserData.profileLanguage.toUpperCase()));
        tasksService.getCaseTasks(noCaseId, (authUserData.currUserData.profileLanguage).toUpperCase()).then((rp) => {
          let response = rp.map((data) => {
            return mapTaskToVieModel(data, t, tagDicResponse[0], tagDicResponse[2], tagDicResponse[1], authUserData.currUserData.profileLanguage)

          })
          setResData(response);
          setLoading(false);
        }).catch(
          (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
      })
        .catch((rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true }));
    }
  }, [noCaseId]);


  function handleCardClick(task: Task) {
    setOpen(true)
    setCardData(task)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const editTask = (task: Task) => {
    setResData(resData => resData!.map(x => {
      if (x.id === task.id) { return mapTaskToVieModel(task, t, taskTagsOptions, casePhaseOptions, taskPlanOptions, authUserData.currUserData.profileLanguage) }
      return x;
    }));
    setUpdatedData(!updatedData)

  }
  const editTaskKanban = (task: Task, selectedChipVal) => {
    setResData(resData => resData!.map(x => {
      if (x.id === task.id) { return mapTaskToVieModel(task, t, taskTagsOptions, casePhaseOptions, taskPlanOptions, authUserData.currUserData.profileLanguage) }
      return x;
    }));
    setSelectedChip(selectedChipVal);
  }

  const deleteTask = (taskId: number) => {
    setResData(resData!.map(x => {
      if (x.id === taskId) { x.isDeleting = true; }
      return x;
    }));
    tasksService.deleteTask(taskId).then((rp) => {
      alertService.success(t("ALL.Tasks.Messages.TaskDeleted"), { keepAfterRouteChange: true });
      setResData(resData => resData?.filter(item => item.id !== taskId));
    }).catch(
      (rp) => {
        alertService.error(t(rp.message), { keepAfterRouteChange: true });
        setResData(resData!.map(x => {
          if (x.id === taskId) { x.isDeleting = true; }
          return x;
        }));
      }

    );
  }
  const addTask = (data: Task) => {
    let task = mapTaskToVieModel(data, t, taskTagsOptions, casePhaseOptions, taskPlanOptions, authUserData.currUserData.profileLanguage)
    setResData(resData => [task, ...resData!]);
  }
  const addMultipleTasks = (data: ITaskViewModel[]) => {
    setResData(resData => [...data, ...resData!]);
  }
  return (
    <>
      {loading && <div className="loader">{t("ALL.Common.Loading")}</div>}
      {!loading && <div className="container">
        <>
          {taskTableOnly === undefined ?
            <Box sx={{ flexGrow: 1 }} >
              <Grid item xs={10}>
                <div>
                  <AppBar position="static">
                    <Tabs value={value}
                      onChange={handleChange}
                      // indicatorColor="secondary"
                      TabIndicatorProps={{ style: { background: 'White' } }}
                      textColor="inherit"
                      // variant="fullWidth"
                      aria-label="full width tabs example" centered >
                      <Tab icon={<TableChartIcon />} />
                      <Tab icon={<DashboardIcon />} />
                      <Tab icon={<AssignmentTurnedInIcon />} />
                    </Tabs>
                  </AppBar>
                  {value === 0 && <TasksManagement loading={loading} data={resData} editTask={editTask} addTask={addTask} deleteTask={deleteTask} taskTagsOptions={taskTagsOptions} casePhaseOptions={casePhaseOptions} taskPlanOptions={taskPlanOptions} addMultipleTasks={addMultipleTasks} taskTableOnly={false}/>}
                  {value === 1 && <KanbanBoard data={resData} chipSelected={selectedChip} editTask={editTaskKanban} addTask={addTask} taskTagsOptions={taskTagsOptions} casePhaseOptions={casePhaseOptions} taskPlanOptions={taskPlanOptions} />}
                  {value === 2 && <CalendarView data={resData} handleCardClick={handleCardClick} update={updatedData} />}

                </div>
              </Grid>
            </Box> :
            <TasksManagement loading={loading} data={resData} editTask={editTask} addTask={addTask} deleteTask={deleteTask} taskTagsOptions={taskTagsOptions} casePhaseOptions={casePhaseOptions} taskPlanOptions={taskPlanOptions} addMultipleTasks={addMultipleTasks} taskTableOnly={true}/>}
          <Modal isOpen={open} style={{ maxWidth: "1000px" }}>
            <ModalBody>
              <AddEditTask task={cardData} close={handleClose} add={addTask} edit={editTask} view={true} cleanSelectedNode={setSelectedTask} taskTagsOptions={taskTagsOptions} casePhaseOptions={casePhaseOptions} taskPlanOptions={taskPlanOptions} selectedChip={selectedChip} />
            </ModalBody>
          </Modal>
        </>
      </div>
      }
    </>
  );
}

export default TabComponent;
