import { useContext, useEffect, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { alertService, tasksService } from '../../services/Index';
import { IListTasksProps, ListTasks } from './ListTasks';
import { Task, ITaskViewModel, mapTaskToVieModel } from '../../models/Task';
import { Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { PaperProps } from "./TaskUtils";
import moment from 'moment';
import TaskIcon from '@mui/icons-material/Task';
import AddTaskIcon from '@mui/icons-material/AddTask';
import Settings from '@mui/icons-material/Settings';
import { ListTaskTemplates } from './ListTaskTemplates';
import Grid from '@mui/material/Grid';
import Chips from "./Chips";

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { TaskGeneratorForm } from './TaskGeneratorForm';



export const TasksManagement = ({ addMultipleTasks, data, editTask, addTask, deleteTask, taskTagsOptions, casePhaseOptions, taskPlanOptions, loading,taskTableOnly }) => {
    const [templateModalOpen, setTemplateModalOpen] = useState(false);
    const [generatorModalOpen, setGeneratorModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    //const [tasks, setTasks] = useState<Array<ITaskViewModel>>(data);
    const [loader, setLoader] = useState(loading);
    const { language, t } = useContext(MultiLanguageSupportContext);

    moment.locale(language);

    const chipType = [
        { chipLabel: "ALL.Tasks.Button.SortByPriority", name: "By_Priority", param: "priority" },
        { chipLabel: "ALL.Tasks.Button.SortByPhase", name: "By_Phase", param: "casePhase" },
    ]
    const [selectedChip, setSelectedChip] = useState<String>("By_Priority");
/*
    function handleChipClick(item: any) {
        setSelectedChip(item)
        setLoader(true);
        const filterObj = chipType.filter((e) => e.name === selectedChip);
        let selectedKey = filterObj[0].param;
        let tempTask = JSON.parse(JSON.stringify(tasks))
        let res = tempTask.sort((a, b) => a[selectedKey] - b[selectedKey]);
        setTasks(res)
        setLoader(false);
    }
*/
    const listTasksProps: IListTasksProps = {
        deleteTask: deleteTask,
        add: addTask,
        edit: editTask,
        tasks: data!,
        loading: loader,
        taskTagsOptions: taskTagsOptions,
        casePhaseOptions: casePhaseOptions,
        taskPlanOptions: taskPlanOptions,
        addMultipleTasks: addMultipleTasks
    }
    return (<>
        {!data && <div className="loader">{t("ALL.Common.Loading")}</div>}
        {data &&
            <div className="container">
                {taskTableOnly===false && <>
                <div style={{ marginTop: '2%' }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} md={5} lg={5}>
                            <p className="component-header">{t("ALL.Tasks.Title")}</p>
                        </Grid>
                        {/*<Grid item xs={12} md={4} lg={4}>
                            <Chips data={chipType} handleChipClick={(val)=> handleChipClick(val)} selected={selectedChip} />
        </Grid>*/}
                        {/* <Grid item xs={12} md={3} lg={1}>
                            <div style={{zIndex: -1}}>
                                <Box sx={{ flexGrow: 1 }}>
                                        <Toolbar sx={{ justifyContent: "space-between" }}>
                                            <div />
                                            <div />
                                            <div />
                                            <div />
                                            <div />

                                                <ReactMultiSelectCheckboxes 
                                                placeholderButtonLabel="Filter"
                                                options={options} /> 
                                            <div />
                                        </Toolbar>
                                </Box>
                            </div>
                        </Grid> */}
                        <Grid item xs={12} md={3} lg={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Tooltip title={t("ALL.Tasks.Templates.Title")}>
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Settings fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={PaperProps}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                                <MenuItem onClick={() => { setTemplateModalOpen(true) }}>
                                    <ListItemIcon>
                                        <TaskIcon fontSize="small" />
                                    </ListItemIcon>
                                    {t("ALL.Tasks.Templates.Manage")}
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setGeneratorModalOpen(true) }}>
                                    <ListItemIcon>
                                        <AddTaskIcon fontSize="small" />
                                    </ListItemIcon>
                                    {t("ALL.Tasks.Generator.Generate")}
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </div>
                <hr></hr>
                </>
                    }
                <Box sx={{ width: '100%' }}>
                    <ListTasks {...listTasksProps} />
                </Box>
                <Modal toggle={() => { setTemplateModalOpen(!templateModalOpen) }} isOpen={templateModalOpen} style={{ maxWidth: "1000px" }}>
                    <ModalHeader style={{ display: "block" }}>
                        {t("ALL.Tasks.Templates.Title")}
                        <span style={{ justifyContent: "right" }}>
                            <button type="submit" className="btn btn-md btn-icon-grey mr-1 float-right" onClick={() => { setTemplateModalOpen(!templateModalOpen) }}>
                                <FontAwesomeIcon icon={faCircleXmark} />
                            </button>
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        <ListTaskTemplates taskTagsOptions={taskTagsOptions} casePhaseOptions={casePhaseOptions} taskPlanOptions={taskPlanOptions} />
                    </ModalBody>
                </Modal>
                <Modal toggle={() => { setGeneratorModalOpen(!generatorModalOpen) }} isOpen={generatorModalOpen} style={{ maxWidth: "1000px" }}>
                    <ModalHeader style={{ display: "block" }}>
                        {t("ALL.Tasks.Generator.Title")}
                        <span style={{ justifyContent: "right" }}>
                            <button type="submit" className="btn btn-md btn-icon-grey mr-1 float-right" onClick={() => { setGeneratorModalOpen(!generatorModalOpen) }}>
                                <FontAwesomeIcon icon={faCircleXmark} />
                            </button>
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        <TaskGeneratorForm
                            close={setGeneratorModalOpen}
                            addMultipleTasks={addMultipleTasks}
                            {...listTasksProps}
                        />
                    </ModalBody>
                </Modal>

            </div>
        }</>
    );
}
