import { Route, Routes } from 'react-router-dom';
import { ListFeedback } from './ListFeedback';
import { AddEditFeedback } from './AddEditFeedback';
import { CaseManagment } from './CaseManagment';
import { Dashboard } from './Dashboard';
import { EditContact } from '../casemanager/EditContact';
import { ListAssessements } from '../casemanager/ListAssessements';
import { ViewFeedback } from './ViewFeedback';
import { NotificationManagement } from '../../components/notifications/NotificationManagement';
import { NotesManagement } from '../../components/notes/NotesManagement';
import { DocumentManagement } from '../../components/document/DocumentManagement';
import { ListContacts } from '../../components/contact/ListContacts';
import { ViewNote } from '../../components/notes/ViewNote';
import { useContext } from 'react';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { ViewReflection } from '../client/ViewReflection';
import { ViewAssessement } from '../doctor/ViewAssessment.';
import { ViewTask } from '../../components/tasks/ViewTask';
import { NotificationSettingsManagement } from '../../components/notifications/NotificationSettingsManagement';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import ViewCase from '../casemanager/ViewCase';

const EmployeerPanel = () => {
    const { caseData } = useContext(CaseDataContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    return (
        <Routes>
            <Route path={`/`} element={<CaseManagment/>}/>
            <Route path={`/dashboard/:id`} element={<Dashboard/>}/>
            <Route path="/show-feedbacks">
                <Route path={`:id/:notId`} element={<ListFeedback />} />
                <Route index element={<ListFeedback />} />
            </Route>
            <Route path={`/show-contacts`} element={<ListContacts role="Employeer" />} />
            <Route path="/show-assessements">
                <Route path={`:id/:notId`} element={<ListAssessements />} />
                <Route index element={<ListAssessements />} />
            </Route>
            <Route path={`/add-feedback`} element={<AddEditFeedback/>}/>
            <Route path={`/edit-contact/:id`} element={<EditContact/>}/>
            <Route path={`/view-feedback/:id/:notId`} element={<ViewFeedback/>}/>
            <Route path={`/view-reflection/:id/:notId`} element={<ViewReflection/>}/>
            <Route path={`/view-assessement/:id/:notId`} element={<ViewAssessement/>}/>
            <Route path={`/view-task/:id/:notId`} element={<ViewTask/>}/>
            <Route path={`/view-note/:id/:notId`} element={<ViewNote/>}/>
            <Route path={`/view-case/:id/:notId`} element={<ViewCase/>}/>
            <Route path={`/notification-management`} element={<NotificationManagement role="Employeer" caseId={caseData.selectedCaseId} />} />
            <Route path={`/edit-feedback/:id`} element={<AddEditFeedback/>}/>
            <Route path="/notes-management">
                <Route path={`:id`} element={<NotesManagement  role="Employeer" caseId={caseData.selectedCaseId} />} />
                <Route index element={<NotesManagement  role="Employeer" caseId={caseData.selectedCaseId} />} />
            </Route>
            <Route path="/document-management">
                <Route path={`:id/:notId`} element={<DocumentManagement role="Employeer" caseId={caseData.selectedCaseId}/>} />
                <Route path={`:id`} element={<DocumentManagement role="Employeer" caseId={caseData.selectedCaseId}/>} />
                <Route path={`:notId`} element={<DocumentManagement role="Employeer" caseId={caseData.selectedCaseId}/>} />
                <Route index element={<DocumentManagement role="Employeer" caseId={caseData.selectedCaseId}/>} />
            </Route>
            <Route path={`/manage-notification-settings`} element={<NotificationSettingsManagement allScopes={[{scopeTypeId:0,scopeEntityId:-1},{scopeTypeId:1,scopeEntityId:caseData.insurerId},{scopeTypeId:2,scopeEntityId:caseData.selectedCaseId},{scopeTypeId:3,scopeEntityId:authUserData.currUserData.contactId}]} scope={{scopeTypeId:3,scopeEntityId:authUserData.currUserData.contactId}} />} />
        </Routes>
    );
}
export { EmployeerPanel };