import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { doctorService, alertService, notificationService } from '../../services/Index';
import Assessement from '../../models/Assessement';
import moment from 'moment';
import 'moment/min/locales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { NotificationContext } from '../../ctx/NotificationProvider';

const ViewAssessement=()=> {
    const { id, notId } = useParams();
    const { t } = useContext(MultiLanguageSupportContext);
    const [assessementItem, setAssessement] = useState<Assessement>();
    const {notificationsDetails, setNotifications} = useContext(NotificationContext);
    const { caseData } = useContext(CaseDataContext);
    useEffect(() => {
        doctorService.getSingleAssessement(parseInt(id!)).then(i => {
            setAssessement({
                caseId: i.caseId,
                dateValidFrom: i.dateValidFrom,
                presence: i.presence,
                capacity: i.capacity,
                remarks: i.remarks,
                nextAssessement: i.nextAssessement,


            });
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        if (notId !== "No") {
            notificationService.changeNotificationStatus(parseInt(notId!), "Read");
            setNotifications({
                ...notificationsDetails,
                notificationNumber:notificationsDetails.notificationNumber-1
            })
            //LogedUserProps.manageUserNotifications();
        }

    }, [notId]);


    return (

        <div className="container">
            {assessementItem && <>
                <div className="row">
                    <div className="col-lg-10 col-md-12">
                        <div className="card">
                            <div className="card-header card-header-warning header-top">
                                <h4 className="card-title">{t("DOC.Assessment.Title")}</h4>
                                <p><FontAwesomeIcon icon={faChevronLeft} /><Link to={notId !== "No" && caseData.caseNumber === undefined ? "../" : `../dashboard/${assessementItem.caseId}`} className="back-btn">{t("ALL.Button.Back")}</Link></p>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <label  className="col-form-label-bold">{t("DOC.Assessment.Date")}</label>
                                    <div className="form-group col-sm-12">
                                        <label>{moment(assessementItem.dateValidFrom).format("L")}</label>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <label  className="col-form-label-bold">{t("DOC.Assessment.Presence")}</label>
                                    <div className="form-group col-sm-12">
                                        <label>{assessementItem.presence}</label>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <label  className="col-form-label-bold">{t("DOC.Assessment.Capacity")}</label>
                                    <div className="form-group col-sm-12">
                                        <label>{assessementItem.capacity}</label>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <label className="col-form-label-bold">{t("DOC.Assessment.Remark")}</label>
                                    <div className="form-group col-sm-12"><label>{assessementItem.remarks}</label></div>
                                </div>
                                <div className="form-row">
                                <label className="col-form-label-bold">{t("DOC.Assessment.Next")}</label>

                                <div className="form-group col-sm-12">
                                        <label>{moment(assessementItem.nextAssessement).format("L")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

            }
        </div>


    );
}

export { ViewAssessement };