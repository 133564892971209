import { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { alertService, caseManagerService } from '../../services/Index';
import { Tabs, Tab } from "react-bootstrap";
import { faUsers, faList, faEye } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/images/logoIcon.png';
import moment from 'moment';
import 'moment/min/locales';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons'
import FillingTimeChart from '../../components/charts/FillingTimeChart';
import FillingAmountChart from '../../components/charts/FillingAmountChart';
import MultiCaseTrendLineChart from '../../components/charts/MultiCaseTrendLineChart';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { NotificationManagement } from '../../components/notifications/NotificationManagement';
import TabComponent from '../../components/tasks/TabComponent';

interface ICaseManagementProps {
    allCasesMode: boolean
}
function CaseManagement({ allCasesMode }: ICaseManagementProps) {    
    const [tab, setTab] = useState('list');
    const { pathname } = useLocation();
    const { t } = useContext(MultiLanguageSupportContext);
    const { authUserData, setAuthUserData } = useContext(LoggedUserDataContext);
    const { caseData, setCaseData } = useContext(CaseDataContext);
    const [tasks, setTasks] = useState<any[]>();
    const [notifications, setNotifications] = useState<any[]>();
    const [cases, setCases] = useState<any[]>();
    const [filteredCases, setFilteredCases] = useState<any[]>();
    const [filteredActiveCases, setFilteredActiveCases] = useState<any[]>();
    moment.locale(authUserData.currUserData.profileLanguage);
    function getCases() {
        caseManagerService.getUserCases(String(authUserData.currUserData.insurerId), authUserData.currUserData.profileId).then(
            (rp) => {
                if (!allCasesMode) {
                    setCases(rp);
                    setFilteredCases(rp.filter(c => c.caseData.inCaseManagerRole === 'Yes'));
                    setFilteredActiveCases(rp.filter(c => c.validLicense&&c.caseData.inCaseManagerRole === 'Yes'));
                }
                else {
                    setCases(rp);
                    setFilteredCases(rp);
                }
                setCaseData({
                    ...caseData,
                    caseInsurerReference: authUserData.currUserData.relatedData.insurerPrefix,
                    selectedCaseId: undefined,
                    caseNumber: undefined,
                    caseClienName: undefined,
                    insurerProfileImage: undefined,

                });
                setAuthUserData({
                    ...authUserData,
                    selectedCaseId: undefined

                });
                //CaseProps.caseInsurerReference = authUserData.currUserData.relatedData.insurerPrefix;
                //CaseProps.caseNumber = undefined;
                //CaseProps.caseClienName = undefined;
                //CaseProps.insurerProfileImage = '';
                //LogedUserProps.manageTopNavInsurerPicture(CaseProps.insurerProfileImage);
                //CaseProps.manageCaseBreadCrumb(true);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );

    }
    useEffect(() => {
        if (cases === undefined) {
            getCases();
        }
        else {
            if (!allCasesMode) {
                setFilteredCases(cases.filter(c => c.caseData.inCaseManagerRole === 'Yes'));
                setFilteredActiveCases(cases.filter(c => c.validLicense&&c.caseData.inCaseManagerRole === 'Yes'));
            }
            else {
                setFilteredCases(cases);
                setTab('list');
            }

        }
    }, [allCasesMode]);

    const changeActiveTab = (tabName) => {
        setTab(tabName);

    }

    return (
        <div className="container">
            <Tabs
                activeKey={tab}
                onSelect={(t) => changeActiveTab(t)}>
                <Tab eventKey="list" title={t("ALL.Common.CaseList")}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header card-header-primary header-top">
                                    <h4 className="card-title ">{t("CAM.Case.Overview")}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>{t("INS.Common.InsurerRefNumber")}</th>
                                                    <th>{t("CAM.Case.ClientName")}</th>
                                                    <th>{t("CAM.Case.CaseDeskNumber")}</th>
                                                    <th>{t("CAM.Case.CreateDate")}</th>
                                                    {allCasesMode && <th>{t("ALL.Dictionary.Role.CaseManager")}</th>}
                                                    <th className="text-center">{t("ALL.Button.Actions")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredCases && filteredCases.map(c =>
                                                    <tr key={c.caseData.id}>
                                                        <td>{c.caseData.referenceNumberOfInsurance}</td>
                                                        <td>{c.clientData ? c.clientData.displayName : "-"}</td>
                                                        <td>{c.caseNumber}</td>
                                                        <td>{moment(c.caseData.created).format("L")}</td>
                                                        {allCasesMode && <td>{c.caseManagerData ? c.caseManagerData.displayName : "-"}</td>}
                                                        <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                                                            {(c.caseData.inCaseManagerRole === 'Yes' && c.validLicense) &&
                                                                <> <Link to={`${(pathname.indexOf("/casemanager/") < 0) ? pathname : ".."}/dashboard/${c.caseData.id}`} className="btn btn-md btn-icon-green mr-1"><img src={logo} alt="Logo" className="svg-inline--fa fa-users fa-w-20" /></Link>
                                                                    <Link to={`${(pathname.indexOf("/casemanager/") < 0) ? pathname : ".."}/edit-case/${c.caseData.id}/No`} className="btn btn-md btn-icon-grey mr-1"><FontAwesomeIcon icon={faList} /></Link></>
                                                            }
                                                            <Link to={`${(pathname.indexOf("/casemanager/") < 0) ? pathname : ".."}/manage-contacts/${c.caseData.id}`} className="btn btn-md btn-icon-blue mr-1"><FontAwesomeIcon icon={faUsers} /></Link>
                                                            {!c.validLicense ? <span className="btn btn-md mr-1"><FontAwesomeIcon icon={faCreditCard} className="btn-icon-red" /></span> : <span className="btn btn-md mr-1">
                                                                {c.validLicenseEdition === "DEMO" ? <FontAwesomeIcon icon={faCreditCard} className="btn-icon-light-blue" /> : <FontAwesomeIcon icon={faCreditCard} className="btn-icon-green" />}
                                                            </span>
                                                            }
                                                            {(!(c.caseData.inCaseManagerRole === 'Yes') || !c.validLicense) &&
                                                                <Link to={`${(pathname.indexOf("/casemanager/") < 0) ? pathname : ".."}/view-case/${c.caseData.id}/No`} className="btn btn-md btn-icon-grey mr-1"><FontAwesomeIcon icon={faEye} /></Link>
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                                {!filteredCases &&
                                                    <tr>
                                                        <td colSpan={6} className="text-center">
                                                            <div className="spinner-border spinner-border-lg align-center"></div>
                                                        </td>
                                                    </tr>
                                                }
                                                {filteredCases && !filteredCases.length &&
                                                    <tr>
                                                        <td colSpan={6} className="text-center">
                                                            <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="graphDashboard" title={t("ALL.Common.GraphDashboard")}>
                    {tab === "graphDashboard" && filteredCases && filteredCases !== undefined && <>
                        <div className="row">
                            <FillingTimeChart filteredCases={filteredCases} />
                        </div>
                        <div className="row">
                            <FillingAmountChart filteredCases={filteredCases} />
                        </div>
                        <div className="row">
                            <MultiCaseTrendLineChart filteredCases={filteredCases} />
                        </div>
                    </>

                    }

                </Tab>
                {!allCasesMode &&
                    <Tab eventKey="tasks" title={t("ALL.Tasks.Overview")}>
                        <div className="container">
                            <div className="row table-row" >
                                <div className="col-md-12">
                                    {tab === 'tasks' &&
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <tbody>
                                                    
                                                    {filteredActiveCases && filteredActiveCases.map(c =>
                                                        <tr key={c.caseData.id}>
                                                            <td>{c.caseNumber}</td>
                                                            <td>
                                                                <TabComponent caseId={c.caseData.id} taskTableOnly={true} />
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {filteredActiveCases && !filteredActiveCases.length &&
                                                        <tr key="no-data">
                                                            <td colSpan={5} className="text-center">
                                                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                            </td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Tab>
                } {!allCasesMode &&
                    <Tab eventKey="notifications" title={t("ALL.Notifications.Overview")}>
                        <div className="container">
                            <div className="row table-row" >
                                <div className="col-md-12">
                                    {tab === "notifications" &&
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <tbody>

                                                    {filteredActiveCases && filteredActiveCases.map(c =>
                                                        <tr key={c.caseData.id}>
                                                            <td>{c.caseNumber}</td>
                                                            <td>
                                                                <NotificationManagement role="CaseManager" caseId={c.caseData.id} />
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {filteredActiveCases && !filteredActiveCases.length &&
                                                        <tr key="no-data">
                                                            <td colSpan={5} className="text-center">
                                                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                            </td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Tab>
                }
            </Tabs>
        </div>
    );

}

export { CaseManagement };