import { useContext, useEffect, useState } from 'react';
import { msalConfig } from '../../msal/Config';
import { adminService, alertService, contactService } from '../../services/Index';
import { getGroupMembers } from '../../services/graph/GraphService'
import Membership from '../../models/Membership';
import { ManageAddingContact } from '../../components/contact/ManageAddingContact';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faInfoCircle } from '@fortawesome/pro-light-svg-icons';

function ManageOwner() {
    const {authUserData} = useContext(LoggedUserDataContext);
    const [members, setMembers] = useState<Array<any>>([]);
    const [loadingMembers, setLoadingMembers]= useState<boolean>(true);
    const { t } = useContext(MultiLanguageSupportContext);
    useEffect(() => {
        if (authUserData.currUserData.relatedData.length > 0) {
            var interval = setInterval(function(){                    
                    getGroupMembers(authUserData.currUserData.relatedData[0].insurerADMGroupOpenId).then(rp => {
                        setMembers(rp.value.map((item: { id: any; mail: any; }) => ({
                            contact: { id: item.id, userActveDirectoryId: item.id, email: item.mail },
                            welcomeSent: false
                        })));
                        setLoadingMembers(false);
                        clearInterval(interval);
                    }).catch(
                        (rp) => {
                            if(rp.code!=="Request_ResourceNotFound"){
                                clearInterval(interval);
                                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                            }                           
                            
                        }
                    );
            }, 2000); 
            
        }
    }, []);
    
    function onSubmit(data: any, action: string) {
        switch (action) {
            case 'add':
                return addInsurerOwner(data);
            case 'delete':
                return deleteInsurerOwner(data.adId);
        }
    }

    function addInsurerOwner(data: Membership) {
        data.insurerId = authUserData.currUserData.relatedData[0].id;
        data.roleType = "Insurer";
        data.groupId = authUserData.currUserData.relatedData[0].insurerADMGroupOpenId;
        data.redirectUrl = msalConfig.auth.redirectUri;
        return adminService.addUserToInsurerGroup(data)
            .then(rp => {
                alertService.success(t("INS.Common.AdminAdded"), { keepAfterRouteChange: true });
                setMembers(members => [{ contact: { id: rp.id, userActveDirectoryId: rp.userActveDirectoryId, email: rp.email }, welcomeSent: false }, ...members]);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    function deleteInsurerOwner(id: string) {
        setMembers(members.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));
        var data =
        {
            UserActveDirectoryId: id,
            groupId: authUserData.currUserData.relatedData[0].insurerADMGroupOpenId
        }
        contactService.removeInsurerContactFromGroupAsync(data).then(() => {
            alertService.success(t("INS.AdminRemoved"), { keepAfterRouteChange: true });
            setMembers(m => m.filter(x => x.contact.userActveDirectoryId !== id));
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    }
    return (
        <div className="container">
            <p className="component-header">{t("INS.Common.Admins")}</p>
            <hr></hr>
            <FontAwesomeIcon icon={faInfoCircle}  className="info-icon" />
            <span className="info-text" >{t("ALL.Messages.InsurerOnlySingleAdminAllowed")}</span>
            <hr></hr>
            <div className="white-container">
                <div>                    
                    <ManageAddingContact  groupName="Insurer" role="Insurer" action={onSubmit} 
                    insurerId={authUserData.currUserData.relatedData[0].id} 
                    groupId={authUserData.currUserData.relatedData[0].insurerADMGroupOpenId} 
                    requiered={false} caseContact={false} 
                    members={members} 
                    loadingMembers={loadingMembers}/>
                </div>
            </div>
        </div>
    );
}

export { ManageOwner };