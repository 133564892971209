import { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { adminService, alertService } from '../../services/Index';

import { AddEditInsurer } from './AddEditInsurer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCrown, faPen, faTrash, faCreditCard, faTags } from '@fortawesome/pro-light-svg-icons'
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { AddEditLicense } from './license/AddEditLicense';
import { Modal, ModalBody } from 'reactstrap';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';

const InsurerList=()=> {
    const { path }= useParams();
    const [insurers, setInsurers] = useState<any[]>();
    const [modalIsOpen, setModalVisibility] = useState(false);
    const { authUserData } = useContext(LoggedUserDataContext);
    const [selectedInsurer, setSelectedInsurer] = useState<any>({})
    let rolePath=authUserData.currUserData?.rolePath;

    const toggle = () => setModalVisibility(!modalIsOpen);
    function addLicense(insurerId) {
        setSelectedInsurer(insurerId);
        setModalVisibility(true);
    }
    const { t } = useContext(MultiLanguageSupportContext);
    function getInsurers() {
        adminService.getInsurers().then(x => setInsurers(x)).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    }
    function deleteInsurer(insurerId: number) {
        setInsurers(insurers!.map(x => {
            if (x.id === insurerId) { x.isDeleteing = true; }
            return x;
        }));
        adminService.deleteInsurer(insurerId).then(() => {
            alertService.success(t("INS.Common.Removed"), { keepAfterRouteChange: true });
            setInsurers(m => m!.filter(x => x.id !== insurerId));
        }).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                setInsurers(insurers!.map(x => {
                    if (x.id === insurerId) { x.isDeleteing = false; }
                    return x;
                }));
            }
        );
    }
    useEffect(() => {
        getInsurers();
    }, [path]);
    return (
        <div className="container">
            <p className="component-header">{t("ALL.Dictionary.Role.Insurer")}</p>
            <hr></hr>
            <div className="white-container">
                <AddEditInsurer reSetInsurers={getInsurers} />
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{t("INS.Common.Prefix")}</th>
                            <th>{t("INS.Common.InsurerName")}</th>
                            <th>{t("INS.Common.ContactPerson")}</th>
                            <th className="text-center">{t("ALL.Button.Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {insurers && insurers.map(insurer =>
                            <tr key={insurer.id}>
                                <td>{insurer.insurerPrefix}</td>
                                <td>{insurer.insurerName}</td>
                                <td>{insurer.contactPersonName} {insurer.contactPersonSurname}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: "center" }}>
                                    <Link to={`/${rolePath}/manage-dictionary/${insurer.id}`} state={{ "insurerPrefix": insurer.insurerPrefix }} className="btn btn-md btn-icon-grey mr-1"><FontAwesomeIcon icon={faTags}/></Link>
                                    <Link to={`add-owners/${insurer.id}`} className="btn btn-md btn-icon-blue mr-1"><FontAwesomeIcon icon={faUsersCrown} /></Link>
                                    <Link to={`edit-insurer/${insurer.id}`} className="btn btn-md btn-icon-green mr-1"><FontAwesomeIcon icon={faPen} /></Link>
                                    <button onClick={() => addLicense(insurer.id)} className="btn btn-md btn-icon-grey">
                                        <FontAwesomeIcon icon={faCreditCard} />
                                    </button>
                                    <button onClick={() => deleteInsurer(insurer.id)} className="btn btn-md btn-icon-red" disabled={insurer.isDeleteing}>
                                        <FontAwesomeIcon icon={faTrash} /> {insurer.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    </button>
                                </td>
                            </tr>
                        )}
                        {!insurers &&
                            <tr>
                                <td colSpan={4} className="text-center">
                                    <div className="spinner-border spinner-border-lg align-center"></div>
                                </td>
                            </tr>
                        }
                        {insurers && !insurers.length &&
                            <tr>
                                <td colSpan={4} className="text-center">
                                    <div className="p-2">{t("ALL.Common.NoData")}</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <Modal isOpen={modalIsOpen} style={{ maxWidth: "700px" }} toggle={toggle}>
            {/*<ModalHeader toggle={toggle}></ModalHeader>*/}
                <ModalBody>
                    <AddEditLicense insurerId={selectedInsurer} close={setModalVisibility} />
                </ModalBody>
            </Modal>
        </div>
    );
}

export { InsurerList };