import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { ListAssessements } from './ListAssessements';
import { Dashboard } from './Dashboard';
import { AddEditAssessement } from './AddEditAssessment';
import { CaseManagment } from './CaseManagment';
import { EditContact } from '../casemanager/EditContact';
import { ListFeedback } from '../casemanager/ListFeedback';
import { ListReflections } from '../casemanager/ListReflections';
import { ViewAssessement } from './ViewAssessment.';
import { useContext } from 'react';
import { ListContacts } from '../../components/contact/ListContacts';
import { ViewNote } from '../../components/notes/ViewNote';
import { NotificationManagement } from '../../components/notifications/NotificationManagement';
import { NotesManagement } from '../../components/notes/NotesManagement';
import { DocumentManagement } from '../../components/document/DocumentManagement';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { ViewFeedback } from '../employeer/ViewFeedback';
import { ViewReflection } from '../client/ViewReflection';
import { ViewTask } from '../../components/tasks/ViewTask';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { NotificationSettingsManagement } from '../../components/notifications/NotificationSettingsManagement';
import ViewCase from '../casemanager/ViewCase';

const DoctorPanel = () => {
    const { caseData} = useContext(CaseDataContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    return (
        <Routes>
            <Route path={`/`} element={<CaseManagment/>}/>
            <Route path={`/dashboard/:id`} element={<Dashboard/>}/>
            <Route path="/show-reflections">
                <Route path={`:id/:notId`} element={<ListReflections />} />
                <Route index element={<ListReflections />} />
            </Route>
            <Route path="/show-feedbacks">
                <Route path={`:id/:notId`} element={<ListFeedback />} />
                <Route index element={<ListFeedback />} />
            </Route>
            <Route path="/show-assessements">
                <Route path={`:id/:notId`} element={<ListAssessements />} />
                <Route index element={<ListAssessements />} />
            </Route>
            <Route path="/show-contacts">
                <Route path={`:id`} element={<ListContacts role="Doctor" />} />
                <Route index element={<ListContacts role="Doctor" />} />
            </Route>
            <Route path={`/add-assessement`} element={<AddEditAssessement/>}/>
            <Route path={`/edit-contact/:id`} element={<EditContact/>}/>
            <Route path={`/view-note/:id/:notId`} element={<ViewNote/>}/>
            <Route path={`/view-task/:id/:notId`} element={<ViewTask/>}/>
            <Route path={`/notification-management`} element={<NotificationManagement role="Doctor" caseId={caseData.selectedCaseId} />} />
            <Route path={`/edit-assessement/:id`} element={<AddEditAssessement/>}/>
            <Route path={`/view-feedback/:id/:notId`} element={<ViewFeedback/>}/>
            <Route path={`/view-reflection/:id/:notId`} element={<ViewReflection/>}/>
            <Route path={`/view-case/:id/:notId`} element={<ViewCase/>}/>
            <Route path={`/view-assessement/:id/:notId`} element={<ViewAssessement/>}/>
            <Route path="/notes-management">
                <Route path={`:id`} element={<NotesManagement  role="Doctor" caseId={caseData.selectedCaseId} />} />
                <Route index element={<NotesManagement  role="Doctor" caseId={caseData.selectedCaseId} />} />
            </Route>
            <Route path="/document-management">
                <Route path={`:id/:notId`} element={<DocumentManagement role="Doctor" caseId={caseData.selectedCaseId}/>} />
                <Route path={`:id`} element={<DocumentManagement role="Doctor" caseId={caseData.selectedCaseId}/>} />
                <Route path={`:notId`} element={<DocumentManagement role="Doctor" caseId={caseData.selectedCaseId}/>} />
                <Route index element={<DocumentManagement role="Doctor" caseId={caseData.selectedCaseId}/>} />
            </Route>
            <Route path={`/manage-notification-settings`} element={<NotificationSettingsManagement allScopes={[{scopeTypeId:0,scopeEntityId:-1},{scopeTypeId:1,scopeEntityId:caseData.insurerId},{scopeTypeId:2,scopeEntityId:caseData.selectedCaseId},{scopeTypeId:3,scopeEntityId:authUserData.currUserData.contactId}]} scope={{scopeTypeId:3,scopeEntityId:authUserData.currUserData.contactId}} />} />
        </Routes>
    );
}
export { DoctorPanel };