import { createGlobalStyle} from 'styled-components';

export const GlobalStyles = createGlobalStyle`
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 0px 4px !important;
}
.MuiOutlinedInput-input {
  padding: 9px !important;
}
body{
  background: #f1f1f1;
}
table{
  overflow-x:auto;
}
form .form-row{
  padding-top:26px;
  padding-left:26px;
  padding-right:26px;
  background-color:#fff;
}
header {
  background-image: linear-gradient(90deg, ${({ theme }) => theme.colors.gradiendStart} 0%, ${({ theme }) => theme.colors.gradiendEnd} 100%);
} 
.footer {
  background: ${({ theme }) => theme.colors.sideBar};
} 
/*
.sun-circle{
    background: #F9E2D2;
    height:80px;
    width: 40px;
    border-radius: 50%;
    display: inline-block;
    justify-content: center;
    align-items: center;
} 
.sun-circle svg{
  width: 80%;
  margin: 0 auto;
}*/
.sidebar {
  background: ${({ theme }) => theme.colors.sideBar};
}
.sidebar .active, .case-info {
  background-color:${({ theme }) => theme.colors.gradiendStart};
  
}
.bar-top{
  border-top: 10px solid ${({ theme }) => theme.colors.gradiendEnd};
}
.text-primary{
  color: ${({ theme }) => theme.colors.gradiendEnd} !important;
}
.header-top{
  background: ${({ theme }) => theme.colors.sideBar} !important;
  box-shadow: ${({ theme }) => theme.colors.boxShadow} !important;  
}

.header-top h4, .header-top a, .header-top p{
  color:#091C53 !important;
}
.btn-grey{
  background-color:#C2C2C1;
  width:100%
}
.btn-action, .btn-action:hover{
  width:100%;
  background-color:#091C53;
  color:#fff;
}
.btn-action-small, .btn-action-small:hover{
  padding:10px;
  margin-top:20px;
  float:right;
  background-color:#091C53;
  color:#fff;
}
.info-tooltip + .tooltip > .tooltip-inner {background-color: #091C53;}
.info-tooltip + .tooltip > .tooltip-arrow { border-bottom-color:#091C53;}
.report-icon{
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #091C53;
  opacity: 1;
  margin-left: -11px;
  margin-bottom: 0.5rem;

}
.table thead th {
  vertical-align: top !important;
}
.btn-simple{
  margin: 0;
  position: absolute;
  top: 50%;
}
.btn-icon-white{
  color:white !important;
}
.btn-icon-blue{
  color:#3f51b5;  
}
.btn-icon-light-blue{
  color:#0E86FE;  
}
.info-icon{
  color:#0E86FE;  
  margin-left:5px;
}
.info-text{
  color:#0E86FE;  
  margin-left:5px;
}
.btn-icon-marine{
  color:#091C53;  
}
.btn-icon-green{
  color:#34CF5A;  
}
.btn-icon-background-green{
  color:#fff; 
  float:right; 
  background-color:#B0C15B
}
.btn-icon-grey
{
  color:#091C53;
}
.btn-action-casemanager{
  color:#fff;  
  float:right; 
  background-color:#7F7E7C
}

.btn svg{
  margin-right:5px;
}
[class*="btn-action-"] {
  font-size:1rem;
  width:100%
}
.btn-action-employeer{
  color:#fff;  
  float:right;   
  background-color:#76ABBC;
}
.btn-action-doctor{
  color:#fff; 
  float:right; 
  background-color:#B0C15B;

}
.btn-action-client{
  color:#fff;  
  float:right; 

  background-color:#E97932;
}

.btn-icon-red{
  color:#FF4034;  
}
btn-marine{
  padding-left:5px;
  color:#091C53;  
}
btn-grey{
  padding-left:5px;
  color:#17191A; 
  width: 100%;
}
.alert > .close::after{
  width:0px !important;
}
table{
  background-color:#fff;
}
.component-header{
  font-size:20px;
}
.list-header{
  font-size:16px;
  padding-top:50px;
}
.cd-card-header{
  font-size:18px;
  color:#60686C;
}
.white-container > div, .white-tab > .form-row{
  padding:36px;

}
.custom-modal-style {
  max-width: 700px;
}
.white-container{
  /*margin: 5px;*/
  background-color: #fff;
}
.white-tab .form-row{
  background-color: #fff;
  border-radius: 0px 6px 6px 6px;
}
.calendar-custom{
  position:absolute;
  top:35px;
  right:0;
  z-index:100;
  border: 2px solid ${({ theme }) => theme.colors.actionButton};
}
.btn-calendar, btn-report{
  color:#fff;  
  float:right; 
  background-color: ${({ theme }) => theme.colors.gradiendEnd};
}
.table-border{
  border-top:10px solid ${({ theme }) => theme.colors.gradiendEnd};
}
.form-check-label{
  margin-left:5px;
}
.tab-bar{
  height:48px;
  background-color: ${({ theme }) => theme.colors.tabBGColor};
  color:${({ theme }) => theme.colors.tabColor};
  border-radius: 6px 6px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-top:20px;
  
}
a{
  color: #091C53;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #091C53;
  background-color: #fff;
  border-color: #091C53 #091C53 #091C53;
}

.top-tab-bar{
  height:48px;
  background-color: ${({ theme }) => theme.colors.tabBGColor};
  color:${({ theme }) => theme.colors.tabColor};
  border-radius: 6px 6px 0px 0px;
  margin-left:-5px;
}
.nav-tabs{
  border-bottom: 1px solid #091C53;
}
.tab-bar > label, .top-tab-bar>label{
  padding:10px;
}
.line-breadcrump{
  color:#091C53;
  font-size:15px;
  display: inline-block;
  align-self: flex-end;
  padding:5px;
}
.btn{
  cursor: pointer !important;
}
.contact-header{
  color:#17191A;
  font-size:20px;
}
table [class*="btn-md"]{
  padding:2px !important;
}
.card-text{
  color:#60686C;
  font-size:1rem;;
  margin:0px;
}
td .fa-w-8 {
  transition: .3s transform ease-in-out;
  transform: rotate(-90deg);
}
.collapsed .fa-w-8 {
  transform: rotate(90deg);
}
.report-card{
    font-family: "Inter";
    color: #17191a;
    font-size 1rem;
}
.contact-card{
  margin:20px;
}
`;

