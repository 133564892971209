import { useState, useEffect, useContext } from 'react';

import { alertService, caseManagerService } from '../../../services/Index';
import { faList } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AddEditCaseGoal } from './AddEditCaseGoal';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons'

import CaseGoal from '../../../models/CaseGoal';
import moment from 'moment';
import { faPlusCircle, faTrash } from '@fortawesome/pro-light-svg-icons'
import { List, ListItem, ListItemText } from '@mui/material';
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import { formTemplateService } from '../../../services/Index';
interface ICaseGoalManagementProps {
    caseId: number;
    referenceNumberOfInsurance: string;
    insurerId: number;
}

function CaseGoalManagement({ caseId, referenceNumberOfInsurance, insurerId }: ICaseGoalManagementProps) {
    const [casegoals, setCaseGoals] = useState<any[]>();
    const { t } = useContext(MultiLanguageSupportContext);
    const [modalIsOpen, setModalVisibility] = useState(false);
    const [selectedCaseGoal, setSelectedCaseGoal] = useState<any>({})
    const [templates, setTemplates] = useState<any>([]);
    const [isAdd, setIsAdd] = useState(false);

    function addCaseGoal() {
        setModalVisibility(true);
        setIsAdd(true)
    }
    const toggle = () => setModalVisibility(!modalIsOpen);
    function editCaseGoal(casegoal: CaseGoal) {
        setSelectedCaseGoal(casegoal);
        setModalVisibility(true);
        setIsAdd(false)

    }
    function getCaseGoals() {
        caseManagerService.getCaseGoals(caseId).then(x => setCaseGoals(x)).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    }
    function deleteCaseGoal(casegoalId: number) {
        setCaseGoals(casegoals!.map(x => {
            if (x.id === casegoalId) { x.isDeleteing = true; }
            return x;
        }));
        caseManagerService.deleteCaseGoal(casegoalId).then(() => {
            alertService.success(t("CAM.Case.Goals.Removed"), { keepAfterRouteChange: true });
            setCaseGoals(m => m!.filter(x => x.id !== casegoalId));
        }).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                setCaseGoals(casegoals!.map(x => {
                    if (x.id === casegoalId) { x.isDeleteing = false; }
                    return x;
                }));
            }
        );

    }
    function getGoalTemplate() {
        formTemplateService.getInsurerFormTemplatesAsync(insurerId.toString())
            .then(x => { setTemplates(x) })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    function switchEditMode(data) {
        setSelectedCaseGoal(data.Data);
        setModalVisibility(true);
        setIsAdd(false);
    }

    useEffect(() => {
        getCaseGoals();
        getGoalTemplate();
    }, []);
    // const [modal, setModal] = useState(false);

    // const toggle = () => setModal(!modal);
    return (<>
        <div className="card-header card-header-warning header-top">
            <h4 className="card-title">{t("CAM.Case.Goals.ListShowGoals")}</h4>
            <p className="card-category"><button className="btn btn-md btn-icon-grey mr-1" onClick={addCaseGoal}>
                <FontAwesomeIcon icon={faPlusCircle} /> {t("CAM.Case.Goals.Add")}
            </button></p>
        </div>
        {casegoals && <div className="card-body">


            <List>
                {casegoals!.map(item => (
                    <ListItem key={item.id}>
                        <ListItemText primary={`${item.goalTitle}`} secondary={`${moment(item.goalStartDate).format("L")}-${moment(item.goalEndDate).format("L")}`} />
                        <button onClick={() => editCaseGoal(item)} className="btn btn-md btn-icon-grey">
                            <FontAwesomeIcon icon={faList} />
                        </button>
                        <button onClick={() => deleteCaseGoal(item.id)} className="btn btn-md btn-icon-red" disabled={item.isDeleteing}>
                            <FontAwesomeIcon icon={faTrash} /> {item.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        </button>
                    </ListItem>
                ))}
                
                        {casegoals.length === 0 &&
                            <ListItem key={-1}>{t("CAM.Case.Goals.ListShowNoGoals")}</ListItem>}
            </List>

        </div>}

        {!casegoals &&
         <List>
            <ListItem key={-1}>
                <div className="spinner-border spinner-border-lg align-center"></div>                   
            </ListItem>
         </List>
        }
        <Modal isOpen={modalIsOpen} style={{ maxWidth: "700px" }} toggle={toggle}>
            <ModalHeader style={{ display: "block" }}>
                {/* {t("ALL.Contact.Edit")} */}
                {isAdd ? t("ALL.Button.Add") : t("ALL.Button.Edit") }
                <span style={{ justifyContent: "right" }}>
                    <button type="submit" className="btn btn-md btn-icon-grey mr-1 float-right" onClick={toggle}>
                        <FontAwesomeIcon icon={faCircleXmark} />
                    </button>
                </span>
            </ModalHeader>
            <ModalBody>
                <AddEditCaseGoal caseGoal={selectedCaseGoal} close={setModalVisibility} cleanSelectedNode={setSelectedCaseGoal} reSetGoals={getCaseGoals} caseId={caseId} referenceNumberOfInsurance={referenceNumberOfInsurance} goalTemplates={templates} switchEditMode={switchEditMode} isAddModeEnabled={isAdd} />
            </ModalBody>
        </Modal>
{/* 
        <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader
                    toggle={toggle}>Sample Modal Title</ModalHeader>
                <ModalBody>
                    Sample Modal Body Text to display...
                </ModalBody>
            </Modal> */}

    </>
    );

}

export { CaseGoalManagement };