import { useContext, useEffect, useState } from 'react';
import { msalConfig } from '../../msal/Config';
import { alertService, adminService, contactService } from '../../services/Index';
import { getGroupMembers } from '../../services/graph/GraphService'
import Membership from '../../models/Membership';
import { ManageAddingContact } from '../../components/contact/ManageAddingContact';

import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
function ManageMembers() {
    const { authUserData } = useContext(LoggedUserDataContext);
    const [members, setMembers] = useState<Array<any>>([]);
    const [loadingMembers, setLoadingMembers] = useState<boolean>(true);

    const { t } = useContext(MultiLanguageSupportContext);
    useEffect(() => {
        if (authUserData.currUserData.relatedData.length > 0) {
                getGroupMembers(authUserData.currUserData.relatedData[0].insurerCAMGroupOpenId).then(rp => {
                    setMembers(rp.value.map((item: { id: any; mail: any; }) => ({
                        contact: { id: item.id, userActveDirectoryId: item.id, email: item.mail },
                        welcomeSent: false
                    })));
                    setLoadingMembers(false);
                }).catch(
                    (rp) => {
                        alertService.error(t(rp.message), { keepAfterRouteChange: true });
                    }
                );        
        }
    }, []);
    function onSubmit(data: any, action: string) {
        switch (action) {
            case 'add':
                return addInsurerMember(data);
            case 'delete':
                return deleteInsurerMember(data.adId);
        }
    }

    function addInsurerMember(data: Membership) {
        data.createdBy = authUserData.currUserData.profileId;
        data.modifiedBy = authUserData.currUserData.profileId;
        data.groupId = authUserData.currUserData.relatedData[0].insurerCAMGroupOpenId;
        data.redirectUrl = msalConfig.auth.redirectUri;
        data.insurerId = authUserData.currUserData.relatedData[0].id;
        data.roleType = "CaseManager";
        return adminService.addUserToInsurerGroup(data)
            .then(rp => {
                alertService.success(t("INS.Common.Added"), { keepAfterRouteChange: true });
                setMembers(members => [{ contact: { id: rp.id, userActveDirectoryId: rp.userActveDirectoryId, email: rp.email }, welcomeSent: false }, ...members]);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    function deleteInsurerMember(id: string) {
        setMembers(members.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));
        var data =
        {
            UserActveDirectoryId: id,
            groupId: authUserData.currUserData.relatedData[0].insurerCAMGroupOpenId
        }
        contactService.removeInsurerContactFromGroupAsync(data).then(() => {
            alertService.success(t("INS.Common.Removed"), { keepAfterRouteChange: true });
            setMembers(m => m.filter(x => x.contact.userActveDirectoryId !== id));
        })
            .catch(
                () => alertService.error('Error', { keepAfterRouteChange: true })
            );

    }
    return (
        <div className="container">
            <p className="component-header">{t("INS.CaseManagerManage")}</p>
            <hr></hr>
            <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
            <span className="info-text" >{t("ALL.Messages.InsurerOnlySingleAdminAllowed")}</span>
            <hr></hr>
            <div className="white-container">
                <div>
                    <ManageAddingContact action={onSubmit} groupName="Case Manager" role="CaseManager"
                        insurerId={authUserData.currUserData.relatedData[0].id}
                        groupId={authUserData.currUserData.relatedData[0].insurerCAMGroupOpenId}
                        requiered={false} caseContact={false} members={members}
                        loadingMembers={loadingMembers} />
                </div>

            </div>
        </div>
    );
}

export { ManageMembers };