import { useContext, useEffect, useState } from 'react';
import { alertService, webService } from '../../services/Index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment';
import 'moment/min/locales';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { faCalendarAlt, faEye } from '@fortawesome/pro-light-svg-icons'
import { Modal, ModalBody } from 'reactstrap';
import { WebDetailClientCalculation } from './WebDetailClientCalculation';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';


function WebListCalculation() {
    const [calendarIsShown, setCalendarVisibility] = useState<boolean>(false);
    const [calculations, setCalculations] = useState<any[]>();
    const { t } = useContext(MultiLanguageSupportContext);
    const [modalIsOpen, setModalVisibility] = useState(false);
    const [selectedCalc, setSelectedCalc] = useState<any>({})
    const toggle = () => setModalVisibility(!modalIsOpen);
    useEffect(() => {
        webService.getDetailCostCalculationInDateRange(moment(stateCalc[0].startDate).format('DD.MM.YYYY'), moment(stateCalc[0].endDate).format('DD.MM.YYYY'))
            .then(x => setCalculations(x)).catch(
                (rp) => alertService.error(t(rp), { keepAfterRouteChange: true })
            );

    }, []);
    function manageClientCalculation(c) {
        setSelectedCalc(c);
        setModalVisibility(true);
    }
    const [stateCalc, setStateCalc] = useState([
        {
            startDate: addDays(new Date(), -30),
            endDate: addDays(new Date(), 1),
            key: 'selection'
        }
    ]);

    function changeDateRange(ranges: any) {
        console.log(ranges);
        setCalendarVisibility(!calendarIsShown);
        webService.getDetailCostCalculationInDateRange(moment(ranges.selection.startDate).format('DD.MM.YYYY'), moment(ranges.selection.endDate).format('DD.MM.YYYY'))
            .then(x => setCalculations(x)).catch(
                (rp) => alertService.error(t(rp), { keepAfterRouteChange: true })
            );
        setStateCalc([ranges.selection]);

    }
    return (<>{!calculations &&
        <div className="loader">{t("ALL.Common.Loading")}</div>
    }
        {calculations &&
            <div className="container">
                <div className="row" style={{ paddingTop: "10px" }}>
                            <div className="col-sm-6"><p className="component-header">{t("CalculationSummary") + ` ${moment(stateCalc[0].startDate).format('DD.MM.YYYY')}-${moment(stateCalc[0].endDate).format('DD.MM.YYYY')}`}</p></div>
                            <div className="col-sm-5 text-right">
                                <div className="btn-group">
                                    <button className="btn btn-calendar" onClick={() => setCalendarVisibility(!calendarIsShown)}><FontAwesomeIcon icon={faCalendarAlt} /></button>
                                </div>
                            </div>
                            <div className="col-sm-1" style={{ position: "relative" }}>
                                <div>
                                    {calendarIsShown && <DateRangePicker
                                        className="calendar-custom"
                                        onChange={changeDateRange}
                                        //showSelectionPreview={true}
                                        moveRangeOnFirstSelection={true}
                                        months={3}
                                        ranges={stateCalc}
                                        direction="horizontal"

                                    />}
                                </div>
                            </div>
                        </div>
                <div className="row">
                    <div className="white-container col-12">
                        <table className="table table-striped" id="calculations">
                            <thead>
                                <tr>
                                    <th style={{ width: '30%' }}>{t("ALL.Settings.WebSite.Browser.Date")}</th>
                                    <th style={{ width: '20%' }}>{t("ALL.Settings.WebSite.Browser.Name")}</th>
                                    <th style={{ width: '20%' }}>{t("ALL.Settings.WebSite.Browser.Language")}</th>
                                    <th style={{ width: '20%' }}>{t("ALL.Settings.WebSite.Browser.IP")}</th>
                                    <th style={{ width: '10%' }}>{t("ALL.Button.ShowMore")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {calculations && calculations.map(c => <>
                                    <tr
                                        key={c.clientCostCalculation.clientCostsId}
                                        className="collapsed"
                                        data-toggle="collapse"
                                        data-target={".multi-collapse" + c.clientCostCalculation.clientCostsId!.toString()}
                                        aria-controls={"multiCollapse" + c.clientCostCalculation.clientCostsId!.toString()}>
                                        <td>{moment(c.clientCostCalculation.requestDate).format("L")}</td>
                                        <td>{c.clientCostCalculation.browserDetails.browserName}</td>
                                        <td>{c.clientCostCalculation.browserDetails.browserLanguage}</td>
                                        <td>{c.clientCostCalculation.costCalculation.remoteIpAddress}</td>
                                        <td>
                                            <button onClick={() => manageClientCalculation(c)} className="btn btn-md btn-icon-grey">
                                            <FontAwesomeIcon icon={faEye} className="btn-icon-light-blue"/>                                            
                                        </button> 
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </td>
                                    </tr>
                                    <tr></tr>
                                    <tr className={"collapse multi-collapse" + c.clientCostCalculation.clientCostsId!.toString()} id={"multiCollapse" + c.clientCostCalculation.clientCostsId!.toString()}>
                                        <td colSpan={1}><p>{t("ALL.Settings.WebSite.Browser.Version")}</p><p>{c.clientCostCalculation.browserDetails.browserVersion}</p></td>
                                        <td colSpan={2}><p>{t("ALL.Settings.WebSite.Browser.DisplayWidth")}</p><p>{c.clientCostCalculation.browserDetails.displayWidht}</p></td>
                                        <td colSpan={2}><p>{t("ALL.Settings.WebSite.Browser.Height")}</p><p>{c.clientCostCalculation.browserDetails.displayHeight}</p></td>
                                    </tr>
                                </>
                                )}
                                {!calculations &&
                                    <tr>
                                        <td colSpan={5} className="text-center">
                                            <div className="spinner-border spinner-border-lg align-center"></div>
                                        </td>
                                    </tr>
                                }
                                {calculations && !calculations.length &&
                                    <tr>
                                        <td colSpan={5} className="text-center">
                                            <div className="p-2">{t("ALL.Common.NoData")}</div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        } 
            <Modal isOpen={modalIsOpen} style={{ maxWidth: "100%" }} toggle={toggle}>
                <ModalBody>
                    <WebDetailClientCalculation selectedCalc={selectedCalc} close={setModalVisibility}/>
                </ModalBody>
            </Modal>
        </>);

}


export { WebListCalculation };