import { Modal, Button } from "react-bootstrap";
 
const DeleteConfirmationModal = ({ showModal, hideModalFn, hideModal, confirmModal, type, message }) => {
    return (
        <Modal show={showModal} onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body><div className="alert alert-danger">{message}</div></Modal.Body>
            <Modal.Footer>
            <Button variant="default" onClick={hideModalFn}>
                Cancel
            </Button>
            <Button variant="danger" onClick={() => confirmModal(1) }>
                Delete
            </Button>
            </Modal.Footer>
      </Modal>
    )
}
 
export default DeleteConfirmationModal;