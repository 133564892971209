import  { useContext, useEffect, useRef, useState } from 'react'
import { alertService, clientService, doctorService, employeerService } from '../../services/Index';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2'
ChartJS.register(...registerables);

const options = {
  scales: {
    yAxes: 
      {
        ticks: {
          //beginAtZero: true,
        },
      },    
  },
  responsive: true,
};
interface IProps {
  filteredCases: Array<any>

}
const FillingTimeChart = ({ filteredCases }: IProps) => {
  const { t } = useContext(MultiLanguageSupportContext);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState<{ datasets: any,labels:any}>({
    labels: [],
    datasets: [],
  });
  useEffect(() => {
    var casesId=filteredCases.map(c => (`casesId=${c.caseData.id}`)).toString();
    var reflectionStat=clientService.getCasesReflectionMajorInfo(casesId.replaceAll(",","&"));
    var assesmentStat=doctorService.getCasesAssessementMajorInfo(casesId.replaceAll(",","&"));
    var feedbackStat=employeerService.getCasesFeedbackMajorInfo(casesId.replaceAll(",","&"));
    Promise.all([reflectionStat, assesmentStat,feedbackStat]).then(async (responses) => {
        var labels=filteredCases.map(function(c){
          if(responses[0].filter(r=>r.caseId===c.caseData.id)[0].amountOf == 0&&responses[1].filter(r=>r.caseId===c.caseData.id)[0].amountOf == 0&&responses[2].filter(r=>r.caseId===c.caseData.id)[0].amountOf == 0){
            return `${c.caseNumber}\n${t("ALL.DashBoard.NoData")}`
          }
          else{
            return c.caseNumber
          }
      });
      const chart = chartRef.current;
      if (chart) {
        setChartData({
          labels: labels,
          datasets: [
            {
              label: t("ALL.DashBoard.DaysSinceLegendAssessments"),
              data: responses[1].map(c =>c.numberOfLastFillInDays),
              fill: false,
              backgroundColor: 'rgb(176, 193, 91)',
              borderColor: 'rgb(176, 193, 91)',
              yAxisID: 'yAxes',
            },
            {
              type: 'bar' as const,

              label: t("ALL.DashBoard.DaysSinceLegendFeedbacks"),
              data: responses[2].map(c =>c.numberOfLastFillInDays),
              fill: false,
              backgroundColor: 'rgb(118, 171,188)',
              borderColor: 'rgb(118, 171,188)',
              yAxisID: 'yAxes',
            },
            {
              label: t("ALL.DashBoard.DaysSinceLegendReflections"),
              data: responses[0].map(c =>c.numberOfLastFillInDays),
              fill: false,
              backgroundColor: 'rgb(233,121,50)',
              borderColor: 'rgb(233,121,50)',
              yAxisID: 'yAxes',
            },
          ],
        })
      }

      })
      .catch(
        (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
      );

  }, [filteredCases]);
  return (
    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="card-header card-header-primary header-top">
          <h4 className="card-title">{t("ALL.DashBoard.DaysSinceHeader")}</h4>
          <p className="card-category">{t("ALL.DashBoard.DaysSinceHeaderSub")}</p>
        </div>
        <div className="card-body">

              <Chart
              ref={chartRef}
              type='bar'
              options={options}
              data={chartData}
            />
 
        </div>
      </div>
    </div>

  )
};

export default FillingTimeChart;