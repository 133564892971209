import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { caseDefault, ICase, ICaseProps } from '../interfaces/ICase';

const CaseDataContext = createContext<ICaseProps>({
    caseData: caseDefault,
    setCaseData: (user?: ICase) => console.log(user),

});
type Props = {
    children?: ReactNode
};
const CaseDataProvider: FC<Props> = ({ children }) => {
    const [caseData, setCaseData] = useState({});

    useEffect(() => {
        setCaseData(caseData);
    }, [caseData]);

    return (
        <CaseDataContext.Provider value={{ caseData, setCaseData }}>
            {children}
        </CaseDataContext.Provider>
    );
};

export { CaseDataProvider, CaseDataContext };
