//import 'assets/css/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
//import 'assets/styles/index.css';
import { msalConfig } from "./msal/Config";
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import App from './App';
import {MultiLanguageSupportProvider} from "./ctx/MultiLanguageSupportProvider";
import './constants/multi-lang-support'

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MultiLanguageSupportProvider>
        <App />
      </MultiLanguageSupportProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


/*****R18
 * 
 * 
 * import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { msalConfig } from "./msal/Config";
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import App from './App';
import {MultiLanguageSupportProvider} from "./ctx/MultiLanguageSupportProvider";
import './constants/multi-lang-support'
const container = document.getElementById('root');
const root = createRoot(container!);
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MultiLanguageSupportProvider>
        <App />
      </MultiLanguageSupportProvider>
    </MsalProvider>
  </React.StrictMode>

);
 */