import { useContext, useEffect, useState } from 'react';

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CaseGoal from '../../../models/CaseGoal';
import { mapFormTemplateData } from '../../../models/Form';
import "@fortawesome/fontawesome-svg-core/styles.css";
import 'react-form-builder2/dist/app.css';
import { alertService, caseManagerService, notificationService, formTemplateService } from '../../../services/Index';
import moment from 'moment';
import { Alert } from 'reactstrap';
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../../ctx/LoggedUserDataProvider';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ReactFormGenerator } from 'react-form-builder2';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AddEditDictionaryData } from '../../admin/dictionary/AddEditDictionaryData';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

interface IAddEditCaseGoalProps {
    caseGoal?: any;
    caseId: number
    close: any,
    cleanSelectedNode: any,
    reSetGoals: any,
    referenceNumberOfInsurance: string
    goalTemplates: any,
    switchEditMode: any,
    isAddModeEnabled: any
}
function AddEditCaseGoal({ close, caseGoal, cleanSelectedNode, caseId, reSetGoals, referenceNumberOfInsurance, goalTemplates, switchEditMode, isAddModeEnabled }: IAddEditCaseGoalProps) {

    const [formData, setFormData] = useState<any>([]);
    const [goalForm, setGoalForm] = useState<any>();
    const [casegoal, setCaseGoal] = useState<CaseGoal>();

    const { authUserData } = useContext(LoggedUserDataContext);
    const isAddMode: boolean = Object.entries(caseGoal).length === 0
    const [massage, setAlertMassage] = useState<string>();
    const [minEndDate, setMinEndDate] = useState<any>();
    const [selectedGoalTemp, setSelectedGoalTemp] = useState<number>()
    const [templateData, setTemplateData] = useState([]);
    const [noFieldForTemplate, setNoFieldForTemplate] = useState(false);
    const [loadingGoalTemplate, setLoadingGoalTemplate] = useState(false);

    const { locale, t, mask } = useContext(MultiLanguageSupportContext);
    const validationSchema = isAddMode ? Yup.object().shape({
        goalTitle: Yup.string()
            .required(),
        goalDescription: Yup.string()
            .required(),
        goalStartDate: Yup.date()
            .required(),
        goalEndDate: Yup.date()
            .required(),



    }) : Yup.object().shape({
    });
    const { register, handleSubmit, reset, control, setValue, getValues, errors, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data: CaseGoal) {
        let startDateVal = new Date(data.goalStartDate);
        let endDateVal = new Date(data.goalEndDate);
        if ((data.goalStartDate !== null && startDateVal.getTime() > endDateVal.getTime())) {
            setAlertMassage(t("CAM.Case.Goals.StartBeforeEnd"));
            return false;
        }
        return isAddModeEnabled
            ? createCaseGoal(data)
            : updateCaseGoal(parseInt(caseGoal.id!), data);
    }

    function createCaseGoal(data: CaseGoal) {
        data.createdBy = authUserData.currUserData.profileId;
        data.caseId = caseId;
        return caseManagerService.postNewCaseGoal(data)
            .then((rp) => {
                alertService.success(t("CAM.Case.Goals.Created"), { keepAfterRouteChange: true });
                reset();
                cleanSelectedNode({});
                // close(false)
                reSetGoals();
                switchEditMode(rp)
                notificationService.postNewNotifications(
                    {
                        caseId: caseId,
                        senderContactId: authUserData.currUserData.profileId,
                        notificationStatus: "New",
                        notificationTitle: t("ALL.Notifications.CaseCreationTitle"),
                        notificationMessage: t("ALL.Notifications.CaseCreationMessageNote"),
                        supportedKeyWords: [{ Name: "#CaseName", Value: `${referenceNumberOfInsurance}` }, { Name: "#ModifiedBy", Value: !authUserData.currUserData.name ? `${authUserData.currUserData.name} ${authUserData.currUserData.name}` : authUserData.currUserData.profileEmail }],
                        notificationItemUrl: `view-case/${caseId}`,
                        notificationDate: moment(),
                        notificationSettingDictionaryId: 7,
                        scopes: [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId! }, { scopeTypeId: 2, scopeEntityId: caseId }],
                        templateId: 607


                })
            })
            .catch(
                (rp) => {
                    setAlertMassage(t(rp.message));
                }
            );
    }

    function updateCaseGoal(id: number, data: CaseGoal) {

        data.id = id;
        data.modifiedBy = authUserData.currUserData.profileId
        return caseManagerService.postUpdateCaseGoal(data)
            .then(() => {
                alertService.success(t("CAM.Case.Goals.Updated"), { keepAfterRouteChange: true });
                reset();
                cleanSelectedNode({});
                close(false)
                reSetGoals();

            })
            .catch(
                (rp) => {
                    setAlertMassage(t(rp.message));
                }
            );
    }
    function getGoalTemplate(evnt) {
        setLoadingGoalTemplate(true)
        setSelectedGoalTemp(evnt.target.value);
        formTemplateService.getSingleFormTemplate(parseInt(evnt.target.value!)).then(i => {
            if (i.templateContent !== null) {
                setTemplateData(JSON.parse(i.templateContent));
                setNoFieldForTemplate(false)
            } else {
                setTemplateData([])
                setNoFieldForTemplate(true)
            }
            setLoadingGoalTemplate(false)
        }).catch(
            // (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            (rp) => {setTemplateData([])
                setNoFieldForTemplate(true)}
        );

    }
    function getTemplateFormat(id) {
        formTemplateService.getSingleFormTemplate(parseInt(id!)).then(i => {
            if (i.templateContent !== null) {
                setTemplateData(JSON.parse(i.templateContent));
                setNoFieldForTemplate(false)
            } else {
                setTemplateData([])
                setNoFieldForTemplate(true)
            }
            setLoadingGoalTemplate(false)
        }).catch(
            // (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            (rp) => {setTemplateData([])
                setNoFieldForTemplate(true)}
        );
    }

    function cerateNewInstance(formTemplateDataToSave) {
        return formTemplateService.postNewFormInstance(formTemplateDataToSave)
            .then(() => {
                alertService.success(t("CAM.Case.Goals.Updated"), { keepAfterRouteChange: true });
                reset();
                cleanSelectedNode({});
                close(false)
                reSetGoals();

            })
            .catch(
                (rp) => {
                    setAlertMassage(t(rp.message));
                }
            );
    }

    function updateExistingInstance(formTemplateDataToSave) {
        formTemplateDataToSave.id = goalForm.id;
        // formTemplateDataToSave.templateId = goalForm.templateId;
        return formTemplateService.postUpdateFormInstance(formTemplateDataToSave)
            .then(() => {
                alertService.success(t("CAM.Case.Goals.Updated"), { keepAfterRouteChange: true });
                reset();
                cleanSelectedNode({});
                close(false)
                reSetGoals();

            })
            .catch(
                (rp) => {
                    setAlertMassage(t(rp.message));
                }
            );
    }
    function addId(formattedData) {
        const result = formattedData.map(v => ({ ...v, ...goalForm.formFieldsData.find(sp => sp.fieldKey === v.name) })).map(elm => ({ id: elm.id, fieldKey: elm.name, fieldValue: JSON.stringify(elm.value) }));
        return result;
    }
    function updateCaseGoalExtension(id, templatedata) {
        let formattedData = Array.isArray(formData) ? mapFormTemplateData(templatedata) : addId(templatedata);
        let formTemplateDataToSave = {
            //"id": id, -> this only on update as it was in the task
            "insurerId": authUserData.currUserData.insurerId!,
            "templateTypeId": 0,
            "templateId": selectedGoalTemp,
            "entityId": id,
            "caseId": caseId,
            "formFieldsData": formattedData,
            "created": caseGoal.created,
            "createdBy": caseGoal.createdBy,
            "modifiedBy": authUserData.currUserData.profileId,
            "modified": new Date()
        }

        if(Array.isArray(formData)) {
            cerateNewInstance(formTemplateDataToSave);
        } else {
            updateExistingInstance(formTemplateDataToSave);
        }
    }
    function handleSaveGoal(data1) {
        updateCaseGoalExtension(parseInt(caseGoal.id!), data1);
    }


    useEffect(() => {
        setValue("goalStartDate", moment.parseZone(new Date()));
        setValue("goalEndDate", moment.parseZone(new Date()));
        if (!isAddModeEnabled && caseGoal.id) {
            // caseManagerService.getSingleCaseGoal(parseInt(id!)).then(i => {
            setValue("id", caseGoal.id);
            setValue("caseId", caseGoal.caseId);
            // setValue("goalTemplateId",caseGoal.goalTemplateId);
            setValue("goalStartDate", caseGoal.goalStartDate === null ? null : moment.parseZone(new Date(caseGoal.goalStartDate)));
            setValue("goalEndDate", caseGoal.goalEndDate === null ? null : moment.parseZone(new Date(caseGoal.goalEndDate)))
            setValue("goalTitle", caseGoal.goalTitle);
            setValue("goalDescription", caseGoal.goalDescription);
            setValue("completionDOC", caseGoal.completionDOC);
            setValue("completionCOM", caseGoal.completionCOM);
            setValue("completionCLN", caseGoal.completionCLN);
            setValue("completionEMP", caseGoal.completionEMP);
            setValue("templateData",caseGoal.templateData);


            setCaseGoal(caseGoal);
            //}).catch(
            //    (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            // );
            if(caseGoal.id!==undefined) {
                formTemplateService.getSingleFormInstance(0, caseGoal.id)
                .then(x =>  {
                    setGoalForm(x);
                })
                .catch(
                    // (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
                    (rp) => {setGoalForm([]);}
                );
            }

        } else {
            setValue("id", "");
            setValue("caseId", "");
            setValue("goalStartDate", null);
            setValue("goalEndDate", null)
            setValue("goalTitle", "");
            setValue("goalDescription", "");
        }
        
    }, [isAddMode, isAddModeEnabled]);

    function getValueOfObj(data) {
        let tempFieldVal = JSON.parse(data);
        var valuesOfObj = tempFieldVal.map(function(item) {
            return item['value'];
        });
        return valuesOfObj;
    }
    useEffect(() => {
        if(goalForm && goalForm.id && goalForm.id!==undefined && goalForm.formFieldsData!==undefined) {
            // var newObj = Object.assign({}, ...(goalForm.formFieldsData.map(item => ({ [item.fieldKey]: JSON.parse(item.fieldValue) }) )));
            // var newObj = Object.assign({}, ...(goalForm.formFieldsData.map(item => ({ [item.fieldKey]: item.fieldKey.indexOf("tags_") >= 0 ? getValueOfObj(item.fieldValue) : JSON.parse(item.fieldValue) }) )));

            var newObj = Object.assign({}, ...(goalForm.formFieldsData.map(item => (
                { 
                    [item.fieldKey]: (item.fieldKey.indexOf("tags_") >= 0 && item.fieldValue ? getValueOfObj(item.fieldValue) : item.fieldValue!==null ? JSON.parse(item.fieldValue) : "")
                }
                ) 
            )));

            setFormData(newObj)
            getTemplateFormat(goalForm.templateId)

        }
    },[goalForm])

    const [expanded1, setExpandedPanel1] = useState<string | false>(false);
    const [expanded2, setExpandedPanel2] = useState<string | false>(false);
    
    const handleChangePanel1 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedPanel1(isExpanded ? panel : false);
    };

    const handleChangePanel2 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedPanel2(isExpanded ? panel : false);
    };
    // console.log("goalForm data", goalForm)
    // console.log("goalForm", !goalForm)
    // console.log("isAddModeEnabled", !isAddModeEnabled)
    // console.log("!caseGoal.templateData ", !caseGoal.templateData )
    // console.log("goalTemplates", goalTemplates)
    // console.log("formData", formData);
    // console.log("templateData", templateData);
    return (
        <div className="container">
            {massage && <Alert color="danger">
                {t(`${massage}`)}
            </Alert>}

            {isAddModeEnabled &&
                <>
                    <div className="form-row">
                        <div className="form-group col-sm-5">
                            <label>{t("CAM.Case.Goals.Title")}</label>
                            <input name="goalTitle" type="text" ref={register} className={`form-control ${errors.goalTitle ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.goalTitle?.message}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-sm-12">
                            <label>{t("CAM.Case.Goals.Description")}</label>
                            <textarea name="goalDescription" rows={5} ref={register} className={`form-control ${errors.goalDescription ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.goalDescription?.message}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label>{t("CAM.Case.Goals.StartDate")}</label>
                            <Controller
                            render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                    <DatePicker
                                        mask={mask}
                                        value={getValues("goalStartDate")}                                    
                                        onChange={value => {setValue("goalStartDate", value);setMinEndDate(value);}}
                                        renderInput={(params) => <TextField ref={register} {...params} error={Boolean(errors.goalStartDate) && !getValues("goalStartDate") ? true : false}
                                        helperText={errors.goalStartDate && !getValues("goalStartDate") ? (t("CAM.Case.Goals.StartDateValidation")) : ""}/>}
                                    />
                                </LocalizationProvider>)}
                                name="goalStartDate"
                                control={control}

                            />
                        </div>
                        <div className="form-group col-sm-6">
                            <label>{t("CAM.Case.Goals.EndDate")}</label>
                            <Controller
                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                    <DatePicker
                                        mask={mask}
                                        minDate={minEndDate}
                                        value={getValues("goalEndDate")}
                                        onChange={value => setValue("goalEndDate", value)}
                                        renderInput={(params) => <TextField {...params} ref={register} {...params} error={Boolean(errors.goalEndDate) && !getValues("goalEndDate") ? true : false}
                                        helperText={errors.goalEndDate && !getValues("goalEndDate") ? (t("CAM.Case.Goals.EndDateValidation")) : ""}/>}
                                    />
                                </LocalizationProvider>)}
                                name="goalEndDate"
                                control={control}

                            />
                        </div>
                    </div>

                    <div className="form-group row justify-content-center">
                        <div className="form-group col-sm-4">
                            <button type="button" onClick={handleSubmit(onSubmit)} disabled={formState.isSubmitting} className="btn btn-action">
                                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                {t("ALL.Button.Save")}
                            </button>
                        </div>

                        <div className="form-group col-sm-4">
                            <button className="btn btn- grey" onClick={(event) => {
                                event.preventDefault();
                                reset();
                                cleanSelectedNode({});
                                close(false)
                            }}>
                                {t("ALL.Button.Cancel")}
                            </button>
                        </div>
                    </div>
                </>
            }

            {/* {!isAddMode && <>
                <div className="form-row">
                    <div className="form-group col-sm-6">
                        <label>{t("CAM.Case.Goals.CompletionDOC")}</label>
                        <input name="completionDOC" type="number" min="0" max="100" ref={register} className={`col-sm-3 form-control ${errors.capacity ? 'is-invalid' : ''}`} />
                        <div className="invalid-completionDOC">{errors.completionDOC?.message}</div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-sm-6">
                        <label>{t("CAM.Case.Goals.CompletionCAM")}</label>
                        <input name="completionCOM" type="number" min="0" max="100" ref={register} className={`col-sm-3 form-control ${errors.capacity ? 'is-invalid' : ''}`} />
                        <div className="invalid-completionCOM">{errors.completionCOM?.message}</div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-sm-6">
                        <label>{t("CAM.Case.Goals.CompletionCLN")}</label>
                        <input name="completionCLN" type="number" min="0" max="100" ref={register} className={`col-sm-3 form-control ${errors.capacity ? 'is-invalid' : ''}`} />
                        <div className="invalid-completionCLN">{errors.completionCLN?.message}</div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-sm-6">
                        <label>{t("CAM.Case.Goals.CompletionEMP")}</label>
                        <input name="completionEMP" type="number" min="0" max="100" ref={register} className={`col-sm-3 form-control ${errors.capacity ? 'is-invalid' : ''}`} />
                        <div className="invalid-completionEMP">{errors.completionEMP?.message}</div>
                    </div>
                </div>
            </>
            } */}

            {!isAddModeEnabled &&  
                <>
                    <Accordion expanded={expanded1 === 'panel1'} onChange={handleChangePanel1('panel1')}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="card-header card-header-primary header-top"
                        >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        {t("ALL.Case.Goals.InfoBasic")}
                        </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="form-row">
                                <div className="form-group col-sm-5">
                                    <label>{t("CAM.Case.Goals.Title")}</label>
                                    <input name="goalTitle" type="text" ref={register} className={`form-control ${errors.goalTitle ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.goalTitle?.message}</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-sm-12">
                                    <label>{t("CAM.Case.Goals.Description")}</label>
                                    <textarea name="goalDescription" rows={5} ref={register} className={`form-control ${errors.goalDescription ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.goalDescription?.message}</div>
                                </div>
                            </div>
                            <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label>{t("CAM.Case.Goals.StartDate")}</label>
                            <Controller
                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                    <DatePicker
                                        mask={mask}
                                        value={getValues("goalStartDate")}                                    
                                        onChange={value => {setValue("goalStartDate", value);setMinEndDate(value);}}
                                        renderInput={(params) => <TextField  ref={register} {...params} error={Boolean(errors.goalStartDate) && !getValues("goalStartDate") ? true : false}
                                        helperText={errors.goalStartDate && !getValues("goalStartDate") ? (t("CAM.Case.Goals.StartDateValidation")) : ""} />}
                                    />
                                </LocalizationProvider>)}
                                name="goalStartDate"
                                control={control}

                            />
                            <div className="invalid-feedback">{errors.goalStartDate?.message}</div>
                        </div>
                        <div className="form-group col-sm-6">
                            <label>{t("CAM.Case.Goals.EndDate")}</label>
                            <Controller
                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                    <DatePicker
                                        mask={mask}
                                        minDate={minEndDate}
                                        value={getValues("goalEndDate")}
                                        onChange={value => setValue("goalEndDate", value)}
                                        renderInput={(params) => <TextField {...params} ref={register} error={Boolean(errors.goalEndDate) && !getValues("goalEndDate") ? true : false}
                                        helperText={errors.goalEndDate && !getValues("goalEndDate") ? (t("CAM.Case.Goals.EndDateValidation")) : ""}/>}
                                    />
                                </LocalizationProvider>)}
                                name="goalEndDate"
                                control={control}

                            />
                        </div>
                    </div>

                            {/* {isAddModeEnabled || (!isAddModeEnabled && templateData.length === 0) ? */}
                                <div className="form-group row justify-content-center">
                                    <div className="form-group col-sm-4">
                                        <button type="button" onClick={handleSubmit(onSubmit)} disabled={formState.isSubmitting} className="btn btn-action">
                                            {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            {t("ALL.Button.Save")}
                                        </button>
                                    </div>

                                    <div className="form-group col-sm-4">
                                        <button className="btn btn- grey" onClick={(event) => {
                                            event.preventDefault();
                                            reset();
                                            cleanSelectedNode({});
                                            close(false)
                                        }}>
                                            {t("ALL.Button.Cancel")}
                                        </button>
                                    </div>
                                </div>
                            {/* : null
                            } */}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded2 === 'panel2'} onChange={handleChangePanel2('panel2')}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        className="card-header card-header-primary header-top"
                        >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        {t("ALL.Case.Goals.InfoExtended")}
                        </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {((!isAddModeEnabled  && Array.isArray(formData) && !caseGoal.templateData) ||  (!goalForm)) &&
                           
                                <>
                                <div className="form-row">
                                    {goalTemplates ?
                                        <div className="form-group col-sm-6">
                                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                                <Select
                                                    displayEmpty
                                                    labelId="select-small"
                                                    id="select-small"
                                                    value={selectedGoalTemp}
                                                    onChange={getGoalTemplate}
                                                    renderValue={(selected) => {
                                                        if (!selected) {
                                                          return <em>{t("ALL.FormTemplates.SelectFormTemplate")}</em>;
                                                        } else {
                                                            let selectedOption = goalTemplates.map((option) => {
                                                                if(option.id===selected) {
                                                                    return option.templateTitle
                                                                }
                                                            });
                                                            return selectedOption;
                                                        }                                       
                                                    }}
                                                >
                                                    <MenuItem disabled value="">
                                                        <em>{t("ALL.FormTemplates.SelectFormTemplate")}</em>
                                                    </MenuItem>
                                                    {goalTemplates.map((option) => (
                                                        option.templatePublished &&
                                                        <MenuItem key={option.id} value={option.id}>{option.templateTitle}</MenuItem>))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        :
                                        <div className="form-row">
                                            <div className="form-group col-sm-6">
                                                <h4>{t("ALL.FormTemplates.NotAvailable")}</h4>
                                            </div>
                                        </div>
                                    }
                                </div>
                                </>
                            }  
                            
                            {(goalTemplates.length===0 && templateData.length===0 ) && 
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <h4>{t("ALL.FormTemplates.NoneDefined")}</h4>
                                    </div>
                                </div>
                            }

                            {loadingGoalTemplate  &&
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <div className="spinner-border spinner-border-lg align-center"></div>                   
                                    </div>
                                </div>
                            }

                            {
                                !isAddModeEnabled && (templateData !== null && templateData.length > 0 ) ?
                                <>
                                    <div className="form-row">
                                        <div className="form-group col-sm-6">
                                            <ReactFormGenerator
                                                data={templateData}
                                                answer_data={formData ? formData : {}}
                                                onSubmit={handleSaveGoal}
                                                skip_validations={true}
                                                submitButton={<button type="submit" className={"btn btn-primary"} style={{
                                                    "width": "60%",
                                                    "backgroundColor": "#091C53",
                                                    "color": "#fff"
                                                }}
                                                >Submit</button>}
                                                backButton={<a href="/" className="btn btn-default btn-cancel btn-big">Back</a>}
                                            />

                                            <button style={{
                                                "width": "60%",
                                                "position": "relative",
                                                "top": "20px",
                                                "backgroundColor": 'lightgrey'
                                            }} className="btn btn- grey" onClick={(event) => {
                                                event.preventDefault();
                                                reset();
                                                cleanSelectedNode({});
                                                close(false)
                                            }}>
                                                {t("ALL.Button.Cancel")}
                                            </button>

                                        </div>

                                    </div>
                                </>
                                : 
                                noFieldForTemplate ?
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <h4>No fields found for this tempate</h4>
                                    </div>
                                </div> 
                                :
                                null
                            }
                            

                        </AccordionDetails>
                    </Accordion>
                </>
            }

        </div >

    );
}

export { AddEditCaseGoal };