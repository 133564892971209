export interface ISettings{
    key: string, 
    valueText: string, 
    valueNumber:number 
}
export const defaultSettings : ISettings[] = [];

export interface ISettingsProps {
    appSettings: ISettings[],
    setAppSettings: (settings: ISettings[]) => void;
}
