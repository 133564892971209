import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { adminService, alertService } from '../../services/Index';
import 'moment/min/locales';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCheck, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import NotificationSettings from '../../models/NotificationSettings';
import { Checkbox, FormControlLabel, ListItemIcon, ListItemText, MenuItem, Select } from '@mui/material';
import { MenuProps, chanelOptions, resipientsOptions, useStyles } from "./SettingsUtils";
import { IDicScope } from '../../models/Dictionary';
import '../../assets/css/msgSetup.css';
import { ToolTip } from '../ToolTip';
import moment from 'moment';

interface INotificationSettingEditProps {
    refresh: () => void;
    setting: NotificationSettings
    setSelectedItem: (data: NotificationSettings) => void;
    scope: IDicScope;
}
export const NotificationSettingEdit = ({ setting, setSelectedItem, scope, refresh }: INotificationSettingEditProps) => {

    const classes = useStyles();
    const [posibleOverWrite, setPosibleOverWrite] = useState<boolean>(false);
    const { authUserData } = useContext(LoggedUserDataContext);
    const [chanels, setChanels] = useState<string[]>([]);
    const [recipients, setRecipients] = useState<string[]>([]);
    const [isEnabled, setIsEnabled] = useState<boolean>(true);
    const [isChanelControllOpen, setChanelControllOpen] = useState(false);
    const [isRecipientsControllOpen, setRecipientsControllOpen] = useState(false);
    const { t } = useContext(MultiLanguageSupportContext);
    var [settingResipientsOptions, setRecipientsOptions] = useState<string[]>(resipientsOptions);
    const validationSchema = Yup.object().shape({

    });
    const { register, watch, handleSubmit, control, getValues, setValue, errors, formState } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { isDirty, isValid } = formState;
    const [isDirtyMultiSelect, setIsDirtyMultiSelect] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setValue("overdueDays", setting.overdueDays);
            setValue("reminderDays", setting.reminderDays);
        }, 1000);
        setPosibleOverWrite(setting.scopeTypeId == scope.scopeTypeId);
        setChanels(setting.chanels);
        setIsEnabled(setting.isEnabled);
        setRecipients(setting.recipients);
        setRecipientsOptions(resipientsOptions.filter(item => setting.predefinedRecipients.includes(item)));
    }, [setting]);

    async function updateSetting(data: NotificationSettings) {
        data.chanels = chanels;
        data.notificationSettingDictionaryId = setting.notificationSettingDictionaryId;
        data.scopeTypeId = scope.scopeTypeId;
        data.scopeEntityId = scope.scopeEntityId!;
        data.recipients = recipients;
        data.isEnabled = isEnabled;
        if (data.overdueDays === "") delete data.overdueDays;
        if (data.reminderDays === "") delete data.reminderDays;
        if (setting.id !== null) {
            data.id = setting.id;
            data.modifiedBy = authUserData.currUserData.profileId;
            await adminService.postUpdateNotificationSettingItem(data)
                .then((rp) => {
                    alertService.success(t("ALL.Notifications.Setting.Updated"), { keepAfterRouteChange: true });
                    setSelectedItem(rp);
                })
                .catch(
                    (rp) => {
                        alertService.error(t(rp.message), { keepAfterRouteChange: true })

                    }

                );
        }
        else {
            data.createdBy = authUserData.currUserData.profileId;
            await adminService.postNewNotificationSettingItem(data)
                .then((rp) => {
                    alertService.success(t("ALL.Notifications.Messages.SettingsAdded"), { keepAfterRouteChange: true });
                    setSelectedItem(rp.Data);
                })
                .catch(
                    (rp) => {
                        alertService.error(t(rp.message), { keepAfterRouteChange: true })

                    }

                );
        }
    }
    async function createSetting(data: NotificationSettings) {
        data.chanels = chanels;
        data.notificationSettingDictionaryId = setting.notificationSettingDictionaryId;
        data.scopeTypeId = scope.scopeTypeId;
        data.scopeEntityId = scope.scopeEntityId!;
        data.recipients = recipients;
        if (data.overdueDays === "") delete data.overdueDays;
        if (data.reminderDays === "") delete data.reminderDays;
        data.createdBy = authUserData.currUserData.profileId;
        await adminService.postNewNotificationSettingItem(data)
            .then((rp) => {
                alertService.success(t("ALL.Notifications.Messages.SettingsAdded"), { keepAfterRouteChange: true });
                setSelectedItem(rp.Data);
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })

                }

            );

    }
    const handleChanelChange = (event) => {
        setIsDirtyMultiSelect(true);
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setChanels(chanels.length === chanelOptions.length ? [] : chanelOptions);
            return;
        }
        setChanels(value);
    };
    const handleRecipientChange = (event) => {
        setIsDirtyMultiSelect(true);
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setRecipients(recipients.length === settingResipientsOptions.length ? [] : settingResipientsOptions);
            return;
        }
        setRecipients(value);
    };
    const handleOverwrite = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            createSetting(setting);
        }
        else {
            await adminService.deleteNotificationSetting(setting.id)
                .then(() => {
                    alertService.success(t("ALL.Notifications.Messages.SettingsDeleted"), { keepAfterRouteChange: true });
                    refresh();
                })
                .catch(
                    (rp) => {
                        alertService.error(t(rp.message), { keepAfterRouteChange: true })

                    }

                );
        }
        setPosibleOverWrite(event.target.checked);
    };
    const handleSettingActivation = async (enabled: boolean) => {
        setIsEnabled(enabled);
        var data = {
            id: setting.id,
            modifiedBy: authUserData.currUserData.profileId,
            isEnabled: enabled
        }
        await adminService.updateNotificationSettingIsEnabled(data)
            .then(() => {
                alertService.success(t("ALL.Notifications.Messages.SettingsDeleted"), { keepAfterRouteChange: true });
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })

                }

            );
    }
    useEffect(() => {
        if (isDirty) {
            setIsDirtyMultiSelect(true)
        }
    }, [isDirty])

    return (
        <div className="row table-row" >
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header card-header-primary header-top">
                        <h4>{t(`ALL.Dictionary.NotificationSetting.Options.${setting.notificationSettingDictionaryId}`)}</h4>
                        <FontAwesomeIcon icon={faInfoCircle} id={`TooltipSettings${setting.notificationSettingDictionaryId}`} className="info-icon" />
                        <ToolTip infoText={t(`ALL.Dictionary.NotificationSetting.OptionsInfo.${setting.notificationSettingDictionaryId}`)} target={`TooltipSettings${setting.notificationSettingDictionaryId}`} />
                    </div>
                    <div className="card-body">
                        <form className="row form" onSubmit={handleSubmit(updateSetting)}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={posibleOverWrite}
                                                        onChange={handleOverwrite}
                                                    />
                                                }
                                                label={t("ALL.Notifications.SettingsOverwrite")}
                                                labelPlacement="end"
                                            />
                                        </div>
                                    </div>
                                    {posibleOverWrite &&
                                        <div className="col-md-6" style={{ "margin": "2% 0 0" }}>
                                            <div className="form-check form-check-inline">
                                                <input name="isEnabled" type="radio" value="True" ref={register} checked={isEnabled} onChange={() => { handleSettingActivation(true); }} />
                                                <label className="form-check-label">{t("ALL.Notifications.SettingsActive")}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input name="isEnabled" type="radio" value="False" ref={register} checked={!isEnabled} onChange={() => { handleSettingActivation(false) }} />
                                                <label className="form-check-label">{t("ALL.Notifications.SettingsInactive")}</label>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    {!posibleOverWrite &&
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">{t("ALL.Notifications.InheritFromParent")}:</label>
                                                        <div className="col-sm-4">{setting.scopeTypeId != undefined && setting.scopeTypeId != null ? t(`ALL.Dictionary.NotificationSetting.Scopes.${setting.scopeTypeId}`) : t("ALL.Notifications.NotSet")}</div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">{t("ALL.Notifications.Channels")}:</label>
                                                        <div className="col-sm-4">{setting.scopeTypeId != undefined && setting.scopeTypeId != null ? setting.chanels.join(', ') : t("ALL.Notifications.NotSet")}</div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">{t("ALL.Notifications.Receicer")}:</label>
                                                        <div className="col-sm-4">{setting.scopeTypeId != undefined && setting.scopeTypeId != null ? setting.recipients.join(', ') : t("ALL.Notifications.NotSet")}</div>
                                                    </div>
                                                    {setting.isReminder && <>
                                                        <div className="form-group row">
                                                            <label className="col-sm-6 col-form-label">{t("ALL.Notifications.DaysDue")}:</label>
                                                            <div className="col-sm-4">{setting.scopeTypeId != undefined && setting.scopeTypeId != null ? setting.overdueDays : t("ALL.Notifications.NotSet")}</div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-6 col-form-label">{t("ALL.Notifications.DaysRemind")}:</label>
                                                            <div className="col-sm-4">{setting.scopeTypeId != undefined && setting.scopeTypeId != null ? setting.reminderDays : t("ALL.Notifications.NotSet")}</div>
                                                        </div>
                                                    </>}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {posibleOverWrite && isEnabled && <>
                                        <div className="col-4">
                                            {/* offset-md-3 */}
                                            {!setting.onlyEntityRelated &&
                                                <div className="form-group row">
                                                    <label>{t("ALL.Alerts.Recipients")}</label>
                                                    <Controller
                                                        control={control}
                                                        name="recipients"
                                                        render={() => {
                                                            const labelArr = recipients.map((element, index) => {
                                                                return element.replace(/\s+/g, '')
                                                            });
                                                            return (
                                                                <Select
                                                                    style={{ width: "100%", fontSize: "12px" }}
                                                                    multiple
                                                                    value={labelArr}
                                                                    onClose={(event) => {
                                                                        // onBlur(event);
                                                                        setRecipientsControllOpen(false);
                                                                    }}
                                                                    onOpen={() => setRecipientsControllOpen(true)}
                                                                    onChange={handleRecipientChange}
                                                                    open={isRecipientsControllOpen}

                                                                    displayEmpty={true}
                                                                    {...MenuProps}
                                                                    variant={"outlined"}
                                                                    renderValue={(selected) => {
                                                                        return (
                                                                            selected?.map((option) => option).join(", ") ||
                                                                            "Select some options"
                                                                        );
                                                                    }}
                                                                >
                                                                    <MenuItem value="all">
                                                                        <ListItemIcon>
                                                                            <Checkbox
                                                                                classes={{ indeterminate: classes.indeterminateColor }}
                                                                                checked={settingResipientsOptions.length > 0 && labelArr.length === settingResipientsOptions.length}
                                                                                indeterminate={
                                                                                    labelArr.length > 0 && labelArr.length < settingResipientsOptions.length
                                                                                }
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            classes={{ primary: classes.selectAllText }}
                                                                            primary="Select All"
                                                                        />
                                                                    </MenuItem>
                                                                    {settingResipientsOptions.map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            <ListItemIcon>
                                                                                <Checkbox checked={labelArr.indexOf(option) > -1} />
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={option} />
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            }
                                            <div className="form-group row">
                                                <label>{t("ALL.Alerts.Channel")}</label>
                                                <Controller
                                                    control={control}

                                                    name="multipleSelect"
                                                    render={() => {
                                                        const labelArr = chanels.map((element, index) => {
                                                            return element.replace(/\s+/g, '')
                                                        });
                                                        return (
                                                            <Select
                                                                style={{ width: "100%", fontSize: "12px" }}
                                                                multiple

                                                                value={labelArr}
                                                                onClose={(event) => {
                                                                    // onBlur(event);
                                                                    setChanelControllOpen(false);
                                                                }}
                                                                onOpen={() => setChanelControllOpen(true)}
                                                                onChange={handleChanelChange}
                                                                open={isChanelControllOpen}
                                                                displayEmpty={true}
                                                                {...MenuProps}
                                                                variant={"outlined"}
                                                                renderValue={(selected) => {
                                                                    return (
                                                                        selected?.map((option) => option).join(", ") ||
                                                                        "Select some options"
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="all">
                                                                    <ListItemIcon>
                                                                        <Checkbox
                                                                            classes={{ indeterminate: classes.indeterminateColor }}
                                                                            checked={chanelOptions.length > 0 && labelArr.length === chanelOptions.length}
                                                                            indeterminate={
                                                                                labelArr.length > 0 && labelArr.length < chanelOptions.length
                                                                            }
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        classes={{ primary: classes.selectAllText }}
                                                                        primary="Select All"
                                                                    />
                                                                </MenuItem>
                                                                {chanelOptions.map((option) => (
                                                                    <MenuItem key={option} value={option}>
                                                                        <ListItemIcon>
                                                                            <Checkbox checked={labelArr.indexOf(option) > -1} />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={option} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        );
                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-4">
                                            {setting.isReminder && <>

                                                <div className="form-group row">
                                                    <label>{t("ALL.Alerts.DaysOverDue")}</label>
                                                    <input name="overdueDays" type="number" ref={register} className={`form-control ${errors.overdueDays ? 'is-invalid' : ''}`} />
                                                    <div className="invalid-feedback">{errors.overdueDays?.message}</div>
                                                </div>

                                                <div className="form-group row">
                                                    <label>{t("ALL.Alerts.DaysReminder")}</label>
                                                    <input name="reminderDays" type="number" ref={register} className={`form-control ${errors.reminderDays ? 'is-invalid' : ''}`} />
                                                    <div className="invalid-feedback">{errors.reminderDays?.message}</div>
                                                </div>

                                            </>}
                                        </div>
                                        <div className="col-2" style={{ "margin": "4% 0 0" }}>
                                            <div className="form-group row">
                                                <button type="submit" disabled={formState.isSubmitting || !isDirtyMultiSelect} className="btn btn-action">
                                                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                    {t("ALL.Button.Save")}
                                                </button>
                                            </div>
                                        </div>


                                    </>
                                    }
                                </div>
                                {setting.isReminder &&
                                            <div className="row small-text">
                                                <div className="col-2"><label>{`${t("ALL.Notifications.RunDateLast")}: `}</label></div>
                                                <div className="col-2"><label>{setting.lastRunDate?`${moment(setting.lastRunDate).format("L")}`:"-"}</label></div>
                                                <div className="col-2"><label>{`${t("ALL.Notifications.RunDateNext")}: `}</label></div>
                                                <div className="col-2"><label>{setting.nextRunDate?`${moment(setting.nextRunDate).format("L")}`:"-"}</label></div>
                                            </div>
                                        }
                            </div>
                        </form >
                    </div>
                </div>
            </div>
        </div>
    );
}
