import { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { alertService, tasksService } from '../../../services/Index';
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../../ctx/LoggedUserDataProvider';
import TaskTemplate from '../../../models/TaskTemplate';
import { ListTaskRules } from './ListTaskRules';
import TaskGeneratorRule, { mapTaskGeneratorRuleToViewModel } from '../../../models/TaskGeneratorRule';

interface IAddEditTaskTemplateProps {
    ruleTemplate?: any;
    close: any,
    editTemplate: any,
    addTemplate: any,
    cleanSelectedNode: any,
    planTags: any,
    ruleTemplates?: any,
    addTaskRule: any,
    editTaskRule: any,
    deleteTaskRule: any,
    selectedRuleTemp:number
}

const AddEditTaskRuleGenTemplate = ({ planTags, ruleTemplate, selectedRuleTemp, cleanSelectedNode, addTemplate, close, editTemplate, ruleTemplates, addTaskRule, editTaskRule, deleteTaskRule }: IAddEditTaskTemplateProps) => {

    const [taskRules, setTaskRules] = useState<any>();
    const { authUserData } = useContext(LoggedUserDataContext);
    const { t } = useContext(MultiLanguageSupportContext);
    const [loading, setLoading] = useState(true);
    const isAddMode: boolean = Object.entries(ruleTemplate).length === 0

    useEffect(() => {
        if (!isAddMode) {
            setLoading(true);
            setValue("id", ruleTemplate.id);
            setValue("templateTitle", ruleTemplate.templateTitle);
            tasksService.getTaskGeneratorRulesForRuleTemplate(ruleTemplate.id).then((rp) => {
                let response = rp.map((data) => {
                    return mapTaskGeneratorRuleToViewModel(data, t, planTags, authUserData.currUserData.profileLanguage)

                })
                setTaskRules(response);
                setLoading(false);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
        }
    }, [ruleTemplate]);

    const validationSchema = Yup.object().shape({
        templateTitle: Yup.string().required(t("ALL.Tasks.Templates.ValidateTitle")),
    });
    const deleteRule = (taskId: number) => {
        setTaskRules(taskRules!.map(x => {
            if (x.id === taskId) { x.isDeleting = true; }
            return x;
        }));
        tasksService.deleteTaskGeneratorRule(taskId).then((rp) => {
            alertService.success(t("ALL.Tasks.Generetor.Rules.TaskDeleted"), { keepAfterRouteChange: true });
            setTaskRules(taskRules => taskRules!.filter(x => x.id !== taskId));
            deleteTaskRule(taskId);
        }).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                setTaskRules(taskRules!.map(x => {
                    if (x.id === taskId) { x.isDeleting = true; }
                    return x;
                }));
            }

        );
    }
    const addRule = (data: any) => {
        let rule = mapTaskGeneratorRuleToViewModel(data, t, planTags, authUserData.currUserData.profileLanguage)
        setTaskRules(rules => [rule, ...rules!]);
        addRule(data)
    }
    const editRule = (data: any) => {
        setTaskRules(taskRules!.map(x => {
            if (x.id === data.id) { return  mapTaskGeneratorRuleToViewModel(data, t, planTags, authUserData.currUserData.profileLanguage) }
            return x;
        }));
        editRule(data);

    }

    const { register, handleSubmit, control, reset, setValue, getValues, errors, formState } = useForm({
        defaultValues: {
            templateTitle: '',
            templateDescription: '',
            ownerContact: {},
            insurerId: -1,
            isPrivate: false,

        },
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data: any) => {
        return isAddMode
            ? await createRuleTemplate(data)
            : await updateRuleTemplate(data);
    }

    const createRuleTemplate = async(data: any)=> {
        let reqData = {
            scopeTypeId: 0,
            scopeEntityId: authUserData.currUserData.insurerId,
            templateTitle: data.templateTitle,
            createdBy: authUserData.currUserData.profileId
        }
        await tasksService.createTaskGeneratorRuleTemplate(reqData)
            .then((rp) => {
                alertService.success(t("ALL.Tasks.Generator.Rules.Added"), { keepAfterRouteChange: true });
                addTemplate(rp);
                reset();
                close(false);
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                    reset();
                    close(false);
                }
            );
    }
    const updateRuleTemplate = async(data: any)=> {
        data.modifiedBy = authUserData.currUserData.profileId;
        cleanSelectedNode({});
        let editReqObj = {
            id: ruleTemplate.id,
            templateTitle : data.templateTitle,
            modifiedBy : data.modifiedBy
        }
        await tasksService.updateTaskGeneratorRuleTemplate(editReqObj)
            .then((rp) => {
                alertService.success(t("ALL.Tasks.Generetor.Rules.Updated"), { keepAfterRouteChange: true });
                editTemplate(rp);
                reset();
                close(false);
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                    reset();
                    close(false);
                }

            );
    }
    const listTasksProps = {
        deleteTaskRule: deleteRule,
        add: addRule,
        edit: editRule,
        loading: loading,
        taskRuleData: taskRules,
        planTags: planTags,
        ruleTemplateId: ruleTemplate.id,
        ruleTemplates: ruleTemplates,
        selectedRuleTemp:selectedRuleTemp
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <div className="form-group col-sm-6">
                                <label>{t("ALL.Tasks.Generator.Templates.NewRuleSetTitle")}</label>
                                <input name="templateTitle" type="text" ref={register} className={`form-control ${errors.templateTitle ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.templateTitle?.message}</div>
                            </div>
                        </div>
                      
                        <div className="form-group row justify-content-center">
                            <div className="form-group col-sm-4">
                                <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    {t("ALL.Button.Save")}
                                </button>
                            </div>
                            <div className="form-group col-sm-4">
                                <button className="btn btn-grey" onClick={(event) => {
                                    event.preventDefault();
                                    reset();
                                    cleanSelectedNode({});
                                    close(false)
                                }}>
                                    {t("ALL.Button.Cancel")}
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            {!isAddMode && taskRules &&
                <ListTaskRules {...listTasksProps} ruleTemplateId={ruleTemplate.id} />
            }
        </div>

    );
}


export { AddEditTaskRuleGenTemplate };
