import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEye, faTrash } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { faBell } from '@fortawesome/pro-light-svg-icons'

import { useContext } from 'react';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
interface IListNotificationsProps {
    deleteNotification: (id: number) => void,
    setNotificationAsRead: (id: number) => void,
    notifications?: Array<any>,
    tabKey:string
}
function ListNotifications({ setNotificationAsRead, deleteNotification, notifications, tabKey }: IListNotificationsProps) {
    const { t } = useContext(MultiLanguageSupportContext);
    const {authUserData} = useContext(LoggedUserDataContext);
    return (
        <div className="container">
            <div className="row table-row" >
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header card-header-primary header-top">
                        <h4><FontAwesomeIcon icon={faBell} /></h4>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead className="text-primary">
                                        <tr key="header">
                                            <th style={{ width: '20%' }}>{t("ALL.Notifications.Date")}</th>
                                            <th style={{ width: '20%' }}>{t("ALL.Notifications.Title")}</th>
                                            <th style={{ width: '20%' }}>{t("ALL.Notifications.Message")}</th>
                                            <th style={{ width: '20%' }}>{t("ALL.Notifications.Status")}</th>
                                            <th style={{ width: '20%' }}>{t("ALL.Button.Actions")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {notifications && notifications.map(n => <>
                                            <tr key={`${tabKey}-${n.id}`}>
                                                <td>{moment(n.notificationDate).format("L")}</td>
                                                <td>{n.notificationTitle}</td>
                                                <td>{n.notificationMessage}</td>
                                                <td>{n.notificationStatus}</td>
                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                    <Link to={`/${authUserData.currUserData.rolePath}${n.notificationItemUrl.substring(n.notificationItemUrl.indexOf("/"),n.notificationItemUrl.lenght)}`} className="btn btn-md btn-icon-greybtn btn-md btn-icon-grey mr-1" style={{ padding: "0px" }}><FontAwesomeIcon icon={faEye} /></Link>
                                                    {n.notificationStatus !== "Read" && <button onClick={() => setNotificationAsRead(n.id)} className="btn btn-md btn-icon-green fal" disabled={n.inProgress}>
                                                        <FontAwesomeIcon icon={faCheckCircle} /> {n.inProgress && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                    </button>
                                                    }
                                                    <button onClick={() => deleteNotification(n.id)} className="btn btn-md btn-icon-red" disabled={n.isDeleteing}>
                                                        <FontAwesomeIcon icon={faTrash} /> {n.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                    </button>
                                                </td>
                                            </tr>
                                        </>
                                        )}
                                        {!notifications &&
                                            <tr key={`${tabKey}-spinner`}>
                                                <td colSpan={5} className="text-center">
                                                    <div className="spinner-border spinner-border-lg align-center"></div>
                                                </td>
                                            </tr>
                                        }
                                        {notifications && !notifications.length &&
                                            <tr key={`${tabKey}-no-data`}>
                                                <td colSpan={5} className="text-center">
                                                    <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export { ListNotifications };