import BaseService from '../../BaseService';
import Response from "../../../models/Response";

export const notificationService = {
    getAllNotifications,
    postNewAlerts,
    getReceiverContactNotifications,
    getContactCaseNotifications,
    deleteNotification,
    changeNotificationStatus,
    getReceiverContactNewNotifications,
    getSenderContactNewNotifications,
    getReceiverCaseContactNotifications,
    getSenderCaseContactNotifications,
    postNewNotifications
};
async function postNewAlerts(data: any) {
    let res = BaseService.create<any>("Notification/CreateSiteAlerts", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postNewNotifications(data: any) {
    let res = BaseService.create<any>("Notification/CreateNotifications", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function changeNotificationStatus(notificationId: number, status: string) {
    let res = BaseService.get<any>("Notification/ChangeNotificationStatus", `?notificationId=${notificationId}&status=${status}`).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteNotification(notificationId: number): Promise<any> {
    let res = BaseService.delete("Notification/RemoveNotifications?notificationId=", notificationId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getReceiverContactNotifications(contactADId: string): Promise<any[]> {
    let res = BaseService.get<any>("Notification/GetReceiverContactNotifications", `?contactADId=${contactADId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getReceiverCaseContactNotifications(caseId:number,contactADId: string): Promise<any[]> {
    let res = BaseService.get<any>("Notification/GetReceiverCaseContactNotifications", `?contactADId=${contactADId}&&caseId=${caseId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getReceiverContactNewNotifications(contactADId: string): Promise<any[]> {
    let res = BaseService.get<any>("Notification/GetReceiverContactNewNotifications", `?contactADId=${contactADId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSenderContactNewNotifications(contactADId: string): Promise<any[]> {
    let res = BaseService.get<any>("Notification/GetSenderContactNotifications", `?contactADId=${contactADId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSenderCaseContactNotifications(caseId:number,contactADId: string): Promise<any[]> {
    let res = BaseService.get<any>("Notification/GetSenderCaseContactNotifications", `?contactADId=${contactADId}&&caseId=${caseId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getContactCaseNotifications(contactADId: string, caseId: number): Promise<any[]> {
    let res = BaseService.get<any>("Notification/GetContactCaseNotifications", `?contactADId=${contactADId}&caseId=${caseId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllNotifications(token: string): Promise<any[]> {
    let res = BaseService.getAll<any>("Notification/GetAllNotifications").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

