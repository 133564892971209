import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { alertService, tasksService } from '../../services/Index';
import 'moment/min/locales';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { ITaskViewModel, mapTaskToVieModel, Task } from '../../models/Task';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { TextField } from '@mui/material';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import moment from 'moment';

import MultiTagsAutoComplete from '../MultiTagsAutoComplete';
import { mapTaskTemplateToVieModel } from '../../models/TaskTemplate';
import { MultiSelect } from 'react-multi-select-component';
import { IListTasksProps, ListTasks } from './ListTasks';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';

interface ITaskGeneratorFormProps {
    taskTagsOptions: any[],
    casePhaseOptions: any[],
    taskPlanOptions: any[],
    close: any,
    addMultipleTasks: (task: ITaskViewModel[]) => void,
    deleteTask: (id: number) => void,
    add: (task: Task) => void,
    edit: (task: Task) => void,
    tasks?: Array<ITaskViewModel>,
    loading?: boolean,

}
const TaskGeneratorForm = ({ addMultipleTasks, close, taskTagsOptions, casePhaseOptions, taskPlanOptions, deleteTask, edit, add, tasks, loading }: ITaskGeneratorFormProps) => {
    const { authUserData } = useContext(LoggedUserDataContext);
    const { locale, t, mask } = useContext(MultiLanguageSupportContext);
    const { caseData } = useContext(CaseDataContext);
    const [startOfIntegrationTags, setStartOfIntegrationTags] = useState<any[]>([]);
    const [incapacityOfWorkTags, setIncapacityOfWorkTags] = useState<any[]>([]);
    const [startOfIntegrationTagsOptions, setStartOfIntegrationTagsOptions] = useState<any[]>(taskPlanOptions);
    const [incapacityOfWorkTagsOptions, setIncapacityOfWorkTagsOptions] = useState<any[]>(taskPlanOptions);
    const [loader, setLoader] = useState(true);
    const [tasksGeneratorPreview, setTasksGeneratorPreview] = useState<Array<any>>();
    const [taskTemplates, setTaskTemplates] = useState<any[]>([]);
    const [ruleTemplates, setRuleTemplates] = useState<any[]>([]);
    const [selectedTaskTemplates, setSelectedTaskTemplates] = useState<any[]>([]);
    const [seletedRuleTemplates, setSeletedRuleTemplates] = useState<any[]>([]);
    const [viewMode, setViewMode] = useState<boolean>(false);


    useEffect(() => {
        setLoader(true);
        let tempReqData = [{
            "scopeTypeId": 0,
            "scopeEntityId": authUserData.currUserData.insurerId!
        }];
        if (caseData.selectedCaseId !== undefined) {
            var getTaskTemplates = tasksService.getAllUserRelatedTaskTemplates(authUserData.currUserData.contactId!, authUserData.currUserData.insurerId!);
            var getRuleTemplates = tasksService.getScopedRuleGeneratorTemplates(tempReqData);
            Promise.all([getTaskTemplates, getRuleTemplates]).then(async (rp) => {
                let response = rp[0].map((data) => {
                    return mapTaskTemplateToVieModel(data)

                })
                setTaskTemplates(response);
                setRuleTemplates(rp[1]);
                setValue("ruleTemplateIds", null)
                setValue("taskTemplateIds", null)
                setValue("caseId", caseData.selectedCaseId)
                setValue("contactId", authUserData.currUserData.contactId)
                setValue("incapacityOfWorkTags", null)
                setValue("startOfIntegrationTags", null)
                setValue("incapacityOfWorkDate", moment(caseData.incapacityOfWork).year().toString() === "1" ? moment() : moment.parseZone(caseData.incapacityOfWork));
                setValue("startOfIntegrationDate", moment(caseData.startOfIntegration).year().toString() === "1" ? moment() : moment.parseZone(caseData.startOfIntegration));
                setLoader(false);
                setTaskTemplates(response.map(c => ({ value: c.id, label: c.templateTitle })));
                setRuleTemplates(rp[1].map(c => ({ value: c.id, label: c.templateTitle })));

            })
                .catch(
                    (rp) => {
                        console.log(rp);
                    });
        }

    }, [tasksGeneratorPreview]);
    const updateStartOfIntegrationTags = (tags: any[]) => {
        setStartOfIntegrationTags(tags);
        setIncapacityOfWorkTagsOptions(taskPlanOptions.filter(item => !tags.includes(item)));
    };
    const updateIncapacityOfWorkTags = (tags: any[]) => {
        setIncapacityOfWorkTags(tags);
        setStartOfIntegrationTagsOptions(taskPlanOptions.filter(item => !tags.includes(item)));
    };
    const validationSchema = Yup.object().shape({
    });

    const { register, handleSubmit, control, reset, setValue, getValues, errors, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmitSave = handleSubmit(async (data) => {
        let tasks = tasksGeneratorPreview!.map(({ id, ...rest }) => {
            return rest;
        });
        await tasksService.generateTasks(tasks).then((rp) => {
            reset()
            close()
            let tasksResp = rp.map((data) => {
                return mapTaskToVieModel(data, t, taskTagsOptions, casePhaseOptions, taskPlanOptions, authUserData.currUserData.profileLanguage)
            });
            addMultipleTasks(tasksResp)

        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    });

    const onSubmitPreview = handleSubmit(async (data) => {
        data.startOfIntegrationTags = startOfIntegrationTags.map((tt) => (tt.dicGuid));
        data.incapacityOfWorkTags = incapacityOfWorkTags.map((tt) => (tt.dicGuid));
        data.caseId = caseData.selectedCaseId;
        data.incapacityOfWorkDate = moment(caseData.incapacityOfWork).year().toString() === "1" ? moment() : moment.parseZone(caseData.incapacityOfWork);
        data.startOfIntegrationDate = moment(caseData.startOfIntegration).year().toString() === "1" ? moment() : moment.parseZone(caseData.startOfIntegration);
        data.contactId = authUserData.currUserData.contactId;
        data.ruleTemplateIds = seletedRuleTemplates.map((s) => s.value);
        data.taskTemplateIds = selectedTaskTemplates.map((s) => s.value);
        await tasksService.generateTasksPreview(data).then((rp) => {
            let index = 0;
            let tasks = rp.map((data) => {
                index++
                data.id = parseInt(`${data.id}${index}`)
                return mapTaskToVieModel(data, t, taskTagsOptions, casePhaseOptions, taskPlanOptions, authUserData.currUserData.profileLanguage)

            })
            setTasksGeneratorPreview(tasks);

        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    });
    const deletePreviewTask = (taskId: number) => {
        var taskCopy = [...tasksGeneratorPreview!];
        setTasksGeneratorPreview(taskCopy!.filter(item => item.id !== taskId));
    }
    const editTask = (task: Task) => {
        var taskViewModel = tasksGeneratorPreview!.map(x => {
            if (x.id === task.id) { return mapTaskToVieModel(task, t, taskTagsOptions, casePhaseOptions, taskPlanOptions, authUserData.currUserData.profileLanguage) }
            return x;
        });
        setTasksGeneratorPreview(taskViewModel);
    }
    const addTask = (data: Task) => {
        let task = mapTaskToVieModel(data, t, taskTagsOptions, casePhaseOptions, taskPlanOptions, authUserData.currUserData.profileLanguage)
        setTasksGeneratorPreview(resData => [task, ...resData!]);
    }
    const listTasksPreviewProps: IListTasksProps = {
        deleteTask: deletePreviewTask,
        add: addTask,
        edit: editTask,
        tasks: tasksGeneratorPreview!,
        loading: loader,
        taskTagsOptions: taskTagsOptions,
        casePhaseOptions: casePhaseOptions,
        taskPlanOptions: taskPlanOptions,
        listType: "preview"
    }
    const listTasksProps: IListTasksProps = {
        deleteTask: deleteTask,
        add: addTask,
        edit: editTask,
        tasks: tasks,
        loading: loading,
        taskTagsOptions: taskTagsOptions,
        casePhaseOptions: casePhaseOptions,
        taskPlanOptions: taskPlanOptions,
        listType: "preview"
    }

    return (
        <div className="container">
            {loader && <div className="loader">{t("ALL.Common.Loading")}</div>}
            {!loader && <>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-sm-5">
                                            <label>{t("ALL.Tasks.Templates.Title")}</label>
                                            <MultiSelect
                                                options={taskTemplates ? taskTemplates : []}
                                                value={selectedTaskTemplates}
                                                onChange={value => { setValue("taskTemplateIds", value); setSelectedTaskTemplates(value) }}
                                                labelledBy="Select"
                                            />
                                            <div className="invalid-feedback">{errors.taskTemplateIds?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-sm-5">
                                            <label>{t("ALL.Tasks.Generator.TemplateTitle")} </label>
                                            <MultiSelect
                                                options={ruleTemplates ? ruleTemplates : []}
                                                value={seletedRuleTemplates}
                                                onChange={value => { setValue("ruleTemplateIds", value); setSeletedRuleTemplates(value) }}
                                                labelledBy="Select"
                                            />
                                            <div className="invalid-feedback">{errors.ruleTemplateIds?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-sm-5">
                                            <div className="form-group">
                                                <label>{t("CAM.Case.IncapacityOfWork")}</label>
                                                <Controller
                                                    render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                        <DatePicker
                                                            value={moment(caseData.incapacityOfWork).year().toString() === "1" ? moment() : moment.parseZone(caseData.incapacityOfWork)}//getValues("incapacityOfWorkDate")}
                                                            onChange={value => setValue("incapacityOfWorkDate", value)}
                                                            renderInput={(params) => <TextField {...params} />}
                                                            disabled={true}
                                                            mask={mask}
                                                        />
                                                    </LocalizationProvider>)}
                                                    name="incapacityOfWorkDate"
                                                    control={control}
                                                    className="form-control"
                                                />
                                                <div className="invalid-feedback">{errors.incapacityOfWorkDate?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-5">
                                            <div className="form-group">
                                                <label>{t("ALL.Dictionary.Topic.Options.2")}</label>
                                                <MultiTagsAutoComplete control={control} tagsOptions={incapacityOfWorkTagsOptions} controlName="incapacityOfWorkTags" setTaskTags={updateIncapacityOfWorkTags} tags={incapacityOfWorkTags} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-sm-5">
                                            <div className="form-group">
                                                <label>{t("CAM.Case.IntegrationStartDate")}</label>
                                                <Controller
                                                    render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                        <DatePicker
                                                            value={moment(caseData.startOfIntegration).year().toString() === "1" ? moment() : moment.parseZone(caseData.startOfIntegration)}//getValues("startOfIntegrationDate")}
                                                            onChange={value => setValue("startOfIntegrationDate", value)}
                                                            renderInput={(params) => <TextField {...params} />}
                                                            disabled={true}
                                                        />
                                                    </LocalizationProvider>)}
                                                    name="startOfIntegrationDate"
                                                    control={control}
                                                    className="form-control"
                                                />
                                                <div className="invalid-feedback">{errors.startOfIntegrationDate?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-5">
                                            <div className="form-group">
                                                <label>{t("ALL.Dictionary.Topic.Options.2")}</label>
                                                <MultiTagsAutoComplete control={control} tagsOptions={startOfIntegrationTagsOptions} controlName="startOfIntegrationTags" setTaskTags={updateStartOfIntegrationTags} tags={startOfIntegrationTags} />
                                            </div>
                                        </div>
                                    </div>
                                    {viewMode ?
                                        <div className="form-group row justify-content-center">
                                            <div className="form-group col-sm-4">
                                                <button className="btn btn-grey" onClick={(event) => {
                                                    event.preventDefault();
                                                    reset();
                                                    close(false)
                                                }}>
                                                    {t("ALL.Button.Cancel")}
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div className="form-group row justify-content-center">
                                            <div className="form-group col-sm-4">
                                                <button type="submit" disabled={formState.isSubmitting} className="btn btn-action" onClick={onSubmitPreview}>
                                                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                    {t("ALL.Tasks.Generator.Preview")}
                                                </button>
                                            </div>
                                            {tasksGeneratorPreview && tasksGeneratorPreview.length > 0 &&
                                                <div className="form-group col-sm-4">
                                                    <button disabled={formState.isSubmitting} className="btn btn-action" onClick={onSubmitSave}>
                                                        {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                        {t("ALL.Tasks.SaveTasks")}
                                                    </button>
                                                </div>
                                            }
                                            <div className="form-group col-sm-4">
                                                <button className="btn btn-grey" onClick={(event) => {
                                                    event.preventDefault();
                                                    reset();
                                                    close(false)
                                                }}>
                                                    {t("ALL.Button.Cancel")}
                                                </button>
                                            </div>
                                        </div>

                                    }

                                </form>
                            </div>
                        </div >
                    </div >
                </div >
                {tasksGeneratorPreview && <>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <label>{t("ALL.Tasks.Templates.Generator.Preview")}</label>
                            <hr></hr>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <ListTasks {...listTasksPreviewProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <label>{t("ALL.Tasks.Generator.CurrentTasks")}</label>
                            <hr></hr>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <ListTasks {...listTasksProps} />
                        </div>
                    </div>
                </>
                }
            </>
            }
        </div >

    );
}


export { TaskGeneratorForm };
