import { useContext, useEffect, useState } from 'react';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { adminService, alertService } from '../../services/Index';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import Template from '../../models/Template';
import { CustomRichTextEditor } from '../../components/CustomRichTextEditor';
import { useNavigate, useParams } from 'react-router-dom';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';

function EditTemplate() {
    const { authUserData } = useContext(LoggedUserDataContext);
    let navigate = useNavigate();
    let { id } = useParams();
    const { t } = useContext(MultiLanguageSupportContext);
    const [template, setTemplate] = useState<Template>();
    const [templateBody, setTemplateBody] = useState<string>('');
    useEffect(() => {
        adminService.getSingleTemplate(parseInt(id!)).then(i => {
            setTemplateBody(i.body);
            setTemplate(i);
            setValue("id", i.id);
            setValue("title", i.title);
            setValue("body", i.body);
        }).catch(
            (rp: any) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );

    }, [id]);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(t("ALL.Validation.Title"))
    });

    const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data: Template) {
        data.body=templateBody;
        data.id = template!.id
        data.modifiedBy = authUserData.currUserData.profileId
        return adminService.postUpdateTemplate(data)
          .then(() => {
                alertService.success(t("ALL.Settings.Updated"), { keepAfterRouteChange: true });
                navigate('../all-templates');
        })
        .catch(
          (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    }
    function onChange(value: any) {
        setTemplateBody(value);
    }

    return (
        <div className="container">
            {!template &&
                <div className="loader">{t("ALL.Common.Loading")}</div>
            }
            {template && <>
                <p className="component-header">{t("ALL.Settings.Edit")}</p>
                <hr></hr>
                <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                    <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label>{t("ALL.Settings.TemplateTitle")}</label>
                            <input name="title" type="text" ref={register} className={`form-control ${errors.title ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.title?.message}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-sm-12">
                            <CustomRichTextEditor
                                t={t}
                                body={templateBody}
                                onChange={onChange}
                            />
                            <div className="invalid-feedback">{errors.valueText?.message}</div>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-sm-4 justify-content-md-center">
                            <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                {t("ALL.Button.Save")}
                            </button>
                        </div>
                    </div>

                </form>
            </>}
        </div>

    );
}

export { EditTemplate };