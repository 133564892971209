import { Route, Routes } from 'react-router-dom';
import { ListReflections } from './ListReflections';
import { AddEditReflection } from './AddEditReflection';
import { Dashboard } from './Dashboard';
import { EditContact } from '../casemanager/EditContact';
import { ListAssessements } from '../casemanager/ListAssessements';
import { ViewReflection } from './ViewReflection';
import { ListContacts } from '../../components/contact/ListContacts';
import { NotificationManagement } from '../../components/notifications/NotificationManagement';
import { ViewNote } from '../../components/notes/ViewNote';
import { NotesManagement } from '../../components/notes/NotesManagement';
import { DocumentManagement } from '../../components/document/DocumentManagement';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { useContext } from 'react';
import { ViewFeedback } from '../employeer/ViewFeedback';
import { ViewAssessement } from '../doctor/ViewAssessment.';
import { ViewTask } from '../../components/tasks/ViewTask';
import { NotificationSettingsManagement } from '../../components/notifications/NotificationSettingsManagement';
import ViewCase from '../casemanager/ViewCase';

const ClientPanel = () => {
    const { authUserData } = useContext(LoggedUserDataContext);
    return (
        <Routes>
            <Route index element={<Dashboard />} />
            <Route path="/show-reflections">
                <Route path={`:id/:notId`} element={<ListReflections />} />
                <Route index element={<ListReflections />} />
            </Route>
            <Route path={`/add-reflection`} element={<AddEditReflection />} />
            <Route path={`/show-contacts`} element={<ListContacts role="Client" />} />
            <Route path="/show-assessements">
                <Route path={`:id/:notId`} element={<ListAssessements />} />
                <Route index element={<ListAssessements />} />
            </Route>
            <Route path={`/edit-contact/:id`} element={<EditContact />} />
            <Route path={`/notification-management`} element={<NotificationManagement role="Client" caseId={authUserData.currUserData.relatedData[0].id} />} />
            <Route path={`/view-feedback/:id/:notId`} element={<ViewFeedback/>}/>
            <Route path={`/view-reflection/:id/:notId`} element={<ViewReflection/>}/>
            <Route path={`/view-assessement/:id/:notId`} element={<ViewAssessement/>}/>
            <Route path={`/view-note/:id/:notId`} element={<ViewNote />} />
            <Route path={`/view-task/:id/:notId`} element={<ViewTask/>}/>
            <Route path={`/view-case/:id/:notId`} element={<ViewCase/>}/>
            <Route path={`/notes-management`} element={<NotesManagement role="Client" caseId={authUserData.currUserData.relatedData[0].id} />} />
            <Route path="/document-management">
                <Route path={`:id/:notId`} element={<DocumentManagement role="Client" caseId={authUserData.currUserData.relatedData[0].id}/>} />
                <Route path={`:id`} element={<DocumentManagement role="Client" caseId={authUserData.currUserData.relatedData[0].id}/>} />
                <Route path={`:notId`} element={<DocumentManagement role="Client" caseId={authUserData.currUserData.relatedData[0].id}/>} />
                <Route index element={<DocumentManagement role="Client" caseId={authUserData.currUserData.relatedData[0].id}/>} />
            </Route>
            <Route path={`/edit-reflection/:id`} element={<AddEditReflection />} />
            <Route path={`/manage-notification-settings`} element={<NotificationSettingsManagement allScopes={[{scopeTypeId:0,scopeEntityId:-1},{scopeTypeId:1,scopeEntityId:authUserData.currUserData.insurerId},{scopeTypeId:2,scopeEntityId:authUserData.currUserData.relatedData[0].id},{scopeTypeId:3,scopeEntityId:authUserData.currUserData.contactId}]} scope={{scopeTypeId:3,scopeEntityId:authUserData.currUserData.contactId}} />} />
        </Routes>
    );
}
export { ClientPanel };