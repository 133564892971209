import { useContext, useEffect, useState } from 'react';
import { adminService, alertService } from '../../services/Index';
import { useParams } from 'react-router-dom';
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css"
import Insurer from '../../models/Insurer';
import { ProfilePictureCustom } from '../../components/contact/ProfilePicture';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';


const Branding=()=> {
    const [insurerItem, setInsurer] = useState<Insurer>();
    let { id } = useParams();
    const { t } = useContext(MultiLanguageSupportContext);
    useEffect(() => {
        adminService.getSingleInsurer(parseInt(id!)).then(i => {
            setInsurer({
                insurerName: i.insurerName,
                insurerPrefix: i.insurerPrefix,
                insurerADMGroupOpenId: i.insurerADMGroupOpenId,
                insurerCAMGroupOpenId: i.insurerCAMGroupOpenId,
                profileImage: i.profileImage,
                id:i.id,
                createdBy: i.createdBy
            });
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );

    }, []);

    return (
        <div className="container">            
                <p className="component-header">{t("ALL.Contact.ProfilePictureEdit")}</p>
                <hr></hr>
                <div className="white-container">
                {insurerItem &&
                    <div className="row justify-content-md-center">
                        <div className="col-md-6 text-center">
                            <ProfilePictureCustom pictureType="insurer" image={insurerItem!.profileImage} profileId={insurerItem.id!} pictureFormat="square"/>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export { Branding };