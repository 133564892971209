export class FormField{
    public id:number;
    public formMetaDataId:number;
    public fieldKey:string;
    public fieldValue:string;

    
    

    constructor(id:number,
        formMetaDataId:number,fieldKey:string,fieldValue:string) {
        this.id=id;
        this.formMetaDataId=formMetaDataId;
        this.fieldKey=fieldKey;
        this.fieldValue=fieldValue;

    }

}
export class Form{
    public id?:number;
    public insurerId:number;
    public templateTypeId:number;
    public entityId:number;
    public caseId:number;
    public formFieldsData:FormField[]
    public createdBy?:string;
    public created?:Date;
    public modifiedBy?:string;
    public modified?:Date;
    
    

    constructor(
        entityId:number,templateTypeId:number,caseId:number,
        insurerId:number,
        formFieldsData:FormField[],
        createdBy?:string,created?:Date,modifiedBy?:string,modified?:Date,id?:number,) {
        this.id=id;
        this.insurerId=insurerId;
        this.entityId=entityId;
        this.templateTypeId=templateTypeId;
        this.caseId=caseId;
        this.createdBy=createdBy;
        this.formFieldsData=formFieldsData;
        this.created=created;
        this.modifiedBy=modifiedBy;
        this.modified=modified;        
        
    }

}
export class FormEntityIdentifier{
    public insurerId:number;
    public templateId:number;
    public entityId:number;
    public entityTypeId:number;
    public caseId:number;
    

    constructor(
        entityId:number,templateId:number,caseId:number,
        entityTypeId:number,
        insurerId:number) {
        this.insurerId=insurerId;
        this.entityId=entityId;
        this.entityTypeId=entityTypeId;
        this.templateId=templateId;
        this.caseId=caseId;
   
        
    }

}

export const mapFormTemplateData = (data) => {
    const resultArray = data.map(elm => ({ fieldKey: elm.name, fieldValue: JSON.stringify(elm.value)}));
    return resultArray;
}
