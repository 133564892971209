
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

function Chips ({ data, selected, handleChipClick }) {
    return (
        <div>
            <Stack direction="row" spacing={2}>
                {data.map(chipData => (
                    <Chip key={chipData.name} label={chipData.chipLabel} color={selected===chipData.name ? "primary" : "default"} onClick={()=> handleChipClick(chipData.name)} />
                ))}
            </Stack>
        </div>
    );
}

export default Chips;