import { useState, useEffect, useContext } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { useLocation, useParams } from 'react-router-dom';
import { CaseInfoCard } from '../../components/CaseInfoCard';
import Case from '../../models/Case';
import { alertService, caseManagerService } from '../../services/Index';

import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';

const Dashboard=()=> {
    const {authUserData, setAuthUserData } = useContext(LoggedUserDataContext);
    const {id} = useParams();
    const { pathname } = useLocation();
    const [caseItem, setCase] = useState<Case>();
    const { t } = useContext(MultiLanguageSupportContext);
    const { caseData, setCaseData } = useContext(CaseDataContext);
    useEffect(() => {
        if (pathname.indexOf('dashboard') !== -1) {
        caseData.caseNumber = undefined;
        caseData.caseClienName = undefined;  
        caseData.insurerProfileImage =''; 
        caseManagerService.getSingleCaseWithDetails(parseInt(id!)).then(
            (rp) => {
                setCase(rp);
                //LogedUserProps.selectedCaseId = parseInt(id!);
                //LogedUserProps.manageLeftNav(true);
                setAuthUserData(
                    {
                        ...authUserData,
                        selectedCaseId: parseInt(id!),
                        referenceNumberOfInsurance: rp.referenceNumberOfInsurance!
                    });
                    setCaseData(
                        {
                            ...caseData,
                            selectedCaseId: rp.caseData.id,
                            caseNumber: rp.caseNumber,
                            caseClienName: rp.clientData ? rp.clientData.displayName : '',
                            caseClient: rp.clientData,
                            caseDoctor: rp.doctorData,
                            caseEmployeer: rp.employeerData,
                            caseCaseManager: rp.caseManagerData,
                            caseInsurerReference: rp.caseData.referenceNumberOfInsurance,
                            insurerProfileImage: rp.insurerProfileImage,
                            insurerId:rp.caseData.insurerId
                        });
                // caseData.caseNumber = rp.caseNumber;
                // caseData.caseClienName = rp.clientData ? rp.clientData.displayName : '';
                // caseData.caseClient=rp.clientData;
                // caseData.caseEmployeer=rp.employeerData;
                // caseData.caseCaseManager=rp.caseManagerData;
                // caseData.caseDoctor=rp.doctorData;
                // caseData.insurerProfileImage = rp.insurerProfileImage;
                // caseData.caseInsurerReference = rp.caseData.referenceNumberOfInsurance;
                // caseData.manageCaseBreadCrumb(true);
                // LogedUserProps.manageTopNavInsurerPicture(caseData.insurerProfileImage);
            }

        ).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        }

    }, [id]);

    return (
        <div className="container">
            {!caseItem && <div className="loader">{t("ALL.Common.Loading")}</div>}
            {caseItem && <CaseInfoCard caseItem={caseItem} />}
        </div>
    );

}

export { Dashboard };