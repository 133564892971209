import { useState } from "react";
import { Tooltip } from "reactstrap";
interface IProps {
    infoText: string;
    target:string;

}
export const ToolTip = (props:IProps) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);
  
    return (
      <div>
        <Tooltip placement="right" isOpen={tooltipOpen} target={props.target} toggle={toggle} wrapperClassName="info-tooltip">
          {props.infoText}
        </Tooltip>
      </div>
    );
  }