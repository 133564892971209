import BaseService from '../../BaseService';
import Case from '../../../models/Case';
import Response from "../../../models/Response";
import CaseContact from '../../../models/CaseContact';
import CaseGoal from '../../../models/CaseGoal';
//import { StringDecoder } from 'string_decoder';

export const caseManagerService = {
    getCases,
    postNewCase,
    getSingleCase,
    postUpdateCase,
    getUserCases,
    addUserToCaseGroup,
    getCaseContact,
    inviteContactToCase,
    getSingleCaseWithDetails,
    getAllCasesWithDetails,
    generateRaport,
    deleteCaseGoal,
    getCaseGoals,
    postNewCaseGoal,
    getSingleCaseGoal,
    postUpdateCaseGoal,

};

async function postNewCase(data: Case):Promise<Response> {
    let res = BaseService.create<Case>("Case/CreateCase", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateCase(data: Case):Promise<Case>  {
    let res = BaseService.update<Case>("Case/UpdateCase", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCases(): Promise<Array<Case>> {
    let res = BaseService.getAll<Case>("Case/GetAllCases").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getUserCases(insurerId: string, userId: string): Promise<Array<any>> {
    var data = `?insurerId=${insurerId}&userId=${userId}`;
    let res = BaseService.get<any>("Case/GetUserCases", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllCasesWithDetails(param = ""): Promise<Array<any>> {
    let res = BaseService.getAll<any>("Case/GetAllCasesWithDetails" + param).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleCase(CaseId: number): Promise<any> {
    if (CaseId !== -1) {
        let res = BaseService.get<any>("Case/GetSingelCase?caseId=", CaseId).then(
            (rp) => {
                if (rp.Status) {
                    return rp.Data;
                } else {
                    throw new Error(rp.Exception.response.data);
                }
            }
        );
        return res;
    }
    return {};
};
async function generateRaport(content: any, isLandscape: boolean = false, lng:string): Promise<Blob> {
    var data = {
        html: content,
        isLandscape: isLandscape,
        language: lng
    }
    let res = BaseService.uploadFile<any>('Case/GenerateReport', data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleCaseWithDetails(CaseId: number): Promise<any> {
    let res = BaseService.get<any>("Case/GetSingelCaseWithDetails?caseId=", CaseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseContact(caseId: number, roleName: string): Promise<CaseContact[]> {
    let res = BaseService.get<CaseContact[]>("Case/GetCaseContacts?", `caseId=${caseId}&roleName=${roleName}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function inviteContactToCase(data: any) {
    let res = BaseService.update<CaseContact>("Case/InviteContactToCase", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function addUserToCaseGroup(data: any) {

    let res = BaseService.update<CaseContact>("Case/AssignContactToCase", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postNewCaseGoal(data:CaseGoal) {
    let res=BaseService.create<CaseGoal>("Case/CreateCaseGoal", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
    };
    async function postUpdateCaseGoal(data:CaseGoal) {      
        let res=BaseService.update<CaseGoal>("Case/UpdateCaseGoal", data).then(
            (rp) => {
                if (rp.Status) {
                    return rp.Data;
                } else {
                    throw new Error(rp.Exception.response.data);
                }
            }
        );
        return res;
        };
async function getCaseGoals(caseId:number): Promise<Array<CaseGoal>> {
    let res = BaseService.get<CaseGoal[]>("Case/GetAllCaseGoals?", `caseId=${caseId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteCaseGoal(goalId: number): Promise<any> {
    let res = BaseService.delete("Case/DeleteCaseGoal?goalId=", goalId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleCaseGoal(goalId: number): Promise<any> {
        let res = BaseService.get<any>("Case/GetSingelCaseGoal?goalId=", goalId).then(
            (rp) => {
                if (rp.Status) {
                    return rp.Data;
                } else {
                    throw new Error(rp.Exception.response.data);
                }
            }
        );
        return res;
};
