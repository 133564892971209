import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Case from '../../models/Case';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { caseManagerService, alertService, clientService, notificationService } from '../../services/Index';
import moment from 'moment';
import 'moment/min/locales';
import { faChevronLeft, faPaperPlane } from '@fortawesome/pro-light-svg-icons'
import { CaseGoalManagement } from './casegoal/CaseGoalManagement';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { TextField } from '@mui/material';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import { NotificationContext } from '../../ctx/NotificationProvider';


const EditCase = () => {
    let navigate = useNavigate();
    let { id, onCase,notId } = useParams();
    const { authUserData } = useContext(LoggedUserDataContext);
    const { locale, t } = useContext(MultiLanguageSupportContext);
    const [caseItem, setCase] = useState<Case>();
    const { caseData, setCaseData } = useContext(CaseDataContext);
    const [isSending, setIsSending] = useState(false);
    const [loading, setLoading] = useState<any>(true);
    const {notificationsDetails, setNotifications} = useContext(NotificationContext);
    useEffect(() => {
        caseManagerService.getSingleCase(parseInt(id!)).then(i => {
            setValue("dailyAllowanceAmount", i.dailyAllowanceAmount);
            setValue("incapacityOfWork", moment(i.incapacityOfWork).year().toString() === "1" ? moment() : moment.parseZone(i.incapacityOfWork));
            setValue("endOfIntegration", moment(i.endOfIntegration).year().toString() === "1" ? null : moment.parseZone(i.endOfIntegration));
            setValue("startOfIntegration", moment(i.startOfIntegration).year().toString() === "1" ? moment() : moment.parseZone(i.startOfIntegration));
            setValue("endDailyAllowances", moment(i.endDailyAllowances).year().toString() === "1" ? null : moment.parseZone(i.endDailyAllowances));
            setValue("delayDays", i.delayDays);
            setValue("referenceNumberOfInsurance", i.referenceNumberOfInsurance);
            setValue("reflectionRemiderEnabled", i.reflectionRemiderEnabled);
            setValue("mainGoal", i.mainGoal);
            setCase({
                insurerId: i.insurerId,
                mainGoal: i.mainGoal,
                referenceNumberOfInsurance: i.referenceNumberOfInsurance,
                delayDays: i.delayDays,
                dailyAllowanceAmount: i.dailyAllowanceAmount,
                incapacityOfWork: i.incapacityOfWork,
                endOfIntegration: i.endOfIntegration,
                startOfIntegration: i.startOfIntegration,
                endDailyAllowances: i.endDailyAllowances,
                endOfCaseDesk: i.endOfCaseDesk,
                caseDeskEnded: i.caseDeskEnded,
                clnGroupOpenId: i.clnGroupOpenId,
                docGroupOpenId: i.docGroupOpenId,
                empGroupOpenId: i.empGroupOpenId,
                camGroupOpenId: i.camGroupOpenId,
                reflectionRemiderEnabled: i.reflectionRemiderEnabled
            });
            setLoading(false);
        }).catch(
            (rp) => {
                setLoading(false);
                alertService.error(t(rp.message), { keepAfterRouteChange: true })
            }
        );
        if (notId !== undefined) {
            notificationService.changeNotificationStatus(parseInt(notId!), "Read");
            setNotifications({
                ...notificationsDetails,
                notificationNumber:notificationsDetails.notificationNumber-1
            })
        }
    }, [notId]);

    const validationSchema = Yup.object().shape({
        referenceNumberOfInsurance: Yup.string()
            .required(("INS.Validation.InsurerRefNumber")),
        delayDays: Yup.string()
            .required(t("CAM.Validation.DelayDays")),
        dailyAllowanceAmount: Yup.string()
            .required(t("CAM.Validation.AllowancesAmount")),
        //endDailyAllowances: Yup.string()
        //  .required(t("CAM.Validation.AllowancesEnd")),
        startOfIntegration: Yup.string()
            .required(t("CAM.Validation.IntergrationStartDate")),
        //endOfIntegration: Yup.string()
        //  .required(t("CAM.Validation.IntergrationEndDate")),
        incapacityOfWork: Yup.string()
            .required(t("CAM.Validation.WorkIncapacityDate")),
        mainGoal: Yup.string()
            .required(t("CAM.Validation.MainGoal")),

    });

    const { register, handleSubmit, control, setValue, getValues, errors, formState } = useForm({
        defaultValues: {
            dailyAllowanceAmount: "",
            incapacityOfWork: "",
            endOfIntegration: null,
            startOfIntegration: null,
            endDailyAllowances: null,
            delayDays: "",
            referenceNumberOfInsurance: "",
            reflectionRemiderEnabled: false,
            mainGoal: ""
        },
        resolver: yupResolver(validationSchema)
    });
    function onSubmit(data: Case) {
        return updateCase(parseInt(id!), data);
    }
    function sendNotification(caseId: number) {
        setIsSending(true);
        clientService.sendReflectionNotification(caseId).then(
            (x) => {
                if (x.length > 0) {
                    alertService.success(t("ALL.Messages.NotificationSentTo") + x.toString(), { keepAfterRouteChange: true });
                }
                else {
                    alertService.success(t("ALL.Messages.NotificationNotSent"), { keepAfterRouteChange: true });
                }
                setIsSending(false);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    function updateCase(id: number, data: Case) {
        data.id = id;
        data.modifiedBy = authUserData.currUserData.profileId;
        data.incapacityOfWork = new Date(data.incapacityOfWork).toISOString();
        data.endDailyAllowances = data.endDailyAllowances === null ? null : new Date(data.endDailyAllowances).toISOString();
        data.endOfIntegration = data.endOfIntegration === null ? null : new Date(data.endOfIntegration).toISOString();
        data.startOfIntegration = new Date(data.startOfIntegration).toISOString();
        data.reflectionRemiderEnabled = "True";
        return caseManagerService.postUpdateCase(data)
            .then((rp) => {
                alertService.success(t("CAM.Case.Updated"), { keepAfterRouteChange: true });
                navigate(onCase === "Yes" ? `/${authUserData.currUserData.roleType.toLowerCase()}/dashboard/${id}` : `/`);
                setCaseData(
                    {
                        ...caseData,
                        startOfIntegration: new Date(rp.startOfIntegration),
                        incapacityOfWork: new Date(rp.incapacityOfWork)
                    });
                notificationService.postNewNotifications(
                    {
                        caseId: id,
                        senderContactId: authUserData.currUserData.profileId,
                        notificationStatus: "New",
                        notificationTitle: t(""),
                        notificationMessage: t(""),
                        supportedKeyWords: [{ Name: "#CaseName", Value: `${data.referenceNumberOfInsurance}` }, { Name: "#ModifiedBy", Value: !authUserData.currUserData.name ? `${authUserData.currUserData.name} ${authUserData.currUserData.name}` : authUserData.currUserData.profileEmail }],
                        notificationItemUrl: `view-case/${id}`,
                        notificationDate: moment(),
                        notificationSettingDictionaryId: 9,
                        scopes: [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId! }, { scopeTypeId: 2, scopeEntityId: id }],
                        templateId: 609


                    });
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-10 col-md-12">
                    <p><FontAwesomeIcon icon={faChevronLeft} /><Link to={onCase === "Yes" ? `/${authUserData.currUserData.roleType.toLowerCase()}/dashboard/${id}` : `/${authUserData.currUserData.roleType.toLowerCase()}`} className="back-btn">{t("ALL.Button.Back")}</Link></p>
                </div>
                <hr></hr>
            </div>
            <div className="row">
                <div className="col-lg-7 col-md-12">
                    <div className="card">
                        <div className="card-header card-header-warning header-top">
                            <h4 className="card-title">{t("CAM.Case.Edit")}</h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                    <div className="form-group col-sm-12">
                                        <label>{t("INS.Common.InsurerRefNumber")}</label>
                                        <input name="referenceNumberOfInsurance" type="text" ref={register} className={`form-control ${errors.referenceNumberOfInsurance ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.referenceNumberOfInsurance?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("CAM.Case.IncapacityOfWork")}</label>
                                        <Controller
                                            render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                <DatePicker
                                                    //readOnly={true}
                                                    //inputVariant="outlined"
                                                    //id="date-picker-dialog"
                                                    // inputFormat="dd/MM/yyyy"
                                                    // mask={"dd/MM/yyyy"} 
                                                    value={getValues("incapacityOfWork")}
                                                    onChange={value => setValue("incapacityOfWork", value)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>)}
                                            name="incapacityOfWork"
                                            control={control}

                                        />
                                        <div className="invalid-feedback">{errors.incapacityOfWork?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("CAM.Case.DelayDays")}</label>
                                        <input name="delayDays" type="number" min="0" ref={register} className={`form-control ${errors.delayDays ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.delayDays?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("CAM.Case.AllowancesAmount")}</label>
                                        <input name="dailyAllowanceAmount" type="text" ref={register} className={`form-control ${errors.dailyAllowanceAmount ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.dailyAllowanceAmount?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("CAM.Case.AllowancesEnd")}</label>
                                        <Controller
                                            render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                <DatePicker
                                                    //readOnly={true}
                                                    //inputVariant="outlined"
                                                    //id="date-picker-dialog"
                                                    //inputFormat="dd.MM.yyyy"
                                                    value={getValues("endDailyAllowances")}
                                                    onChange={value => setValue("endDailyAllowances", value)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>)}
                                            name="endDailyAllowances"
                                            control={control}

                                        />
                                        <div className="invalid-feedback">{errors.endDailyAllowances?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("CAM.Case.IntegrationStartDate")}</label>
                                        <Controller
                                            render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                <DatePicker
                                                    //readOnly={true}
                                                    //inputVariant="outlined"
                                                    //id="date-picker-dialog"
                                                    //inputFormat="dd.MM.yyyy"
                                                    value={getValues("startOfIntegration")}
                                                    onChange={value => setValue("startOfIntegration", value)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>)}
                                            name="startOfIntegration"
                                            control={control}

                                        />
                                        <div className="invalid-feedback">{errors.startOfIntegration?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("CAM.Case.IntegrationEndDate")}</label>
                                        <Controller
                                            render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                <DatePicker
                                                    //readOnly={true}
                                                    //inputVariant="outlined"
                                                    //id="date-picker-dialog"
                                                    //inputFormat="dd.MM.yyyy"
                                                    value={getValues("endOfIntegration")}
                                                    onChange={value => setValue("endOfIntegration", value)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>)}
                                            name="endOfIntegration"
                                            control={control}

                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-12">
                                        <label>{t("CAM.Case.IntegrationMainGoal")}*</label>
                                        <textarea name="mainGoal" rows={5} ref={register} className={`form-control ${errors.mainGoal ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.mainGoal?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    {/* <div className="form-group col-sm-6">

                                        <label>{t("ALL.Tasks.Reminder.ReminderEnable")}*</label>
                                        <div className={`${errors.reflectionRemiderEnabled ? 'is-invalid-radio' : ''}`}>
                                            <div className="form-check form-check-inline">
                                                <input name="reflectionRemiderEnabled" type="radio" value="True" ref={register} />
                                                <label className="form-check-label">{t("ALL.Button.Yes")}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input name="reflectionRemiderEnabled" type="radio" value="False" ref={register} />
                                                <label className="form-check-label">{t("ALL.Button.No")}</label>
                                                <div className="invalid-feedback">{errors.reflectionRemiderEnabled?.message}</div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="form-group col-sm-2">
                                        <button onClick={() => sendNotification(parseInt(id!))} className="btn btn-md btn-action-casemanager" disabled={isSending}>
                                            <FontAwesomeIcon icon={faPaperPlane} /> {isSending && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        </button>
                                    </div> */}
                                </div>
                                <div className="form-group row justify-content-center">
                                    <div className="col-sm-6">
                                        <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                            {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            {t("CAM.Case.Update")}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-12">
                    <div className="card">
                        {/* {loading && <div className="loader">{t("ALL.Common.Loading")}</div>} */}
                        {!loading &&
                            <CaseGoalManagement caseId={parseInt(id!)} referenceNumberOfInsurance={caseItem!.referenceNumberOfInsurance}
                                insurerId={caseItem!.insurerId}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export { EditCase };