import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Reflection from '../../models/Reflection';
import { alertService, caseManagerService, clientService, notificationService } from '../../services/Index';

import { faList, faEye } from '@fortawesome/pro-light-svg-icons'
import { addDays, addMonths } from 'date-fns';
import moment from 'moment';
import "bootstrap/js/src/collapse.js";
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { Tabs, Tab } from "react-bootstrap";
import { helperFunctions } from '../../helpers/HelperFunctions';
import { StandardListHeader } from '../../components/list/StandardListHeader';
import { StandardReportHeader } from '../../components/list/StandardReportHeader';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { NotificationContext } from '../../ctx/NotificationProvider';


function ListReflections() {
    const { authUserData } = useContext(LoggedUserDataContext);
    const { caseData, setCaseData } = useContext(CaseDataContext);
    moment.locale(authUserData.currUserData.profileLanguage);
    const ref = React.createRef<HTMLDivElement>();
    const { id, notId } = useParams();
    const isNoteMode: boolean = !id;
    const [reflections, setreflections] = useState<Reflection[]>();
    const { t } = useContext(MultiLanguageSupportContext);
    const [calendarIsShown, setCalendarVisibility] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    let navigate = useNavigate();
    const { notificationsDetails, setNotifications } = useContext(NotificationContext);
    const [calendarState, setCalendarState] = useState([
        {
            startDate: addMonths(new Date(), -3),
            endDate: addDays(new Date(), 1),
            key: 'selection'
        }
    ]);
    useEffect(() => {
        if (!isNoteMode) {
            setCaseData(
                {
                    ...caseData,
                    selectedCaseId: parseInt(id!)
                })
        }
        if (caseData.selectedCaseId === undefined) {
            navigate(`/${authUserData.currUserData.roleType.toLowerCase()}`);
        }
        else {
            clientService.getCaseReflectionsInDateRange(caseData.selectedCaseId!, moment(calendarState[0].startDate).format('DD.MM.YYYY'), moment(calendarState[0].endDate).format('DD.MM.YYYY')).then(x => setreflections(x)).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
        }
        if (notId !== undefined) {
            notificationService.changeNotificationStatus(parseInt(notId!), "Read");
            setNotifications({
                ...notificationsDetails,
                notificationNumber: notificationsDetails.notificationNumber - 1
            })
        }
    }, [notId]);

    function changeDateRange(ranges: any) {
        console.log(ranges);
        setCalendarVisibility(!calendarIsShown);
        clientService.getCaseReflectionsInDateRange(caseData.selectedCaseId!, moment(ranges.selection.startDate).format('DD.MM.YYYY'), moment(ranges.selection.endDate).format('DD.MM.YYYY')).then(x => setreflections(x)).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        setCalendarState([ranges.selection]);

    }
    function generateReport(checked) {
        setLoading(true);
        var content = document.getElementById("reflection")!.outerHTML;
        caseManagerService.generateRaport(content, checked, authUserData.currUserData.profileLanguage).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ReflectionReport.pdf');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        });

    }

    return (
        <div className="container">
            <Tabs defaultActiveKey="home">
                <Tab eventKey="home" title={t("ALL.Common.ViewList")}>{!reflections &&
                    <div className="loader">{t("ALL.Common.Loading")}</div>
                }
                    {reflections && <>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <StandardListHeader headerClass="header-top" changeDateRange={changeDateRange} calendarState={calendarState} headerTitle="CLN.Reflection.List" />
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>{t("CLN.Reflection.Date")}</th>
                                                        <th>{t("CLN.Reflection.WorkingTimes")}</th>
                                                        <th>{t("CLN.Reflection.TaskPerformed")}</th>
                                                        <th>{t("CLN.Reflection.HealthStateDuringWork")}</th>
                                                        <th>{t("CLN.Reflection.HealthStateAfterWork")}</th>
                                                        <th>{t("ALL.Button.Actions")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {reflections && reflections.map(r => <>
                                                        <tr key={r.id}
                                                            className="collapsed"
                                                            data-toggle="collapse"
                                                            data-target={".multi-collapse" + r.id!.toString()}
                                                            aria-controls={"multiCollapse" + r.id!.toString()}>
                                                            <td>{moment(r.reflectionDate).format("L")}</td>
                                                            <td>{r.workingTime}</td>
                                                            <td>{r.taskExecuted}</td>
                                                            <td>{t(`CLN.Reflection.HealthState.${r.healthStateDurnigWork}`)}</td>
                                                            <td>{t(`CLN.Reflection.HealthState.${r.healthStateAfterWork}`)}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                                {r.isEditable &&
                                                                    <Link to={`/${authUserData.currUserData.roleType.toLowerCase()}/edit-reflection/${r.id}`} className="btn btn-md btn-icon-grey mr-1"><FontAwesomeIcon icon={faList} /></Link>
                                                                }
                                                                <Link to={`/${authUserData.currUserData.roleType.toLowerCase()}/view-reflection/${r.id}/No`} className="btn btn-md btn-icon-grey mr-1"><FontAwesomeIcon icon={faEye} /></Link>
                                                                <FontAwesomeIcon icon={faChevronRight} />
                                                            </td>
                                                        </tr>
                                                        <tr></tr>
                                                        <tr className={"collapse multi-collapse" + r.id!.toString()} id={"multiCollapse" + r.id!.toString()}>
                                                            <td colSpan={2}>
                                                                <p className="table-collaps-header">{t("CLN.Reflection.TaskPerformed")}</p>
                                                                <p>{r.taskExecuted}</p>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <p className="table-collaps-header">{t("CLN.Reflection.Remarks")}</p>
                                                                <p>{r.remarks}</p>

                                                            </td>
                                                            <td>
                                                                <p className="table-collaps-header">{t("CLN.Reflection.WorkingTimesMorning")}</p>
                                                                <p>{r.workTimeMorningMinutes !== null ? `${r.workTimeMorningMinutes.substring(0, 5)}` : "-"}</p>

                                                            </td>
                                                            <td>
                                                                <p className="table-collaps-header">{t("CLN.Reflection.WorkingTimesAfternoon")}</p>
                                                                <p>{r.workTimeAfternoonMinutes !== null ? `${r.workTimeAfternoonMinutes.substring(0, 5)}` : "-"}</p>

                                                            </td>

                                                        </tr></>
                                                    )}
                                                    {!reflections &&
                                                        <tr>
                                                            <td colSpan={6} className="text-center">
                                                                <div className="spinner-border spinner-border-lg align-center"></div>
                                                            </td>
                                                        </tr>
                                                    }
                                                    {reflections && !reflections.length &&
                                                        <tr>
                                                            <td colSpan={6} className="text-center">
                                                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}</Tab>
                <Tab eventKey="report" title={t("ALL.Common.ViewReport")}>
                    {!reflections &&
                        <div className="loader">{t("ALL.Common.Loading")}</div>
                    }
                    {reflections && <>
                        <StandardReportHeader changeDateRange={changeDateRange} calendarState={calendarState} isLoading={isLoading} generateReport={generateReport} />

                        {/* <Pdf targetRef={ref} filename="ReflectionReport.pdf" x={.5} y={.5} scale={1} options={options}>
                                        {({ toPdf }) => (
                                            <button className="btn btn-sm btn-icon-marine report-icon" onClick={toPdf} ><FontAwesomeIcon icon={faFileDownload} /> {t("ALL.Button.DownloadReport")}
                                            </button>
                                        )}
                                    </Pdf>*/}

                        <div className="report-preview" id="reflection" ref={ref} style={{ backgroundColor: "white", padding: "15px", color: "#000" }}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <p>{t("ALL.Common.ReportCreated")}:<b>{moment().format("L")}</b></p>
                                </div>
                                <div className="col-sm-6  text-right" style={{ textAlign: 'right' }}>
                                    <img
                                        src={helperFunctions.getLogoBase64Url()}
                                        width="170px"
                                        height="48px"
                                        alt="Logo"
                                    />
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-sm-6">
                                    <p>{t("CLN.Reflection.DateRange")}:<b> {moment(calendarState[0].startDate).format('DD.MM.YYYY')}-{moment(calendarState[0].endDate).format('DD.MM.YYYY')}</b></p>
                                    <p>{t("CLN.Reflection.WeekNumber")}: <b>{(moment(calendarState[0].startDate).week() === moment(calendarState[0].endDate).week()) ? moment(calendarState[0].endDate).week() : `${moment(calendarState[0].startDate).week()} - ${moment(calendarState[0].endDate).week()}`}</b></p>
                                    <p>{t("CLN.Reflection.ClientName")}: <b>{caseData.caseClienName}</b></p>
                                    <p>{t("INS.Common.InsurerRefNumber")}: <b>{caseData.caseInsurerReference}</b></p>
                                    <p>{t("CAM.Case.CaseDeskNumber")}: <b>{caseData.caseNumber}</b></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="white-container col-12">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>{t("CLN.Reflection.Date")}</th>
                                                    <th>{t("CLN.Reflection.WorkingTimes")}</th>
                                                    <th>{t("CLN.Reflection.TaskPerformed")}</th>
                                                    <th>{t("CLN.Reflection.HealthStateDuringWork")}</th>
                                                    <th>{t("CLN.Reflection.HealthStateAfterWork")}</th>
                                                    <th>{t("CLN.Reflection.Remarks")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reflections && reflections.map(r => <>
                                                    <tr key={`report-${r.id}`}>
                                                        <td>{moment(r.reflectionDate).format('dddd')}<br></br>{moment(r.reflectionDate).format("L")}</td>
                                                        <td>{r.workingTime}</td>
                                                        <td>{r.taskExecuted}</td>
                                                        <td>{t(`CLN.Reflection.HealthState.${r.healthStateDurnigWork}`)}</td>
                                                        <td>{t(`CLN.Reflection.HealthState.${r.healthStateAfterWork}`)}</td>
                                                        <td>{r.remarks}</td>
                                                    </tr>
                                                </>
                                                )}
                                                {!reflections &&
                                                    <tr>
                                                        <td colSpan={6} className="text-center">
                                                            <div className="spinner-border spinner-border-lg align-center"></div>
                                                        </td>
                                                    </tr>
                                                }
                                                {reflections && !reflections.length &&
                                                    <tr>
                                                        <td colSpan={6} className="text-center">
                                                            <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </Tab>

            </Tabs>
        </div>
    );

}

export { ListReflections };