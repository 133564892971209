
import { apiConfig } from '../../msal/Config';
import LogedUserProps from '../props/LogedUserProps';
let graph = require('@microsoft/microsoft-graph-client');

async function getGraphClient() {
    const client = graph.Client.init({
        authProvider: (done:any) => {
            done(null, LogedUserProps.graphToken);
        }
    });
    return client;
}

export async function getUserDetails() {
    const client = await getGraphClient();
    return await client.api('/me').get();
}

export async function getUserGroups() {
    const client = await getGraphClient();
    const body=
    {
    "securityEnabledOnly": true
    }      
    return await client.api('/me/getMemberGroups').post(body);
}
export async function checkIfAdminGroupMember() {

    const client = await getGraphClient();
    const string = {
        groupIds: [
            apiConfig.adminGroup
        ]
      };
      
    return await client.api('/me/checkMemberGroups')
          .post(string);
}
export async function getGroupMembers(groupId:string) {

    const client = await getGraphClient();  
    let response
    while(response===undefined){
        response= await client.api(`/groups/${groupId}/members`).get();
    }
    return response
}
export async function createInsurerGroups(groupName:string, groupDescription:string) {
    const client = await getGraphClient();
    const group = {
        description: groupDescription,
        displayName: groupName,
        groupTypes: [
          "Unified"
        ],
        mailEnabled: true,
        mailNickname: groupName.replace(/[^A-Za-z0-9]+/, ""),
        securityEnabled: false
      };
      
      return await client.api('/groups')
          .post(group);
}