import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext } from 'react';

import { alertService, caseManagerService, clientService, licenseService } from '../../../services/Index';

import moment from 'moment';
import * as rdrLocales from 'date-fns/locale';
import 'moment/min/locales';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "bootstrap/js/src/collapse.js";
import { faChevronRight} from '@fortawesome/pro-light-svg-icons'

import { Tabs, Tab } from "react-bootstrap";

import { StandardListHeader } from '../../../components/list/StandardListHeader';
import { StandardReportHeader } from '../../../components/list/StandardReportHeader';
import { helperFunctions } from '../../../helpers/HelperFunctions';
import { MultiSelect } from 'react-multi-select-component';
import { useNavigate, useParams} from 'react-router-dom';
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../../ctx/CaseDataProvider';

const LicenseReport=()=> {
    const { caseData } = useContext(CaseDataContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    moment.locale(authUserData.currUserData.profileLanguage);
    let { insurerId } = useParams();
    const [options, setOptions] = useState<any[]>([]);
    const [selectedCases, setSelectedCases] = useState<any[]>([]);
    const [licenses, setLicenses] = useState<any[]>();
    const { t } = useContext(MultiLanguageSupportContext);
    const [calendarIsShown, setCalendarVisibility] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [checked, setChecked] = React.useState(false);
    const toggleChecked = () => {
        setChecked((prev) => !prev);
    };
    const [calendarState, setCalendarState] = useState([
        {
            startDate: new Date(moment().clone().startOf('month').format('YYYY-MM-DD hh:mm')),
            endDate: new Date(moment().clone().endOf('month').format('YYYY-MM-DD hh:mm')),
            key: 'selection'
        }
    ]);
    function sum(items, prop) {
        return items.reduce(function (a, b) {
            return a + b[prop];
        }, 0);
    };
    const [calendarLocale, setCalendarLocale] = useState<any>(rdrLocales.enAU);
    useEffect(() => {
        switch (authUserData.currUserData.profileLanguage.toLowerCase()) {
            case "en": setCalendarLocale(rdrLocales.enAU);
                break;
            case "de": setCalendarLocale(rdrLocales.de);
                break;
            case "fr": setCalendarLocale(rdrLocales.fr);
                break;
        }
        caseManagerService.getAllCasesWithDetails(`?insurerId=${insurerId}`).then(
            (rp) => {
                setOptions(rp?.map(c => ({ label: c.caseNumber, value: c.caseData.id })))
                licenseService.getLicensesInDateRange(parseInt(insurerId!), [rp.map(a => a.caseData.id)].toString(), moment(calendarState[0].startDate).format('DD.MM.YYYY'), moment(calendarState[0].endDate).format('DD.MM.YYYY')).then(x => {
                    var grouped = Object.values(groupBy(x, 'caseNumber'));
                    setLicenses(grouped)
                }).catch(
                    (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
                );
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );

    }, []);
    function caseSelectionChanged(data) {
        setSelectedCases(data);
        licenseService.getLicensesInDateRange(parseInt(insurerId!), data.length > 0 ? data.map(a => a.value).toString() : options.map(a => a.value).toString(), moment(calendarState[0].startDate).format('DD.MM.YYYY'), moment(calendarState[0].endDate).format('DD.MM.YYYY')).then(x => {
            var grouped = Object.values(groupBy(x, 'caseNumber'));
            setLicenses(grouped)
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );

    }
    function groupBy(objectArray, property) {
        return objectArray.reduce((acc, obj) => {
            const key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }
    function changeDateRange(ranges: any) {
        console.log(ranges);
        setCalendarVisibility(!calendarIsShown);
        licenseService.getLicensesInDateRange(parseInt(insurerId!), selectedCases.length > 0 ? selectedCases.map(a => a.value).toString() : options.map(a => a.value).toString(), moment(ranges.selection.startDate).format('DD.MM.YYYY'), moment(ranges.selection.endDate).format('DD.MM.YYYY')).then(x => {
            var grouped = Object.values(groupBy(x, 'caseNumber'));
            setLicenses(grouped)
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        setCalendarState([ranges.selection]);

    }
    function generateReport() {
        setLoading(true);
        var content = document.getElementById("license")!.outerHTML;
        caseManagerService.generateRaport(content, checked, authUserData.currUserData.profileLanguage).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'LicenseReport.pdf');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        });

    }
    return (
        <div className="container">
            <Tabs defaultActiveKey="home">

                <Tab eventKey="home" title={t("ALL.Common.ViewList")}>{!licenses &&
                    <div className="loader">{t("ALL.Common.Loading")}</div>
                }
                    {licenses && <>
                        <div className="row table-row">
                            <div className="col-md-12">
                                <div className="card">
                                    <StandardListHeader headerClass="header-top-cam" changeDateRange={changeDateRange} calendarState={calendarState} headerTitle="ALL.License.Overview" />

                                    <div className="col-sm-3 text-right" style={{ paddingTop: "10px", paddingRight: "0px" }}>
                                        <MultiSelect
                                            options={options}
                                            value={selectedCases}
                                            onChange={caseSelectionChanged}
                                            labelledBy="Select"
                                        />
                                    </div>

                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                            <thead className="text-primary-cam">
                                                    <tr>
                                                        <th>{t("CAM.Case.CaseDeskNumber")}</th>
                                                        <th>{t("ALL.License.AssignmentAmount")}</th>
                                                        <th>{t("ALL.License.AssignmentCostsPerDay")}</th>
                                                        <th>{t("ALL.Button.ShowMore")}</th>
                                                        <th>{t("ALL.License.AssignmentTotalCosts")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {licenses && licenses.map(grouped =>
                                                        grouped.map((r, index) => <>
                                                            <tr key={r.id}
                                                                className="collapsed"
                                                                data-toggle="collapse"
                                                                data-target={".multi-collapse" + r.id!.toString()}
                                                                aria-controls={"multiCollapse" + r.id!.toString()}>
                                                                <td>{r.caseNumber}</td>
                                                                <td>{r.amountInRange}</td>
                                                                <td>{r.amountPerDay}</td>
                                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                                    <FontAwesomeIcon icon={faChevronRight} />
                                                                </td>
                                                                {grouped.length === index + 1 &&
                                                                    <td><b>{sum(grouped, 'amountInRange')}</b></td>
                                                                }
                                                            </tr>
                                                            <tr></tr>
                                                            <tr className={"collapse multi-collapse" + r.id!.toString()} id={"multiCollapse" + r.id!.toString()} style={{ fontStyle: "italic" }}>
                                                                <td colSpan={1}>
                                                                    <p >{t("ALL.License.AssignmentDateFrom")}</p>
                                                                    <p>{moment(r.assignmentStartDate).format("L")}</p>
                                                                </td>
                                                                <td colSpan={1}>
                                                                    <p>{t("ALL.License.AssignmentDateTo")}</p>
                                                                    <p>{moment(r.assignmentEndDate).format("L")}</p>

                                                                </td>
                                                                <td colSpan={3}>
                                                                    <p>{t("ALL.Dictionary.Role.CaseManager")}</p>
                                                                    <p>{r.caseManager}</p>

                                                                </td>


                                                            </tr></>
                                                        )
                                                    )}
                                                    {!licenses &&
                                                        <tr>
                                                            <td colSpan={6} className="text-center">
                                                                <div className="spinner-border spinner-border-lg align-center"></div>
                                                            </td>
                                                        </tr>
                                                    }
                                                    {licenses && !licenses.length &&
                                                        <tr>
                                                            <td colSpan={6} className="text-center">
                                                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}</Tab>
                <Tab eventKey="report" title={t("ALL.Common.ViewReport")}>
                    {!licenses &&
                        <div className="loader">{t("ALL.Common.Loading")}</div>
                    }
                    {licenses && <>
                        <StandardReportHeader changeDateRange={changeDateRange} calendarState={calendarState} isLoading={isLoading} generateReport={generateReport} />
                        <div className="report-preview" id="license" style={{ backgroundColor: "white", padding: "15px" }}>
                            <div className="row justify-content-md-center"><h3>{t("ALL.License.ReportTitle")}</h3></div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <p>{t("ALL.Common.ReportCreated")}:<b>{moment().format("L")}</b></p>
                                </div>
                                <div className="col-sm-6  text-right" style={{ textAlign: 'right' }}>
                                    <img
                                        src={helperFunctions.getLogoBase64Url()}
                                        width="170px"
                                        height="48px"
                                        alt="Logo"
                                    />
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-sm-6">
                                    <p>{t("CLN.License.DateRange")}:<b> {moment(calendarState[0].startDate).format('DD.MM.YYYY')}-{moment(calendarState[0].endDate).format('DD.MM.YYYY')}</b></p>
                                    <p>{t("ALL.Dictionary.Role.Insurer")}: <b>{caseData.caseInsurerReference}</b></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="white-container col-11">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>{t("CAM.Case.CaseDeskNumber")}</th>
                                                <th>{t("ALL.Dictionary.Role.CaseManager")}</th>
                                                <th>{t("ALL.License.AssignmentDateFrom")}</th>
                                                <th>{t("ALL.License.AssignmentDateTo")}</th>
                                                <th>{t("ALL.License.AssignmentAmount")}</th>
                                                <th>{t("ALL.License.AssignmentCostsPerDay")}</th>
                                                <th>{t("ALL.License.AssignmentTotalCosts")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {licenses && licenses.map(grouped =>
                                                grouped.map((r, index) => <>
                                                    <tr key={`report-${r.id}`}>
                                                        <td>{r.caseNumber}</td>
                                                        <td>{r.caseManager}</td>
                                                        <td>{moment(r.assignmentStartDate).format("L")}</td>
                                                        <td>{moment(r.assignmentEndDate).format("L")}</td>
                                                        <td>{r.amountInRange}</td>
                                                        <td>{r.amountPerDay}</td>
                                                        {grouped.length === index + 1 &&
                                                            <td><b>{sum(grouped, 'amountInRange')}</b></td>
                                                        }
                                                    </tr></>
                                                )
                                            )}
                                            {!licenses &&
                                                <tr>
                                                    <td colSpan={6} className="text-center">
                                                        <div className="spinner-border spinner-border-lg align-center"></div>
                                                    </td>
                                                </tr>
                                            }
                                            {licenses && !licenses.length &&
                                                <tr>
                                                    <td colSpan={6} className="text-center">
                                                        <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </Tab>
            </Tabs>

        </div>
    );

}

export { LicenseReport };