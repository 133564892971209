import { useContext, useEffect, useState } from 'react';
import { msalConfig } from '../../msal/Config';
import { alertService, caseManagerService, contactService } from '../../services/Index';
import { ManageAddingContact } from '../../components/contact/ManageAddingContact';

import { CaseContactActions } from '../../components/contact/CaseContactActions';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { DisplayContact } from '../../components/contact/DisplayContact';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';


interface IManageGroupMembersProps {
    groupId: string,
    groupName: string,
    caseId?: number,
    role?: string,
    insurerId?: number
}

function ManageGroupMembers({ groupId, caseId, groupName, insurerId, role }: IManageGroupMembersProps) {
    const [members, setMembers] = useState<Array<any>>();
    const [loadingMembers, setLoadingMembers] = useState<boolean>(true);
    const { authUserData } = useContext(LoggedUserDataContext);
    const { t } = useContext(MultiLanguageSupportContext);
    useEffect(() => {
        if (groupId) {
            caseManagerService.getCaseContact(caseId!, groupName.replace(/\s/g, '')).then(g => {
                console.log(g);
                setMembers(g);
                setLoadingMembers(false);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
        }

    }, [groupId]);
    function addCaseMember(data: any) {
        delete data.confirmEmail;
        data.createdBy = authUserData.currUserData.profileId;
        data.modifiedBy = authUserData.currUserData.profileId;
        data.groupId = groupId;
        data.redirectUrl = msalConfig.auth.redirectUri;
        data.insurerId = authUserData.currUserData.insurerId != -1 ? authUserData.currUserData.insurerId : insurerId;
        data.roleType = groupName!.replace(/\s/g, '');
        data.caseId = caseId;
        return caseManagerService.addUserToCaseGroup(data)
            .then(rp => {
                alertService.success(t("ALL.Contact.Added"), { keepAfterRouteChange: true });
                var newMember = { contact: rp.contact, welcomeSent: rp.welcomeSent, invited: rp.invited, newUser: rp.newUser };
                setMembers((groupMembers: any) => [newMember, ...members!]);
            })
            .catch(

                (rp) => {
                    console.log(rp);
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                }
            );
    }

    function deleteCaseMember(id: number, adId: string) {
        setMembers(members!.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));
        var data =
        {
            userId: id,
            groupId: groupId,
            caseId: caseId,
            userActveDirectoryId: adId

        }
        contactService.removeContactFromGroupAsync(data).then(() => {
            alertService.success(`${groupName} removed`, { keepAfterRouteChange: true });
            setMembers(m => m?.filter(x => x.contact.id !== id));
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );

    }
    function onSubmit(data: any, action: string) {
        switch (action) {
            case 'add':
                return addCaseMember(data)
            case 'delete':
                return deleteCaseMember(data.id, data.adId)
        }
    }
    return (

        <div className="card-body" style={{ minHeight: "480px" }}>
            {!members &&
                <div className="loader">{t("ALL.Common.Loading")}</div>
            }
            {members && <>
                <ManageAddingContact requiered={true} caseContact={true} action={onSubmit} members={members} 
                groupName={groupName} 
                caseId={caseId} role={role} 
                loadingMembers={loadingMembers} />

                {members.length > 0 && <>
                    <DisplayContact contact={members.find(o => true).contact} groupName={groupName!.replace(/\s/g, '').toLowerCase()} editable={groupName.replace(/ /g, '') === authUserData.currUserData.roleType || (role !== "Client" && role !== "Doctor" && role !== "Employeer")} />
                    {role !== "Client" && role !== "Doctor" && role !== "Employeer" &&
                        <CaseContactActions caseContact={members.find(o => true)} groupId={groupId} groupName={groupName} caseId={caseId} />
                    }
                </>}
                {members.length === 0 && (role === "Client" || role === "Doctor" || role === "Employeer") &&
                    <p className="card-text">{t("ALL.Contact.NoAssignment")}</p>}


            </>
            }
        </div>

    );
}

export { ManageGroupMembers };