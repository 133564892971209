import BaseService from '../../BaseService';
import Reflection from '../../../models/Reflection';
import Response from "../../../models/Response";

export const clientService = {
    getCaseReflections,
    postNewReflection,
    getSingleReflection,
    postUpdateReflection,
    getCaseReflectionMajorInfo,
    sendReflectionNotification,
    getCaseReflectionsInDateRange,
    getCaseReflectionsDescendingDateReflection,
    getCasesReflectionMajorInfo,
    getCaseReflectionHealthStateTrendAsync

};

async function postNewReflection(data: Reflection):Promise<Response> {
    let res = BaseService.create<Reflection>("Reflection/CreateReflection", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateReflection(data: Reflection):Promise<Reflection> {
    let res = BaseService.update<Reflection>("Reflection/UpdateReflection", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseReflections(caseId: number): Promise<Array<Reflection>> {
    let res = BaseService.get<Reflection>("Reflection/GetCaseReflections?caseId=",caseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseReflectionsDescendingDateReflection(caseId: number): Promise<Array<Reflection>> {
    let res = BaseService.get<Reflection>("Reflection/GetCaseReflectionsDescendingDateReflection?caseId=",caseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseReflectionsInDateRange(caseId: number,startDate:string,endDate:string): Promise<Array<Reflection>> {
    let res = BaseService.get<Reflection>("Reflection/GetCaseReflectionsInDateRange?",`caseId=${caseId}&startDateIn=${startDate}&endDateIn=${endDate}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseReflectionMajorInfo(caseId: number): Promise<any> {
    let res = BaseService.get<any>("Reflection/GetCaseReflectionMajorInfo?caseId=",caseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseReflectionHealthStateTrendAsync(caseId: number): Promise<any> {
    let res = BaseService.get<any>("Reflection/GetCaseReflectionHealthStateTrend?caseId=",caseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCasesReflectionMajorInfo(casesId: string): Promise<any> {
    let res = BaseService.get<any>("Reflection/GetCasesReflectionMajorInfo?",casesId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

async function sendReflectionNotification(caseId: number): Promise<any> {
    let res = BaseService.get<any>("Reflection/SendReflectionNotification?caseId=",caseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleReflection(reflectionId: number): Promise<Reflection> {
    let res = BaseService.get<Reflection>("Reflection/GetSingelReflection?reflectionId=", reflectionId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
