import { useContext, useEffect, useState } from 'react';
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import { tasksService } from '../../../services/casedesk/tasks/TaskService';
import { ITaskRulesProps, ListTaskRules } from './ListTaskRules';
import TaskGeneratorRule, { mapTaskGeneratorRuleToViewModel } from '../../../models/TaskGeneratorRule';
import { Menu, Tooltip, ListItemIcon, IconButton } from '@mui/material';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import { PaperProps } from "../TaskUtils";
import Settings from '@mui/icons-material/Settings';
import TaskIcon from '@mui/icons-material/Task';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { ListTaskRuleGenTemplates } from './ListTaskRuleGenTemplates';
import { adminService } from '../../../services/casedesk/admin/AdminService';
import { LoggedUserDataContext } from '../../../ctx/LoggedUserDataProvider';
import { alertService } from '../../../services/Index';
import { helperFunctions } from '../../../helpers/HelperFunctions';


export const TaskRulesManagement = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRuleTemp, setSelectedRuleTemp] = useState<number>(1)
    const [ruleTemplates, setRuleTemplates] = useState<any>([]);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [taskRuleData, setTaskRuleData] = useState<any>();
    const [loading, setLoading] = useState(true);
    const { language, t } = useContext(MultiLanguageSupportContext);
    const [planTags, setPlanTags] = useState<any[]>([]);
    const { authUserData } = useContext(LoggedUserDataContext);

    moment.locale(language);

    useEffect(() => {
        setLoading(true);
        adminService.getScopedDictionaries(2, [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId }],
            authUserData.currUserData.profileLanguage.toUpperCase()).then((respDic) => {
                var lngReducedTags=helperFunctions.getUniqueDictionaryPerLanguage(respDic, "dicGuid", authUserData.currUserData.profileLanguage.toUpperCase());
                setPlanTags(lngReducedTags);
                tasksService.getAllTaskGeneratorRules().then((rp) => {
                    let response = rp.map((data) => {
                        return mapTaskGeneratorRuleToViewModel(data, t, lngReducedTags, authUserData.currUserData.profileLanguage)

                    })
                    setTaskRuleData(response);
                    setLoading(false);
                }).catch(
                    (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
                );

            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );

        let tempReqData = [{
            "scopeTypeId": 0,
            "scopeEntityId": authUserData.currUserData.insurerId!
        }];
        tasksService.getScopedRuleGeneratorTemplates(tempReqData).then((rp) => {
            setRuleTemplates(rp);
            if (rp && rp.length > 0) {
                setSelectedRuleTemp(rp[0].id)
            }
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    }, [])

    const addTaskRule = (data: TaskGeneratorRule) => {
        let rule = mapTaskGeneratorRuleToViewModel(data, t, planTags, authUserData.currUserData.profileLanguage)
        setTaskRuleData(taskRuleData => [rule, ...taskRuleData!]);
    }

    const editTaskRule = (data: TaskGeneratorRule) => {
        let rule = mapTaskGeneratorRuleToViewModel(data, t, planTags, authUserData.currUserData.profileLanguage)
        setTaskRuleData(taskRuleData!.map(x => {
            if (x.id === rule.id) { return rule }
            return x;
        }));
    }

    const deleteTaskRule = (taskRuleId: number) => {
        setTaskRuleData(taskRuleData!.map(x => {
            if (x.id === taskRuleId) { x.isDeleting = true; }
            return x;
        }));

        tasksService.deleteTaskGeneratorRule(taskRuleId).then((rp) => {
            alertService.success(t("ALL.Tasks.Generator.Rules.Deleted"), { keepAfterRouteChange: true });
            setTaskRuleData(taskRuleData => taskRuleData!.filter(x => x.id !== taskRuleId));
        }).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                setTaskRuleData(taskRuleData!.map(x => {
                    if (x.id === taskRuleId) { x.isDeleting = true; }
                    return x;
                }));
            }

        );
    }

    const listTaskRulesProps: ITaskRulesProps = {
        deleteTaskRule: deleteTaskRule,
        add: addTaskRule,
        edit: editTaskRule,
        taskRuleData: taskRuleData!,
        loading: loading,
        planTags: planTags,
        ruleTemplates: ruleTemplates,
        selectedRuleTemp:selectedRuleTemp
    }
    function filterRuleTemp(evnt) {
        console.log("evnt.target.value", evnt.target.value);
        setSelectedRuleTemp(evnt.target.value);

    }

    const ruleTemplateAdd = (template) => {
        setRuleTemplates(taskTemplates => [template, ...taskTemplates!]);
    }

    const ruleTemplateDelete = (taskTemplateId) => {
        setRuleTemplates(ruleTemplates => ruleTemplates.filter(({ id }) => id !== taskTemplateId));
    }

    const ruleTemplateUpdate = (template) => {
        setRuleTemplates(ruleTemplates!.map(x => {
            if (x.id === template.id) { return template }
            return x;
        }));
    }

    return (<>
        {!taskRuleData && <div className="loader">{t("ALL.Common.Loading")}</div>}
        {taskRuleData &&
            <div className="container">
                <div className="row">
                    <div className="col-md-11 align-self-start">
                        <p className="component-header">{t("ALL.Tasks.Generator.Rules.Title")}</p>
                    </div>
                    <div className="col-md-1 align-self-end">
                        <Tooltip title={t("ALL.Tasks.Templates.Title")}>
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Settings fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={PaperProps}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                            <MenuItem onClick={() => { setModalOpen(true) }}>
                                <ListItemIcon>
                                    <TaskIcon fontSize="small" />
                                </ListItemIcon>
                                {t("ALL.Tasks.Templates.Manage")}
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                <hr></hr>
                            <Grid item xs={12} md={3} lg={2} style={{ display: "flex", justifyContent: "flex-end" }}>

                                    <Grid item xs={5}>
                                        <div className="form-row">
                                            <div className="form-group col-sm-12">
                                                <FormControl sx={{ m: 1, minWidth: 250 }}>
                                                    {ruleTemplates.length>0 ? 
                                                        <>
                                                            <label>{t("ALL.Tasks.Generator.Templates.ChooseRuleSet")}</label>
                                                            <Select
                                                                labelId="select-small"
                                                                id="select-small"
                                                                value={selectedRuleTemp}
                                                                autoWidth
                                                                onChange={filterRuleTemp}
                                                            >
                                                                {ruleTemplates.map((option) => (
                                                                    <MenuItem value={option.id}>{option.templateTitle}</MenuItem>))}
                                                            </Select>
                                                        </>
                                                    :
                                                        <label>{t("L-XX-Create rule set")}</label>
                                                    }
                                                    
                                                </FormControl>
                                            </div>
                                        </div>
                                    </Grid>

                            </Grid>
                <div className="col-md-12">
                    <ListTaskRules {...listTaskRulesProps} />
                </div>

                <Modal toggle={() => { setModalOpen(!modalOpen) }} isOpen={modalOpen} style={{ maxWidth: "1000px" }}>
                    <ModalHeader style={{ display: "block" }}>
                        {t("ALL.Tasks.Generator.Templates.RuleSets")}
                        <span style={{ justifyContent: "right" }}>
                            <button type="submit" className="btn btn-md btn-icon-grey mr-1 float-right" onClick={() => { setModalOpen(!modalOpen) }}>
                                <FontAwesomeIcon icon={faCircleXmark} />
                            </button>
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        <ListTaskRuleGenTemplates 
                            planTags={planTags}
                            ruleTemplateAdd={ruleTemplateAdd}
                            ruleTemplateDelete={ruleTemplateDelete}
                            ruleTemplates={ruleTemplates}
                            ruleTemplateUpdate={ruleTemplateUpdate}
                            addTaskRule={addTaskRule}
                            editTaskRule={editTaskRule}
                            deleteTaskRule={deleteTaskRule}
                            selectedRuleTemp={selectedRuleTemp}
                        />
                    </ModalBody>
                </Modal>
            </div>

        } </>
    );
}
