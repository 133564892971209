import { EditContactControl } from '../../components/contact/EditContactControl';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Link, useParams, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { useContext } from 'react';


interface IEditContactProps {
    returnPath?: string;
}
function EditContact({ returnPath }:IEditContactProps ) {
    let { id } = useParams();
    let navigate = useNavigate();
    const retunAction = () => {
        returnPath !== undefined ? navigate(returnPath!) : navigate('..');
    }
    const { t } = useContext(MultiLanguageSupportContext);
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-10 col-md-12">
                    <div className="card">
                        <div className="card-header card-header-warning header-top">
                            <h4 className="card-title">{t("ALL.Contact.Edit")}</h4>
                            <p><FontAwesomeIcon icon={faChevronLeft} /><Link to={`..`} className="back-btn">{t("ALL.Button.Back")}</Link></p>
                        </div>
                        <div className="card-body">
                            <EditContactControl id={parseInt(id!)} returnAction={retunAction} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { EditContact };