import License from "../../../../models/License";
import BaseService from "../../../BaseService";
import Response from "../../../../models/Response";
import LicenseAssignement from "../../../../models/LicenseAssignement";

export const licenseService = {
    postNewLicense,
    postUpdateLicense,
    getLicenses,
    getSingleLicense,
    getInsurerLicensesAsync,
    deleteLicense,
    getSingleLicenseAssignement,
    postNewLicenseAssignement,
    postUpdateLicenseAssignement,
    getCaseLicenseAssignement,
    getInsurerActiveLicenseAssignementAsync,
    getInsurerActiveLicensesAsync,
    deleteLicenseAssignement,
    getAllInsurerLicensesAssignementsAsync,
    getLicensesInDateRange,
    getInsurerLicenseAssignementAsync

};
async function postNewLicense(data: License):Promise<Response>  {
    let res = BaseService.create<License>("License/CreateLicense", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateLicense(data: License):Promise<License>  {

    let res = BaseService.update<License>("License/UpdateLicense", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getLicensesInDateRange(insurerId: number,casesId:string,startDate:string,endDate:string): Promise<Array<License>> {
    let res = BaseService.get<License>("License/GetLicensesInDateRange?",`insurerId=${insurerId}&casesId=${casesId}&startDateIn=${startDate}&endDateIn=${endDate}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

async function getLicenses(): Promise<Array<License>> {
    let res = BaseService.getAll<License>("License/GetAllLicenses").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

async function getSingleLicense(licenseId: number): Promise<License> {
    let res = BaseService.get<License>("License/GetSingelLicense?licenseId=", licenseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseLicenseAssignement(caseId: number): Promise<Array<LicenseAssignement>> {
    let res = BaseService.get<Array<LicenseAssignement>>("License/GetCaseLicenseAssignement?caseId=", caseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleLicenseAssignement(licenseAssignementId: number): Promise<LicenseAssignement> {
    let res = BaseService.get<LicenseAssignement>("License/GetSingelLicenseAssignements?licenseAssignementId=", licenseAssignementId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postNewLicenseAssignement(data: LicenseAssignement) {
    let res = BaseService.create<LicenseAssignement>("License/AddCaseLicenseAssignement", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateLicenseAssignement(data: LicenseAssignement) {

    let res = BaseService.update<LicenseAssignement>("License/UpdateLicenseAssignement", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

async function getInsurerActiveLicenseAssignementAsync(insurerId: number): Promise<any> {
    let res = BaseService.get<any>("License/GetInsurerActiveLicenseAssignement?insurerId=", insurerId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getInsurerLicenseAssignementAsync(insurerId: number): Promise<any> {
    let res = BaseService.get<any>("License/GetInsurerLicenseAssignement?insurerId=", insurerId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getInsurerActiveLicensesAsync(insurerId: number): Promise<any> {
    let res = BaseService.get<any>("License/GetInsurerActiveLicenses?insurerId=", insurerId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

async function getInsurerLicensesAsync(insurerId: number): Promise<any> {
    let res = BaseService.get<any>("License/GetInsurerLicense?insurerId=", insurerId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllInsurerLicensesAssignementsAsync(insurerId: number,licenseId:number): Promise<any> {
    let res = BaseService.get<any>("License/GetAllInsurerLicenseAssignements?insurerId=", `${insurerId}&licenseId=${licenseId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteLicense(licenseId: number): Promise<any> {
    let res = BaseService.delete("License/DeleteLicense?licenseId=", licenseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteLicenseAssignement(licenseId: number): Promise<any> {
    let res = BaseService.delete("License/DeleteLicenseAssignement?licenseId=", licenseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
