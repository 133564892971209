import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Case from '../../models/Case';
import { caseManagerService, alertService, notificationService } from '../../services/Index';
import moment from 'moment';
import 'moment/min/locales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { NotificationContext } from '../../ctx/NotificationProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';


const  ViewCase=()=> {

    const { t } = useContext(MultiLanguageSupportContext);
    const [caseItem, setCase] = useState<Case>();
    const { authUserData } = useContext(LoggedUserDataContext);
    let {id,notId}= useParams();
    const {notificationsDetails, setNotifications} = useContext(NotificationContext);
    useEffect(() => {
        caseManagerService.getSingleCase(parseInt(id!)).then(i => {
            setCase({
                insurerId: i.insurerId,
                mainGoal: i.mainGoal,
                referenceNumberOfInsurance: i.referenceNumberOfInsurance,
                delayDays: i.delayDays,
                dailyAllowanceAmount: i.dailyAllowanceAmount,
                incapacityOfWork: i.incapacityOfWork,
                endOfIntegration: i.endOfIntegration,
                startOfIntegration: i.startOfIntegration,
                endDailyAllowances: i.endDailyAllowances,
                endOfCaseDesk: i.endOfCaseDesk,
                caseDeskEnded: i.caseDeskEnded,
                clnGroupOpenId: i.clnGroupOpenId,
                docGroupOpenId: i.docGroupOpenId,
                empGroupOpenId: i.empGroupOpenId,
                camGroupOpenId: i.camGroupOpenId,
                reflectionRemiderEnabled:i.reflectionRemiderEnabled
            });
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        if (notId !== "No") {
            notificationService.changeNotificationStatus(parseInt(notId!), "Read");
            setNotifications({
                ...notificationsDetails,
                notificationNumber:notificationsDetails.notificationNumber-1
            })
            //LogedUserProps.manageUserNotifications();
        }

    }, []);
    return (
        <div className="container">
            {caseItem &&
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-header card-header-warning header-top">
                                <h4 className="card-title">{t("CAM.Case.Properties")}</h4>

                                <p><FontAwesomeIcon icon={faChevronLeft} /><Link to={`/${authUserData.currUserData.rolePath}/all-cases`} className="back-btn">{t("ALL.Button.Back")}</Link></p>

                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label className="col-form-label-bold">{t("INS.Common.InsurerRefNumber")}</label>
                                        <div>{caseItem.insurerId}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-3">
                                        <label className="col-form-label-bold">{t("CAM.Case.IncapacityOfWork")}</label>
                                        <div>{moment(caseItem.incapacityOfWork).year().toString() === "1" ? "-" : moment(caseItem.incapacityOfWork).format("L")}</div>
                                    </div>
                                    <div className="form-group col-sm-3">
                                        <label className="col-form-label-bold">{t("CAM.Case.DelayDays")}</label>
                                        <div>{caseItem.delayDays}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-3">
                                        <label className="col-form-label-bold">{t("CAM.Case.AllowancesAmount")}</label>
                                        <div>{caseItem.dailyAllowanceAmount}</div>
                                    </div>
                                    <div className="form-group col-sm-3">
                                        <label className="col-form-label-bold">{t("CAM.Case.AllowancesEnd")}</label>
                                        <div>{moment(caseItem.endDailyAllowances).year().toString() === "1" ? "-" : moment(caseItem.endDailyAllowances).format("L")}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-3">
                                        <label className="col-form-label-bold">{t("CAM.Case.IntegrationStartDate")}</label>
                                        <div>{moment(caseItem.startOfIntegration).year().toString() === "1" ? "-" : moment(caseItem.startOfIntegration).format("L")}</div>
                                    </div>
                                    <div className="form-group col-sm-3">
                                        <label className="col-form-label-bold">{t("CAM.Case.IntegrationEndDate")}</label>
                                        <div>{moment(caseItem.endOfIntegration).year().toString() === "1" ? "-" : moment(caseItem.endOfIntegration).format("L")}</div>

                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label className="col-form-label-bold">{t("CAM.Case.IntegrationMainGoal")}</label>
                                        <label>{caseItem.mainGoal}</label>
                                    </div>
                                </div>
                                <div className="form-row">
                                <div className="form-group col-sm-6">
                                    <label className="col-form-label-bold">{t("EMP.Feedback.Limitations")}</label>
                                        <label>{caseItem.reflectionRemiderEnabled === "True" ? t("ALL.Button.Yes") : t("ALL.Button.No")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            }
        </div>
    );

}

export default ViewCase;
