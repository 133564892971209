import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { alertService, notificationService, tasksService } from '../../services/Index';
import 'moment/min/locales';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Task } from '../../models/Task';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';

import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import MultiTagsAutoComplete from '../MultiTagsAutoComplete';

import ContactAutoComplete from '../../components/contact/ContactAutoComplete';
import { caseManagerService } from '../../services/casedesk/casemanager/CaseManagerService';
import { ToolTip } from '../ToolTip';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
interface IAddEditTaskProps {
    taskTagsOptions: any[],
    casePhaseOptions: any[],
    taskPlanOptions: any[],
    task?: any;
    close: any,
    edit: any,
    add: any,
    cleanSelectedNode: any,
    taskTemplateId?: number,
    view: boolean,
    selectedChip: String,
    listType?: string
}
const AddEditTask = ({ taskTemplateId, listType, cleanSelectedNode, view, add, task, close, edit, taskTagsOptions, casePhaseOptions, taskPlanOptions, selectedChip }: IAddEditTaskProps) => {
    const { authUserData } = useContext(LoggedUserDataContext);
    const [phase, setPhase] = useState<string>("");
    const { locale, t, mask } = useContext(MultiLanguageSupportContext);
    const { caseData } = useContext(CaseDataContext);
    const isAddMode: boolean = Object.entries(task).length === 0
    const [taskTags, settaskTags] = useState<any[]>([]);
    const [viewMode, setViewMode] = useState<boolean>(false);
    const [resteAutocomplete, setResetAutocomplete] = useState((new Date()).toString());
    const [members, setMembers] = useState<Array<any>>([]);

    useEffect(() => {
        if (!isAddMode) {
            console.log("task edit", task);
            setValue("caseId", task.caseId);
            setValue("taskTitle", task.taskTitle);
            setValue("taskType", 0);
            setValue("startTime", moment(task.startTime).year().toString() === "1" ? moment() : moment.parseZone(task.startTime));
            setValue("dueTime", moment(task.dueTime).year().toString() === "1" ? moment() : moment.parseZone(task.dueTime));
            setValue("owner", task.owner);
            setValue("assigned", task.assigned.email);
            setValue("taskStatus", task.taskStatus);
            setValue("taskDescription", task.taskDescription);
            setValue("reminderIntervallHours", task.reminderIntervallHours);
            setValue("reminderTemplateId", task.reminderTemplateId);
            setValue("priority", task.priority);
            setValue("planTag", task.planTag);
            setValue("templateSourceId", task.templateSourceId);
            setPhase(task.casePhaseTag);
            settaskTags(taskTagsOptions!.filter((t) => task.taskTags?.includes(t.dicGuid)).map(n => {
                return n;
            }));

        } else {
            setValue("dueTime", moment().add(7, 'days'));
            setValue("taskStatus", 1);
            setValue("priority", 2);
            setValue("reminderIntervallHours", 3);

        }
        setViewMode(false)
    }, [task, view]);

    // useEffect(() => {
    //     caseManagerService.getCaseContact(caseData.selectedCaseId!, ("Case Manager").replace(/\s/g, '')).then(g => {
    //         setMembers(g);

    //     }).catch(
    //         (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
    //     );
    // }, [])

    const validationSchema = Yup.object().shape({
        // userEmail: Yup.string().email()
        // .required(t("ALL.Contact.Validation.EMail")),
        taskTitle: Yup.string().required(t("ALL.Tasks.Validation.Title")),
    });

    const { register, handleSubmit, control, reset, setValue, getValues, errors, formState } = useForm({
        defaultValues: {
            caseId: caseData.selectedCaseId,
            taskTitle: "",
            taskType: null,
            startTime: null,
            dueTime: moment().add(7, 'days'),
            owner: null,
            assigned: "",
            taskStatus: null,
            taskDescription: "",
            taskTags: "",
            reminderIntervallHours: null,
            reminderTemplateId: null,
            priority: null,
            casePhaseTag: null,
            planTag: null,
            templateSourceId: null,
        },
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data: Task) => {
        return isAddMode
            ? await createTask(data)
            : await updateTask(data);
    }

    const createTask = async (data: Task) => {
        // let assignedTo = members.find(o => {
        //     if (o.contact.email === getValues("userEmail")) {
        //         return o;
        //     }
        // }
        // );
        data.owner = {
            id: authUserData.currUserData.contactId!
        }
        data.assigned = {
            id: authUserData.currUserData.contactId!
        }
        if (taskTemplateId !== undefined) {
            data.templateId = taskTemplateId;
        }
        data.taskTags = taskTags.map((tt) => (tt.dicGuid));
        // if (typeof (data.planTag) === "string" && data.planTag==="") {
        //     delete data["planTag"];
        // }

        data.casePhaseTag = phase;
        data.dueTime = data.dueTime === null ? new Date() : data.dueTime;
        data.startTime = new Date();
        data.caseId = caseData.selectedCaseId!;
        data.createdBy = { id: authUserData.currUserData.contactId!, userActveDirectoryId: authUserData.currUserData.profileId };
        cleanSelectedNode({});
        if (listType === "preview") {
            add(data);
            reset();
            close(false);
        }
        else {
            await tasksService.postNewTask(data)
                .then((rp) => {
                    alertService.success(t("ALL.Tasks.Messages.TaskAdded"), { keepAfterRouteChange: true });
                    add(rp.Data);
                    reset();
                    close(false);
                    notificationService.postNewNotifications(
                        {
                            caseId: caseData.selectedCaseId,
                            senderContactId: authUserData.currUserData.profileId,
                            notificationStatus: "New",
                            notificationTitle: t(""),
                            notificationMessage: t(""),
                            notificationItemUrl: `view-task/${rp.Data.id}`,
                            notificationDate: moment(),
                            notificationSettingDictionaryId: 10,
                            scopes: [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId! }, { scopeTypeId: 2, scopeEntityId: caseData.selectedCaseId }],
                            templateId: 610
                        });
                })
                .catch(
                    (rp) => {
                        alertService.error(t(rp.message), { keepAfterRouteChange: true })
                        reset();
                        close(false);
                    }
                );
        }
    }
    const updateTask = async (data: Task) => {
        // let assignedTo = members.find(o => {
        //     if (o.contact.email === getValues("userEmail")) {
        //         return o;
        //     }
        // }
        // );
        data.taskTags = taskTags.map((tt) => (tt.id.toString()));
        data.owner = {
            id: authUserData.currUserData.contactId!
        }
        data.assigned = {
            id: authUserData.currUserData.contactId!
        }
        data.taskTags = taskTags.map((tt) => (tt.dicGuid));
        // if (typeof (data.planTag) === "string" && data.planTag==="") {
        //     delete data["planTag"];
        // }
        data.casePhaseTag = phase;
        data.id = task.id;
        data.taskType = task.taskType;
        data.startTime = task.startTime;
        data.caseId = caseData.selectedCaseId!;
        data.assigned = task.assigned;
        data.owner = task.owner;
        data.reminderTemplateId = task.reminderTemplateId;
        data.reminderDate = task.reminderDate;
        data.createdBy = task.createdBy;
        data.modifiedBy = { id: authUserData.currUserData.contactId!, userActveDirectoryId: authUserData.currUserData.profileId };
        cleanSelectedNode({});
        if (listType === "preview") {
            edit(data, selectedChip);
            reset();
            close(false);
        }
        else {
            await tasksService.postUpdateTask(data)
                .then((rp) => {
                    alertService.success(t("ALL.Tasks.Messages.TaskUpdated"), { keepAfterRouteChange: true });
                    edit(rp, selectedChip);
                    reset();
                    close(false);
                    notificationService.postNewNotifications(
                        {
                            caseId: caseData.selectedCaseId,
                            senderContactId: authUserData.currUserData.profileId,
                            notificationStatus: "New",
                            notificationTitle: t(""),
                            notificationMessage: t(""),
                            notificationItemUrl: `view-task/${data.id}`,
                            notificationDate: moment(),
                            notificationSettingDictionaryId: 11,
                            scopes: [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId! }, { scopeTypeId: 2, scopeEntityId: caseData.selectedCaseId }],
                            templateId: 611
                        });
                })
                .catch(
                    (rp) => {
                        alertService.error(t(rp.message), { keepAfterRouteChange: true })
                        reset();
                        close(false);
                    }

                );
        }
    }

    // const AntSwitch = styled(Switch)(({ theme }) => ({
    //     width: 28,
    //     height: 16,
    //     padding: 0,
    //     display: 'flex',
    //     '&:active': {
    //         '& .MuiSwitch-thumb': {
    //             width: 15,
    //         },
    //         '& .MuiSwitch-switchBase.Mui-checked': {
    //             transform: 'translateX(9px)',
    //         },
    //     },
    //     '& .MuiSwitch-switchBase': {
    //         padding: 2,
    //         '&.Mui-checked': {
    //             transform: 'translateX(12px)',
    //             color: '#fff',
    //             '& + .MuiSwitch-track': {
    //                 opacity: 1,
    //                 backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
    //             },
    //         },
    //     },
    //     '& .MuiSwitch-thumb': {
    //         boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    //         width: 12,
    //         height: 12,
    //         borderRadius: 6,
    //         transition: theme.transitions.create(['width'], {
    //             duration: 200,
    //         }),
    //     },
    //     '& .MuiSwitch-track': {
    //         borderRadius: 16 / 2,
    //         opacity: 1,
    //         backgroundColor:
    //             theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    //         boxSizing: 'border-box',
    //     },
    // }));

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header card-header-warning header-top">
                            <h4 className="card-title">{isAddMode ? t("ALL.Tasks.Button.AddTask") : view ? t("ALL.Tasks.Button.TasksDetails") : t("ALL.Tasks.Button.EditTask")}
                                <span>
                                    <button type="submit" className="btn btn-md btn-icon-grey mr-1 float-sm-right" onClick={close}>
                                        <FontAwesomeIcon icon={faCircleXmark} />
                                    </button>
                                </span>
                            </h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* {view && !isAddMode ? 
                                <div className="form-row">
                                <div className="form-group col-sm-12">
                                    <Stack direction="row" spacing={1} alignItems="center" className="float-sm-right">
                                        <Typography>View</Typography>
                                        <AntSwitch checked={!viewMode} inputProps={{ 'aria-label': 'ant design' }} onChange={switchMode}/>
                                        <Typography>Edit</Typography>
                                    </Stack>
                                </div> 
                                </div>
                                : null } */}


                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <div className="form-row">
                                            <div className="form-group col-sm-6">
                                                <label>{t("ALL.Tasks.Title")}*</label>
                                                <input name="taskTitle" type="text" ref={register} className={`form-control ${errors.taskTitle ? 'is-invalid' : ''}`} disabled={viewMode ? true : false} />
                                                <div className="invalid-feedback">{errors.taskTitle?.message}</div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-sm-8">
                                                <label>{t("ALL.Tasks.Description")}</label>
                                                <textarea name="taskDescription" rows={5} ref={register} className={`form-control ${errors.taskDescription ? 'is-invalid' : ''}`} disabled={viewMode ? true : false} />
                                                <div className="invalid-feedback">{errors.taskDescription?.message}</div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-sm-8">
                                                <label>{t("ALL.Tasks.DueDate")}</label>
                                                <Controller
                                                    render={() => (
                                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                            {/* <DateTimePicker
                                                            value={getValues("dueTime")}
                                                            onChange={value => setValue("dueTime", value)}
                                                            renderInput={(params) => <TextField {...params} />}
                                                            disabled={viewMode ? true : false}
                                                            mask={mask}
                                                        /> */}
                                                            <DatePicker
                                                                value={getValues("dueTime")}
                                                                onChange={value => setValue("dueTime", value)}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                disabled={viewMode ? true : false}
                                                                mask={mask}
                                                            />
                                                        </LocalizationProvider>

                                                    )}
                                                    name="dueTime"
                                                    control={control}

                                                />
                                                {/* <div className="invalid-feedback">{errors.dueTime?.message}</div> */}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-sm-8">
                                                <label>{t("ALL.Tasks.Status.StatusTitle")}</label>
                                                <select name="taskStatus" ref={register} className={`form-control ${errors.taskStatus ? 'is-invalid' : ''}`} disabled={viewMode ? true : false}>
                                                    <option value={1}>{t("ALL.Tasks.Status.Options.1")}</option>
                                                    <option value={2}>{t("ALL.Tasks.Status.Options.2")}</option>
                                                    <option value={3}>{t("ALL.Tasks.Status.Options.3")}</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.taskStatus?.message}</div>
                                            </div>

                                        </div>
                                       {/* <div className="form-row">
                                            <div className="form-group col-sm-8">
                                                <label>{t("ALL.Tasks.Reminder.ReminderTitle")}</label>
                                                <select name="reminderIntervallHours" ref={register} className={`form-control ${errors.reminderIntervallHours ? 'is-invalid' : ''}`} disabled={viewMode ? true : false}>
                                                    <option value={1}>{t("ALL.Tasks.Reminder.Option1")}</option>
                                                    <option value={2}>{t("ALL.Tasks.Reminder.Option2")}</option>
                                                    <option value={3}>{t("ALL.Tasks.Reminder.Option3")}</option>
                                                    <option value={4}>{t("ALL.Tasks.Reminder.Option4")}</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.reminderIntervallHours?.message}</div>
                                            </div>

                                        </div>*/}
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <div className="form-row">
                                            <div className="form-group col-sm-8">
                                                <label>{t("ALL.Dictionary.Topic.Options.2")}</label>
                                                <select name="planTag" ref={register} className={`form-control ${errors.planTag ? 'is-invalid' : ''}`} disabled={viewMode ? true : false} >
                                                    <option value=""></option>
                                                    {taskPlanOptions.length>0 ? taskPlanOptions.map((option) => (
                                                        <option value={option.dicGuid}>{option.title}</option>
                                                    )) : <option value="L-XX -NoTagOption" disabled >{t("L-XX-NoTagOption")}</option>}
                                                </select>
                                                <div className="invalid-feedback">{errors.planTag?.message}</div>
                                            </div>
                                            <div className="form-group col-sm-2" style={{"paddingTop": "7%"}}>
                                                <FontAwesomeIcon icon={faInfoCircle} id="TooltipPlanTasks" className="info-icon" style={{"paddingLeft": "3%"}} />
                                                <ToolTip infoText={t("L-XX-HowToAdd-PlanTaskTags")} target="TooltipPlanTasks" />
                                            </div>

                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-sm-8">
                                                <label>{t("ALL.Dictionary.Topic.Options.0")}</label>
                                                <MultiTagsAutoComplete control={control} tagsOptions={taskTagsOptions} controlName="taskTags" setTaskTags={settaskTags} tags={taskTags} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-sm-8">
                                                <label>{t("ALL.Tasks.Priority.PriorityTitle")}</label>

                                                <select name="priority" ref={register} className={`form-control ${errors.priority ? 'is-invalid' : ''}`} disabled={viewMode ? true : false} >
                                                    <option value={1}>{t("ALL.Tasks.Priority.Options.1")}</option>
                                                    <option value={2}>{t("ALL.Tasks.Priority.Options.2")}</option>
                                                    <option value={3}>{t("ALL.Tasks.Priority.Options.3")}</option>
                                                    <option value={4}>{t("ALL.Tasks.Priority.Options.4")}</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.priority?.message}</div>
                                            </div>

                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-sm-8">
                                                <label>{t("CAM.Case.Phases.PhaseTitle")}</label>
                                                <Controller
                                                    render={() => (
                                                        <RadioGroup
                                                            aria-labelledby="casePhaseTag-controlled-radio-buttons-group"
                                                            value={phase}
                                                            onChange={(e) => setPhase(e.target.value)}>
                                                            {casePhaseOptions.map((option) => (
                                                                <FormControlLabel value={option.dicGuid} control={<Radio />} label={option.title} disabled={viewMode ? true : false} />
                                                            ))}
                                                        </RadioGroup>
                                                    )}
                                                    name="casePhaseTag"
                                                    control={control}
                                                />
                                                <div className="invalid-feedback">{errors.casePhaseTag?.message}</div>
                                            </div>
                                            <div className="form-group col-sm-2">
                                                <FontAwesomeIcon icon={faInfoCircle} id="TooltipCasePhase" className="info-icon" style={{"paddingLeft": "3%"}} />
                                                <ToolTip infoText={t("L-XX-HowToAdd-CasePhase")} target="TooltipCasePhase" />
                                            </div>
                                        </div>
                                        {/* <div className="form-row">
                                            <div className="form-group col-sm-8">
                                                <label>{t("L-YY.AssignedTo")}</label>

                                                <ContactAutoComplete key={resteAutocomplete} role={"Case Manager"} caseId={caseData.selectedCaseId!} control={control} caseContact={true} error={false} insurerId={authUserData.currUserData.contactId} defaultValue={getValues("assigned") !== null ? getValues("assigned") : ""} />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {viewMode ?
                                    <div className="form-group row justify-content-center">
                                        <div className="form-group col-sm-4">
                                            <button className="btn btn-grey" onClick={(event) => {
                                                event.preventDefault();
                                                reset();
                                                cleanSelectedNode({});
                                                close(false)
                                            }}>
                                                {t("ALL.Button.Cancel")}
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div className="form-group row justify-content-center">
                                        <div className="form-group col-sm-4">
                                            <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                {t("ALL.Button.Save")}
                                            </button>
                                        </div>
                                        <div className="form-group col-sm-4">
                                            <button className="btn btn-grey" onClick={(event) => {
                                                event.preventDefault();
                                                reset();
                                                cleanSelectedNode({});
                                                close(false)
                                            }}>
                                                {t("ALL.Button.Cancel")}
                                            </button>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </div >

    );
}


export { AddEditTask };
