import { useState, useEffect, useContext } from 'react';
import { CaseInfoCard } from '../../components/CaseInfoCard';
import Case from '../../models/Case';
import { alertService, caseManagerService } from '../../services/Index';

import { useLocation, useParams } from 'react-router-dom';
import DashboardLineChart from '../../components/charts/DashboardLineChart';
import CaseTrendLineChart from '../../components/charts/CaseTrendLineChart';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';

const Dashboard = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { id } = useParams();
    const [caseItem, setCase] = useState<Case>();
    const [chartRefresh, setChartRefresh] = useState<boolean>(true);
    const { authUserData, setAuthUserData } = useContext(LoggedUserDataContext);
    const { caseData, setCaseData } = useContext(CaseDataContext);
    useEffect(() => {
        caseManagerService.getSingleCaseWithDetails(parseInt(id!)).then(
            (rp) => {
                setCase(rp);
                setAuthUserData(
                    {
                        ...authUserData,
                        selectedCaseId: parseInt(id!),
                        referenceNumberOfInsurance: rp.referenceNumberOfInsurance!
                    });

                setCaseData(
                    {
                        ...caseData,
                        selectedCaseId: rp.caseData.id,
                        caseNumber: rp.caseNumber,
                        caseClienName: rp.clientData ? rp.clientData.displayName : '',
                        caseClient: rp.clientData,
                        caseDoctor: rp.doctorData,
                        caseEmployeer: rp.employeerData,
                        caseCaseManager: rp.caseManagerData,
                        caseInsurerReference: rp.caseData.referenceNumberOfInsurance,
                        insurerProfileImage: rp.insurerProfileImage,
                        insurerId:rp.caseData.insurerId
                    });
            }
        ).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        //   }
    }, [id]);
    return (
        <div className="container">
            {!caseItem && <div className="loader">{t("ALL.Common.Loading")}</div>}
            {caseItem && <CaseInfoCard caseItem={caseItem} />}

            <div className="row">

                <DashboardLineChart chartRefresh={chartRefresh} />
            </div>
            <div className="row">
                <CaseTrendLineChart caseId={parseInt(id!)} />
            </div>
        </div>
    );

}

export { Dashboard };