export const msalConfig= {
    auth: {
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID!}`,
        clientId: process.env.REACT_APP_CLIENT_ID!,
        redirectUri: process.env.REACT_APP_REDIRECT_URL!,

    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }

};
export const apiConfig= {
    resourceUri: `${process.env.REACT_APP_RESOURCE_URI!}/api/v1/`,
    resourceScope: `api://${process.env.REACT_APP_API_CLIENT_ID!}/access_as_user`,
    adminGroup:process.env.REACT_APP_AD_ADMIN_GROUP_ID!
}
export const graphConfig = {
    scopes: [
      'user.read',
      'mailboxsettings.read',
      'calendars.readwrite'
    ]
  };
export const graphTokenRequest = {
    scopes: ["User.Read",'Directory.ReadWrite.All']
};

export const apiTokenRequest = {
    scopes: [apiConfig.resourceScope]
}

export const silentTokenRequest = {
    scopes: [apiConfig.resourceScope,'https://graph.microsoft.com/User.Read']
}