import { useContext, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../../themes/global';
import { adminTheme, insurerTheme, caseManagerTheme, doctorTheme, employeerTheme, clientTheme, defaultTheme } from '../../themes/theme';
import LeftNav from '../navigation/LeftNav';
import TopNav from '../navigation/TopNav';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { NotificationProvider } from '../../ctx/NotificationProvider';

const Layout = ({ children }) => {
    const { authUserData } = useContext(LoggedUserDataContext);
    const [isLoading, setDataLoading] = useState<boolean>(true);
    const [themeMode, setThemeMode] = useState<any>(adminTheme);

    useEffect(() => {

        if (authUserData.currUserData.roleType === 'Admin') {
            setThemeMode(adminTheme);
        }
        else if (authUserData.currUserData.roleType === 'Insurer') {
            setThemeMode(insurerTheme);
        }
        else if (authUserData.currUserData.roleType === 'CaseManager') {
            setThemeMode(caseManagerTheme);
        }
        else if (authUserData.currUserData.roleType === 'Doctor') {
            setThemeMode(doctorTheme);
        }
        else if (authUserData.currUserData.roleType === 'Client') {
            setThemeMode(clientTheme);
        }
        else if (authUserData.currUserData.roleType === 'Employeer') {
            setThemeMode(employeerTheme);
        }
        else {
            setThemeMode(defaultTheme);
        }
        setDataLoading(false);
    }, []);
    const [toggled, setToggled] = useState(false);
    const handleToggleSidebar = (value) => {
        setToggled(value);
    };
    return (
        <div className="wrapper">
            {isLoading &&
                <div className="spinner-border spinner-border-lg align-center"></div>
            }
            {!isLoading &&
                <ThemeProvider theme={themeMode}>
                    <NotificationProvider>
                        <GlobalStyles />
                        <LeftNav toggled={toggled}  handleToggleSidebar={handleToggleSidebar}/>
                        <div className="main-panel ps-container ps-theme-default">
                            <TopNav toggled={toggled} handleToggleSidebar={handleToggleSidebar}/>
                            <div className="content">
                                <div className="container-fluid">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </NotificationProvider>
                </ThemeProvider>
            }
        </div>
    );

}
export default Layout;
