import { useParams } from "react-router-dom";
import "./Progress.css";
interface IProgressProps {
  progress: any,
}
export const Progress=({progress}:IProgressProps) =>{
    return (
      <div className="ProgressBar">
        <div
          className="Progress"
          style={{ width: progress + "%" }}
        />
      </div>
    );
  }

export default Progress;
