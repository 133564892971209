import { useContext, useEffect, useState } from 'react';
import { apiConfig, msalConfig } from '../../msal/Config';
import { adminService, alertService, contactService } from '../../services/Index';
import { getGroupMembers } from '../../services/graph/GraphService'
import Membership from '../../models/Membership';
import { ManageAddingContact } from '../../components/contact/ManageAddingContact';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';


function ManageAdmins() {
    const [members, setMembers] = useState<Array<any>>([]);
    const [loadingMembers, setLoadingMembers] = useState<boolean>(true);
    const { authUserData } = useContext(LoggedUserDataContext);
    const { t } = useContext(MultiLanguageSupportContext);

    useEffect(() => {
        var interval = setInterval(function(){   
            getGroupMembers(apiConfig.adminGroup).then(rp => {
                setMembers(rp.value.map((item: { id: any; mail: any; }) => ({
                    contact: { id: item.id, userActveDirectoryId: item.id, email: item.mail },
                    welcomeSent: false
                })));
                setLoadingMembers(false);
                clearInterval(interval);
            }).catch(
                (rp) => {
                    if(rp.code!=="Request_ResourceNotFound"){
                        clearInterval(interval);
                        alertService.error(t(rp.message), { keepAfterRouteChange: true });
                    } 
                }
            );
        }, 2000); 
       
    }, []);

    function onSubmit(data: any, action: string) {
        switch (action) {
            case 'add':
                return addAdminOwner(data);
            case 'delete':
                return deleteAdminOwner(data.adId);
        }
    }

    function addAdminOwner(data: Membership) {
        data.insurerId = -1;
        data.roleType = "Administrator";
        data.groupId = apiConfig.adminGroup;
        data.redirectUrl = msalConfig.auth.redirectUri;
        data.createdBy = authUserData.currUserData.profileId;
        data.modifiedBy = authUserData.currUserData.profileId;
        return adminService.addUserToAdminGroup(data)
            .then(rp => {
                alertService.success(t("INS.Common.AdminAdded"), { keepAfterRouteChange: true });
                setMembers(members => [{ contact: { id: rp.id, userActveDirectoryId: rp.userActveDirectoryId, email: rp.email }, welcomeSent: false }, ...members]);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    function deleteAdminOwner(id: string) {
        setMembers(members.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));
        var data =
        {
            UserActveDirectoryId: id,
            groupId: apiConfig.adminGroup
        }
        contactService.removeInsurerContactFromGroupAsync(data).then(() => {
            alertService.success(t("INS.Common.AdminRemoved"), { keepAfterRouteChange: true });
            setMembers(m => m.filter(x => x.contact.userActveDirectoryId !== id));
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    }
    return (
        <div className="container">
            <p className="component-header">{t("INS.Common.Admins")}</p>
            <hr></hr>
            <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
            <span className="info-text" >{t("ALL.Messages.InsurerOnlySingleAdminAllowed")}</span>
            <hr></hr>
            <div className="white-container">
                    <div>

                        <ManageAddingContact groupName="Administrator" role="Administrator" action={onSubmit} insurerId={-1}
                            groupId={apiConfig.adminGroup}
                            requiered={false}
                            caseContact={false}
                            members={members}
                            loadingMembers={loadingMembers} />
                    </div>
                
            </div>
        </div>
    );
}

export { ManageAdmins };