import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormTemplate from '../../../models/FormTemplate'
import "@fortawesome/fontawesome-svg-core/styles.css";
import { ReactFormBuilder } from 'react-form-builder2';
import { ReactFormGenerator } from 'react-form-builder2';

import 'react-form-builder2/dist/app.css';
import { alertService, formTemplateService } from '../../../services/Index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiConfig } from '../../../msal/Config';
import { faFileMedical } from '@fortawesome/pro-light-svg-icons';
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../../ctx/LoggedUserDataProvider';
interface IAddEditFormTemplateProps {
    reSetTemplates?: any;
}
function AddEditFormTemplate({ reSetTemplates }: IAddEditFormTemplateProps) {
    const [template, setTemplate] = useState<FormTemplate>();
    const [templateData, setTemplateData] = useState([]);
    const [templateDescription, setTemplateDescription] = useState('');
    const [publishTemplate, setPublishTemplate] = useState(false);
    const [dataSubmitting, setDataSubmitting] = useState(false);

    const { authUserData } = useContext(LoggedUserDataContext);
    let { id } = useParams();
    let navigate = useNavigate();
    const isAddMode: boolean = !id;
    const { t } = useContext(MultiLanguageSupportContext);
    const validationSchema = isAddMode ? Yup.object().shape({
        templateTitle: Yup.string()
            .required()

    }) : Yup.object().shape({
    });
    const { register, handleSubmit, reset, setValue, errors, formState, getValues } = useForm({
        resolver: yupResolver(validationSchema)
    });
    function onSubmit(data: FormTemplate) {
        return isAddMode
            ? createTemplate(data)
            : updateTemplate(data, false);
    }

    function createTemplate(data: FormTemplate) {
        data.createdBy = authUserData.currUserData.profileId;
        data.insurerId = authUserData.currUserData.relatedData[0].id;
        data.templatePublished = false;
        return formTemplateService.postNewFormTemplate(data)
            .then((rp) => {
                alertService.success(t("ALL.FormTemplates.Created"), { keepAfterRouteChange: true });
                reSetTemplates(rp.Data);
                reset();
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    function updateTemplate(formData, contentUpdate: boolean) {
        let data = {
            id: parseInt(id!),
            insurerId: authUserData.currUserData.relatedData[0].id,
            modifiedBy: authUserData.currUserData.profileId,
            templateContent: JSON.stringify(formData.task_data),
            templateTitle: getValues("templateTitle"),
            templateDescription: getValues("templateDescription"),
            //createdBy: getValues("createdBy")
        }
        if (!contentUpdate) {
            return formTemplateService.postUpdateFormTemplate(data)
                .then(() => {
                    alertService.success(t("ALL.FormTemplates.Updated"), { keepAfterRouteChange: true });
                    // navigate('./manage-form-templates');
                })
                .catch(
                    (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
                );
        }
        else {
            setDataSubmitting(true);
            return formTemplateService.postUpdateFormTemplateContent(data)
                .then(() => {
                    //alertService.success(t("ALL.FormTemplates.Updated"), { keepAfterRouteChange: true });
                    // navigate('./manage-form-templates');
                    setDataSubmitting(false);
                })
                .catch(
                    (rp) => {
                        setDataSubmitting(false);
                        alertService.error(t(rp.message), { keepAfterRouteChange: true })}
                ).finally
                {};
        }

    }

    function handlePublish() {
        setPublishTemplate(!publishTemplate)
        let data = {
            id: parseInt(id!),
            modifiedBy: authUserData.currUserData.profileId,
            templatePublished: !publishTemplate
        }
        console.log("handlePublish", data);

        return formTemplateService.publishFormTemplate(data)
            .then((rp) => {
                alertService.success(publishTemplate ? t("ALL.FormTemplates.PublishedRemoved") : t("ALL.FormTemplates.Published"), { keepAfterRouteChange: true });
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    useEffect(() => {
        if (!isAddMode) {
            formTemplateService.getSingleFormTemplate(parseInt(id!)).then(i => {
                setValue("id", i.id);
                setValue("insurerId", i.insurerId);
                setValue("templateTitle", i.templateTitle);
                setValue("templateDescription", i.templateDescription);
                setValue("templateContent", JSON.parse(i.templateContent));
                setValue("templatePublished", i.templatePublished);
                setValue("templateType", i.templateType)
                setValue("createdBy", i.createdBy)

                setTemplate({
                    id: i.id,
                    insurerId: i.insurerId,
                    templateTitle: i.templateTitle,
                    templateDescription: i.templateDescription,
                    templateContent: i.templateContent,
                    templateType: i.templateType,
                    templatePublished: i.templatePublished,
                    createdBy: i.createdBy
                });
                setPublishTemplate(i.templatePublished);
                if (i.templateContent) {
                    setTemplateData(JSON.parse(i.templateContent))
                }
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
        }
    }, []);

    function onLoadFn() {
        return new Promise((resolve, reject) => {
            console.log("resolving");
            formTemplateService.getSingleFormTemplate(parseInt(id!)).then(i => {
                resolve(JSON.parse(i.templateContent));
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
        });
    }

    return (
        <div className="container">
            {!isAddMode && <> <p className="component-header">{t("ALL.FormTemplates.HeadEdit")}</p>
                <hr></hr></>}
            <div className=" white-container">
                <div>
                    <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        <div className="form-row">
                            <div className="form-group col-sm-5">
                                <label>{t("ALL.FormTemplates.Title")}</label>
                                <input name="templateTitle" type="text" ref={register} className={`form-control ${errors.templateTitle ? 'is-invalid' : ''}`} />
                                <input name="createdBy" type="text" ref={register} hidden />
                                <input name="templatePublished" type="text" ref={register} hidden />
                                <div className="invalid-feedback">{errors.templateTitle?.message}</div>
                            </div>
                            <div className="form-group col-sm-5">
                                <label>{t("ALL.FormTemplates.TemplateType")}</label>
                                <select name=" templateType" ref={register} className={`form-control ${errors.templateType ? 'is-invalid' : ''}`}>
                                    <option value={0}>{t("ALL.FormTemplates.Options.0")}</option>
                                </select>
                                <div className="invalid-feedback">{errors.templateType?.message}</div>
                            </div>
                            {!isAddMode &&
                                <>
                                    <div className="form-group col-sm-8">
                                        <label>{t("ALL.FormTemplates.Description")}</label>
                                        <textarea name="templateDescription" rows={5} ref={register} className={`form-control ${errors.templateDescription ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.templateDescription?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <br />
                                        <div className="form-check form-check-inline">
                                            <input name="isPublished" type="radio" value="True" ref={register} checked={publishTemplate ? true : false} onClick={handlePublish} />
                                            <label className="form-check-label">{t("ALL.FormTemplates.Publish")}</label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input name="isPublished" type="radio" value="False" ref={register} checked={!publishTemplate ? true : false} onClick={handlePublish} />
                                            <label className="form-check-label">{t("ALL.FormTemplates.UnPublish")}</label>
                                        </div>
                                    </div>
                                </>
                            }
                            {!isAddMode &&
                                <>
                                    <div className="form-row col-sm-8">
                                        <div className="form-group col-sm-4 justify-content-md-center">
                                            <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                {t("ALL.Button.Save")}
                                            </button>
                                        </div>
                                        {/* {getValues("templatePublished") === "false" && */}
                                        <div className="form-group col-sm-4 justify-content-md-center">
                                        <button type="button" className="btn btn- grey" onClick={() => navigate(`/${authUserData.currUserData.rolePath}/manage-form-templates`)}>
                                                {t("ALL.Button.Cancel")}
                                            </button>
                                        </div>
                                        {/* } */}
                                    </div>
                                    <hr></hr>
                                </>
                            }
                            {isAddMode &&
                                <div className="form-group col-sm-5">
                                    <button type="submit" disabled={formState.isSubmitting} className="btn btn-action btn-simple">
                                        {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        <FontAwesomeIcon icon={faFileMedical} /> {t("ALL.FormTemplates.Add")}
                                    </button>
                                </div>
                            }
                        </div>
                        {!isAddMode &&
                            <div>
                                <hr></hr>
                                <div className="form-row">
                                    <div className="form-group col-sm-12">
                                        {dataSubmitting &&
                                        <div className="overlay">
                                        <div className="overlay__inner">
                                            <div className="overlay__content"><span className="spinner"></span></div>
                                        </div>
                                    </div>}
                                            <ReactFormBuilder
                                                editMode={true}
                                                onLoad={() => onLoadFn()}
                                                onPost={data => updateTemplate(data, true)}
                                            />

                                        
                                    </div>
                                </div>
                            </div>
                        }
                    </form>

                </div>
            </div>
        </div>
    );
}

export { AddEditFormTemplate };