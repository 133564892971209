import BaseService from '../../BaseService';
import Response from "../../../models/Response";

import {ITaskViewModel, Task} from '../../../models/Task';
import TaskTemplate from '../../../models/TaskTemplate';
import TaskGeneratorRule, { ITaskGeneratorViewModel } from '../../../models/TaskGeneratorRule';
import { IDicScope } from '../../../models/Dictionary';

export const tasksService = {
    getAllTasks,
    postNewTask,
    postUpdateTask,
    deleteTask,
    getSingleTask,
    getCaseTasks,
    getAllUserRelatedTaskTemplates,
    postNewTaskTemplate,
    postUpdateTaskTemplate,
    deleteTaskTemplate,
    getTasksForTemplate,
    postUpdateTaskStatus,
    postUpdateTaskCasePhaseTag,
    postNewTaskGeneratorRule,
    postUpdateTaskGeneratorRule,
    getSingleTaskGeneratorRule,
    deleteTaskGeneratorRule,
    getAllTaskGeneratorRules,
    getScopedRuleGeneratorTemplates,
    deleteTaskGeneratorRuleTemplate,
    createTaskGeneratorRuleTemplate,
    removeTemplateRuleAssignementById,
    updateTaskGeneratorRuleTemplate,
    getTaskGeneratorRulesForRuleTemplate,
    getAllInsurerRelatedTaskTemplates,
    generateTasksPreview,
    generateTasks,
};
async function postNewTask(data: any) {
    let res = BaseService.create<any>("Task/CreateTask", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postNewTaskTemplate(data: TaskTemplate) {
    let res = BaseService.create<TaskTemplate>("Task/CreateTaskTemplate", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateTaskTemplate(data: TaskTemplate) {
    let res = BaseService.update<TaskTemplate>("Task/UpdateTaskTemplate", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateTask(data: any) {
    let res = BaseService.update<any>("Task/UpdateTask", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateTaskStatus(data: any) {
    let res = BaseService.update<any>("Task/UpdateTaskStatus", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateTaskCasePhaseTag(data: any) {
    let res = BaseService.update<any>("Task/UpdateTaskCasePhaseTag", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleTask(taskId: number): Promise<Task> {
    let res = BaseService.get<Task>("Task/GetSingleTask?taskId=", taskId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteTask(taskId: number): Promise<any> {
    let res = BaseService.delete("Task/RemoveTask?taskId=", taskId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function generateTasksPreview(taskGeneratorData: any): Promise<ITaskViewModel[]> {
    let res = BaseService.post("Task/GenerateTasksPreview", taskGeneratorData).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function generateTasks(taskList: any[]): Promise<any> {
    let res = BaseService.post("Task/GenerateTasks", taskList).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteTaskTemplate(taskTempalteId: number): Promise<any> {
    let res = BaseService.delete("Task/RemoveTaskTemplate?taskTempalteId=", taskTempalteId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllTasks(): Promise<any[]> {
    let res=BaseService.getAll<any>("Task/GetAllTasks").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllUserRelatedTaskTemplates(userId:number,insurerId:number): Promise<any[]> {
    let res=BaseService.get<any[]>("Task/GetAllUserRelatedTasksTemplates",`?insurerId=${insurerId}&userId=${userId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllInsurerRelatedTaskTemplates(insurerId:number): Promise<any[]> {
    let res=BaseService.get<any[]>("Task/GetAllUserRelatedTasksTemplates",`?insurerId=${insurerId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseTasks(caseId:number,lng:string ): Promise<Array<Task>> {
    let res=BaseService.get<Task>("Task/GetCaseTasks",`?caseId=${caseId}&lng=${lng}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getTasksForTemplate(templateId:number): Promise<Array<Task>> {
    let res=BaseService.get<Task>("Task/GetTasksFromTemplate?templateId=", templateId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

//**************task generator rule management */
async function postNewTaskGeneratorRule(data: any) {
    let res = BaseService.create<any>("Task/CreateTaskGeneratorRule", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateTaskGeneratorRule(data: any) {
    let res = BaseService.update<any>("Task/UpdateTaskGeneratorRule", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleTaskGeneratorRule(ruleId: number): Promise<Task> {
    let res = BaseService.get<Task>("Task/GetSingleTaskGeneratorRule?taskId=", ruleId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteTaskGeneratorRule(ruleId: number): Promise<any> {
    let res = BaseService.delete("Task/RemoveTaskGeneratorRule?ruleId=", ruleId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllTaskGeneratorRules(): Promise<TaskGeneratorRule[]> {
    let res=BaseService.getAll<any>("Task/GetAllTaskGeneratorRules").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
/****************Rule Templates */
async function getScopedRuleGeneratorTemplates(scopes:IDicScope[]): Promise<any> {
    let res = BaseService.post("Task/GetScopedRuleGeneratorTemplates",{scopes:scopes}).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteTaskGeneratorRuleTemplate(ruleTemplateId: number): Promise<any> {
    let res = BaseService.delete("Task/RemoveTaskGeneratorRuleTemplate?ruleTemplateId=", ruleTemplateId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function createTaskGeneratorRuleTemplate(data: any): Promise<any> {
    let res = BaseService.post("Task/CreateTaskGeneratorRuleTemplate", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function updateTaskGeneratorRuleTemplate(ruleTemplate:any): Promise<any> {
    let res = BaseService.post("Task/UpdateTaskGeneratorRuleTemplate",ruleTemplate).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function removeTemplateRuleAssignementById(ruleId:number): Promise<any> {
    let res = BaseService.get("Task/RemoveTemplateRuleAssignementById",`?ruleId=${ruleId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getTaskGeneratorRulesForRuleTemplate(ruleTemplateId:number): Promise<any> {
    let res = BaseService.get("Task/GetTaskGeneratorRulesForRuleTemplate",`?ruleTemplateId=${ruleTemplateId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
