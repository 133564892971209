import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'moment/min/locales';
import LicenseAssignement from '../../../models/LicenseAssignement';
import { alertService, licenseService } from '../../../services/Index';
import { LicenseManagementAssignment } from './LicenseManagementAssignment';
import { Alert } from 'reactstrap';
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import { TextField } from '@mui/material';
import { LoggedUserDataContext } from '../../../ctx/LoggedUserDataProvider';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';

interface IAddEditLicenseAssignementProps {
    selectedCase?: any;
    close?: any;
    reloadCase: any;
}
function AddEditLicenseAssignement({ reloadCase, close, selectedCase }: IAddEditLicenseAssignementProps) {
    const { authUserData } = useContext(LoggedUserDataContext);
    let { id } = useParams();
    const isAddMode: boolean = !id;
    const [licenses, setLicenses] = useState<any[]>();
    const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
    const [selectedLicense, setselectedLicense] = useState<any>();
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [initialLicenses, setInitialLicenses] = useState<any[]>();
    const [massage, setAlertMassage] = useState<string>();
    const [insureLicenseAssignement, setInsurerLicenseAssignement] = useState<any[]>();
    const [caseLicenseAssignement, setCaseLicenseAssignement] = useState<any>();
    const [activeLicenseAssignement, setActiveLicenseAssignement] = useState<any>();

    const { locale, t } = useContext(MultiLanguageSupportContext);
    const columWidth = isAddMode ? "col-sm-12" : "col-sm-6"
    const validationSchema = Yup.object().shape({
        licenseId: Yup.string().required(t("ALL.License.Validation.ID")),
        assignmentStartDate: Yup.date().required(t("ALL.License.Validation.DateStart")),
        assignmentEndDate: Yup.date().required(t("ALL.License.Validation.DateEnd")),
    });
    const { register, handleSubmit, reset, setValue, getValues, control, errors, formState } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            caseId: null,
            caseActive: false,
            licenseId: null,
            assignmentStartDate: null,
            assignmentEndDate: null,
        }
    });

    function onSubmit(data: LicenseAssignement) {
        return activeLicenseAssignement
            ? updateLicenseAssignement(data)
            : createLicenseAssignement(data)
    }

    function createLicenseAssignement(data: LicenseAssignement) {
        data.createdBy = authUserData.currUserData.profileId;
        data.insurerId = selectedCase.caseData.insurerId;
        data.caseId = selectedCase.caseData.id!;
        return licenseService.postNewLicenseAssignement(data)
            .then((rp) => {
                //setAlertMassage("ALL.License.AssignmentCreated");
                alertService.success(t("ALL.License.AssignmentCreated"), { keepAfterRouteChange: true });
                close();
                reloadCase();
                reset();
            })
            .catch(
                (rp) => { alertService.error(t(rp.message), { keepAfterRouteChange: true }); }
            );
    }

    function updateLicenseAssignement(data: LicenseAssignement) {
        if (data.assignmentStartDate !== undefined && data.assignmentStartDate !== null && data.assignmentEndDate !== undefined && data.assignmentEndDate !== null && new Date(data.assignmentStartDate) > new Date(data.assignmentEndDate)) {
            setAlertMassage("ALL.License.Validation.StartBeforeEnd");
            return false;
        }
        data.id = activeLicenseAssignement.Id;
        data.modifiedBy = authUserData.currUserData.profileId
        return licenseService.postUpdateLicenseAssignement(data)
            .then((rp) => {
                setAlertMassage("ALL.License.AssignmentUpdated");
                close(false);
                reloadCase();
                reset();
            })
            .catch(
                (rp) => { alertService.error(t(rp.message), { keepAfterRouteChange: true }); }
            );
    }
    function checkAssignementStart(date: any) {
        setSaveDisabled(false);

        //setLicenses(initialLicenses);
        var filterdLicenses = initialLicenses!.filter(l => NoTime(new Date(l.licenseValidFrom)) <= NoTime(new Date(date)) && NoTime(new Date(l.licenseValidTo)) >= NoTime(new Date(date)))

        if (activeLicenseAssignement && endDate === undefined) {
            filterdLicenses = filterdLicenses!.filter(l => NoTime(new Date(l.licenseValidTo)) >= NoTime(new Date(activeLicenseAssignement.assignmentEndDate)) && NoTime(new Date(l.licenseValidFrom)) <= NoTime(new Date(activeLicenseAssignement.assignmentEndDate)))
        }
        if (filterdLicenses.length === 0) {
            setSaveDisabled(true);

        }
        else if (filterdLicenses.length > 0) {
            var currentlyAssignedLicense = insureLicenseAssignement?.filter(l => l.caseId === selectedCase.caseData.id);
            if (activeLicenseAssignement) {
                currentlyAssignedLicense = currentlyAssignedLicense!.filter(l => l.id !== activeLicenseAssignement.Id);
            }
            var filterdInsurerLicensesAssignement = currentlyAssignedLicense!.filter(l => NoTime(new Date(l.assignmentStartDate)) <= NoTime(new Date(date)) && NoTime(new Date(date)) <= NoTime(new Date(l.assignmentEndDate)))
            if (endDate) {
                if (new Date(date) >= new Date(endDate)) {
                    setSaveDisabled(true);
                    filterdLicenses = [];
                    return false;
                }
                filterdInsurerLicensesAssignement = currentlyAssignedLicense!.filter(l => NoTime(new Date(endDate)) >= NoTime(new Date(l.assignmentStartDate)) && NoTime(new Date(date)) <= NoTime(new Date(l.assignmentEndDate)))

            }
            if (filterdInsurerLicensesAssignement.length > 0) {
                setSaveDisabled(true);
                filterdLicenses = []
            }
        }
        setLicenses(filterdLicenses);

    }
    function checkAssignementEnd(date: any) {
        setSaveDisabled(false);

        //setLicenses(initialLicenses);
        var filterdLicenses = initialLicenses!.filter(l => NoTime(new Date(l.licenseValidTo)) >= NoTime(new Date(date)) && NoTime(new Date(l.licenseValidFrom)) <= NoTime(new Date(date)))

        if (activeLicenseAssignement && startDate === undefined) {
            filterdLicenses = filterdLicenses!.filter(l => NoTime(new Date(l.licenseValidFrom)) <= NoTime(new Date(activeLicenseAssignement.assignmentStartDate)) && NoTime(new Date(l.licenseValidTo)) >= NoTime(new Date(activeLicenseAssignement.assignmentStartDate)))
        }
        if (filterdLicenses.length === 0) {
            setSaveDisabled(true);
        }
        else if (filterdLicenses.length > 0) {
            var currentlyAssignedLicense = insureLicenseAssignement?.filter(l => l.caseId === selectedCase.caseData.id);
            if (activeLicenseAssignement) {
                currentlyAssignedLicense = currentlyAssignedLicense!.filter(l => l.id !== activeLicenseAssignement.Id);
            }
            var filterdInsurerLicensesAssignement = currentlyAssignedLicense!.filter(l => NoTime(new Date(l.assignmentStartDate)) <= NoTime(new Date(date)) && NoTime(new Date(date)) <= NoTime(new Date(l.assignmentEndDate)))
            if (startDate) {
                if (new Date(startDate) >= new Date(date)) {
                    setSaveDisabled(true);
                    filterdLicenses = [];
                    return false;
                }
                filterdInsurerLicensesAssignement = currentlyAssignedLicense!.filter(l => NoTime(new Date(l.assignmentStartDate)) <= NoTime(new Date(date)) && NoTime(new Date(startDate)) <= NoTime(new Date(l.assignmentEndDate)))
                //filterdInsurerLicensesAssignement = currentlyAssignedLicense!.filter(l => helperFunctions.checkDateOverlaping(startDate,date,l.assignmentStartDate,l.assignmentEndDate))            
            }
            if (filterdInsurerLicensesAssignement.length > 0) {
                setSaveDisabled(true);
                filterdLicenses = []
            }
        }
        setLicenses(filterdLicenses);

    }
    function setSelectedLicenseAssignement(id: number) {
        licenseService.getSingleLicenseAssignement(id).then(i => {
            setValue("licenseId", i.licenseId);
            setValue("caseId", i.caseId);
            setValue("assignmentStartDate", i.assignmentStartDate);
            setValue("assignmentEndDate", i.assignmentEndDate);
            setActiveLicenseAssignement({
                Id: i.id,
                licenseId: i.licenseId,
                caseId: i.caseId,
                assignmentStartDate: i.assignmentStartDate,
                assignmentEndDate: i.assignmentEndDate,

            });
            setSaveDisabled(true);
            setLicenses(licenses?.filter(l => l.id === i.licenseId))
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    }
    function checkLicenseAssigned(event) {
        var selectedLicense = parseInt(event.target.value);
        var filterdLicensesAssignement = insureLicenseAssignement!.filter(l => NoTime(new Date(l.assignmentStartDate)) <= NoTime(new Date(endDate)) && NoTime(new Date(startDate)) <= NoTime(new Date(l.assignmentEndDate)))

        filterdLicensesAssignement = filterdLicensesAssignement.filter(l => l.licenseId === selectedLicense);
        console.log(filterdLicensesAssignement);
        console.log(filterdLicensesAssignement.length);
        setSaveDisabled(filterdLicensesAssignement.length !== 0);
        if (filterdLicensesAssignement.length !== 0) {
            setAlertMassage("ALL.License.Validation.AlreadyAssigned");
        }
        else {
            setAlertMassage(undefined);
        }
    };

    useEffect(() => {
        /**********Get active license assignement for insurer **************/
        licenseService.getInsurerLicenseAssignementAsync(selectedCase.caseData.insurerId).then(
            (x) => {
                setInsurerLicenseAssignement(x.result);

            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
        /**********Get all insurer licenses for insurer **************/
        licenseService.getInsurerLicensesAsync(selectedCase.caseData.insurerId).then(
            (rp) => {
                setInitialLicenses(rp);
                /**********Get license assignement for case **************/
                licenseService.getCaseLicenseAssignement(selectedCase.caseData.id).then(x => {
                    setCaseLicenseAssignement(x);
                    setLicenses(rp);

                }).catch(
                    (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
                );

            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }, []);
    function NoTime(dateTime) {
        var date = new Date(dateTime.getTime());
        date.setHours(0, 0, 0, 0);
        return date;
    }
    return (
        <div className="container">
            {massage && <Alert color="danger">
                {t(`${massage}`)}
            </Alert>}
            <p className="component-header">{t("ALL.License.Assignment")}</p>
            <hr></hr>
            <div className=" white-container">
                <form onSubmit={handleSubmit(onSubmit)} className={columWidth}>
                    {activeLicenseAssignement &&
                        <div className="form-group col-sm-12">
                            <div className="form-group col-sm-12">
                                <label>{`${selectedCase.insurerPrefix}-${activeLicenseAssignement.licenseId}`}</label>
                            </div>
                        </div>
                    }
                    <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label>{t("ALL.License.AssignmentDateFrom")}*</label>
                            <Controller
                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                    <DatePicker
                                        //readOnly={true}
                                        //inputVariant="outlined"
                                        //id="date-picker-dialog"
                                        //inputFormat="dd.MM.yyyy"
                                        value={getValues("assignmentStartDate")}
                                        onChange={value => {
                                            setValue("assignmentStartDate", value);
                                            setStartDate(value);
                                            checkAssignementStart(value);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>)}
                                name="assignmentStartDate"
                                control={control}

                            />
                        </div>
                        <div className="form-group col-sm-6">
                            <label>{t("ALL.License.AssignmentDateTo")}*</label>
                            <Controller
                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                    <DatePicker
                                        //readOnly={true}
                                        //inputVariant="outlined"
                                        //id="date-picker-dialog"
                                        //inputFormat="dd.MM.yyyy"
                                        value={getValues("assignmentEndDate")}
                                        onChange={value => {
                                            setValue("assignmentEndDate", value);
                                            setEndDate(value);
                                            checkAssignementEnd(value);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>)}
                                name="assignmentEndDate"
                                control={control}

                            />
                        </div>
                    </div>
                    {((startDate && endDate) || activeLicenseAssignement) && <div className="form-row">
                        <div className="form-group col-sm-12">
                            {licenses && licenses.length === 0 ? <label>{t("ALL.License.NoFree")}</label> : <>
                                <label>{t("ALL.License.Available")}</label>
                                <select name="licenseId" ref={register} className={`form-control ${errors.licenseId ? 'is-invalid' : ''}`} onChange={checkLicenseAssigned} value={selectedLicense} >
                                    {licenses && licenses!.map(item => (
                                        <option
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {`${item.insurerPrefix}-${item.id}`}
                                        </option>
                                    ))}

                                </select>
                                <div className="invalid-feedback">{errors.licenseId?.message}</div></>
                            }
                        </div>
                    </div>}

                    <div className="form-row">
                        <div className="form-group col-sm-4 justify-content-md-center">
                            <button type="submit" disabled={formState.isSubmitting || saveDisabled} className="btn btn-action">
                                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                {t("ALL.Button.Save")}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div style={{ marginTop: "50px" }}>
                <LicenseManagementAssignment close={close} caseId={isAddMode ? selectedCase.caseData.id : caseLicenseAssignement?.caseId} setActiveLicenseAssignement={setSelectedLicenseAssignement} reloadCases={reloadCase} />
            </div>
        </div >
    );
}

export { AddEditLicenseAssignement };