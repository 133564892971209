
/* eslint eqeqeq: 0 */
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import { useTable } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlusCircle, faPalette, faFont } from '@fortawesome/pro-light-svg-icons'
import { AddEditDictionaryData } from './AddEditDictionaryData';
import { Modal, ModalBody } from 'reactstrap';
import { useContext, useEffect, useState } from 'react';
import { Dictionary } from '../../../models/Dictionary';
import React, { useMemo } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { TwitterPicker } from "react-color";
import { alertService } from '../../../services/Index';
import { adminService } from '../../../services/casedesk/admin/AdminService';
import DeleteConfirmationModal from './DeleteConfirmationModal';
export interface IListDictionaryProps {
    deleteItem: (id: number, guid: string) => void,
    add: any,
    // edit: (dictionaryLabel: Dictionary) => void,
    edit: any,
    dictionaryData?: Array<Dictionary>,
    loading?: boolean,
    updateColorData: any,
    selectedTopic: number
}

const DictionaryList = ({ deleteItem, selectedTopic, dictionaryData, loading, add, edit, updateColorData }: IListDictionaryProps) => {
    const availableLng = ['EN', 'DE'];
    const { t } = useContext(MultiLanguageSupportContext);
    const [open, setOpen] = useState(false);
    const [selectedTag, setSelectedTag] = useState<any>({})
    const [selectedDicData, setSelectedTagDIcData] = useState<any>({})
    const [translationList, setTranslationList] = useState<Dictionary[]>([])
    const [translation, setTranslation] = useState<boolean>(false)
    const [modalEditMode, setModalEditMode] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [hideModal, setHideModal] = useState<boolean>(false)


    const twitterStyle = {
        default: {
            input: {
                display: "none"
            },
            hash: {
                display: "none"
            }
        }
    };

    const editDictionary = (dictionaryLabel: Dictionary) => {
        setSelectedTag(dictionaryLabel);
        setOpen(true);
        setTranslation(false);
        setModalEditMode(true)
    }

    const editTranslations = (dictionaryLabel: Dictionary) => {
        setOpen(true);
        setSelectedTag(dictionaryLabel);
        setTranslation(true);
        adminService.getDictionaryItemTranslations(dictionaryLabel.dicGuid!)
            .then((rp) => {
                var translationList = rp
                availableLng.forEach(lng => {
                    if (translationList.length > 0 && translationList.filter(e => e.dicItemLng === lng).length === 0) {
                        var newTranslation = Object.create({});
                        Object.assign(newTranslation, translationList[0]);
                        newTranslation.dicItemLng = lng;
                        newTranslation.title = "";
                        newTranslation.description = "";
                        translationList.push(newTranslation);
                    }
                });
                setTranslationList(translationList);

            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })

                }

            );
    }

    const deleteDictionaryEnty = (dictionaryLabel) => {
        deleteItem(dictionaryLabel.id, dictionaryLabel.dicGuid!);
    }
    const deleteTranslations = (dictionaryLabel: Dictionary) => {
        adminService.checkIfDictionaryInUse(dictionaryLabel.dicGuid!)
            .then((rp) => {
                if (rp > 0) {
                    setShowModal(true)
                    setSelectedTagDIcData(dictionaryLabel)
                } else {
                    deleteDictionaryEnty(dictionaryLabel)
                }
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                }

            );

    }
    const confirmModal = (userRes) => {
        if (userRes === 1) {
            deleteDictionaryEnty(selectedDicData)
        }
        setHideModal(true);
        setShowModal(false);
    }
    const hideModalFn = () => {
        setHideModal(true);
        setShowModal(false);
    }
    const addDictionary = () => {
        setTranslation(false);
        setOpen(true);
        setModalEditMode(false);
        setSelectedTag({});
    }
    const handleClose = () => {
        setOpen(false);
    };

    const updateColor = (e, dictionaryLabel) => {
        updateColorData(e.hex, dictionaryLabel);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: t("ALL.Dictionary.Title"),
                accessor: 'title',
                Cell: row => (
                    <>
                        <div>
                            <div style={{
                                "height": "15px", "width": "18px",
                                "border": "1px solid #ffffff00",
                                "marginRight": "5px",
                                "backgroundColor": row.row.original.dicItemColorId,
                                "display": 'inline-block'
                            }}> </div>
                            <span>{row.row.original.title}</span>
                        </div>
                    </>
                )
            },
            {
                Header: t("ALL.Dictionary.Description"),
                accessor: 'description',
            },
            {
                Header: t("ALL.Button.ShowMore"),
                accessor: 'action',
                Cell: row => (<div>
                    <button
                        onClick={() => editDictionary(row.row.original)}
                        className="btn btn-md btn-icon-grey">
                        <FontAwesomeIcon icon={faEdit} />
                    </button>

                    {/* <button 
                    // onClick={() => removeTask(row.row.original)}
                     className="btn btn-md btn-icon-red">
                        <FontAwesomeIcon icon={faTrash} />{row.row.original.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    </button> */}

                    <button
                        onClick={() => editTranslations(row.row.original)}
                        className="btn btn-md btn-icon-grey">
                        <FontAwesomeIcon icon={faFont} />
                    </button>

                    <OverlayTrigger
                        trigger="click"
                        overlay={
                            <Popover id="popover-contained" placement="right">
                                <Popover.Header as="h3">{t("ALL.Tasks.ColorSelector")}</Popover.Header>
                                <Popover.Body>
                                    <TwitterPicker
                                        styles={twitterStyle}
                                        triangle="hide"
                                        // colors={palette}
                                        width="212px"
                                        onChange={(e) => updateColor(e, row.row.original)}
                                    />
                                </Popover.Body>
                            </Popover >
                        }
                        rootClose
                    >
                        <button
                            // onClick={() => editDictionary(row.row.original)} 
                            className="btn btn-md btn-icon-grey">
                            <FontAwesomeIcon icon={faPalette}
                            // color={row.row.original.dicItemColorId} 
                            />
                        </button>
                    </OverlayTrigger>

                    <button
                        onClick={() => deleteTranslations(row.row.original)}
                        className="btn btn-md btn-icon-red">
                        <FontAwesomeIcon icon={faTrash} />{row.row.original.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    </button>
                </div>
                ),
            },
        ],
        []
    );
    const data = useMemo(() => [...dictionaryData ? dictionaryData.filter(obj => {
        return obj.dicTopicId == selectedTopic
    }) : []], [selectedTopic, dictionaryData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    });

    return (
        <>
            <DeleteConfirmationModal showModal={showModal} hideModal={hideModal} hideModalFn={hideModalFn} confirmModal={confirmModal} type={"error"} message={"Tag already in use. Are you sure you want to editDictionary ?"} />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header card-header-tabs card-header-primary header-top">
                            <div className="card-category">
                                <div className="btn-group">
                                    <button type="submit" className="btn btn-md btn-icon-grey mr-1"
                                        onClick={addDictionary}
                                    >
                                        <FontAwesomeIcon icon={faPlusCircle} />{t("ALL.Dictionary.Button.Add")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">

                                <table className="table table-striped" {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {loading ?
                                            <tr>
                                                <td colSpan={5} className="text-center">
                                                    <div className="spinner-border spinner-border-lg align-center"></div>
                                                </td>
                                            </tr> :
                                            (rows.length > 0 && rows.map((row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => {
                                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                        })}
                                                    </tr>
                                                );
                                            })) ||
                                            <tr>
                                                <td colSpan={5} className="text-center">
                                                    <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                </td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={open} style={{ maxWidth: "1000px" }}>
                    <ModalBody>
                        <AddEditDictionaryData
                            translationList={translationList}
                            dictionaryLabel={selectedTag}
                            close={handleClose}
                            add={add}
                            edit={edit}
                            editMode={modalEditMode}
                            cleanSelectedNode={setSelectedTag}
                            translation={translation}
                            view={false}
                            selectedTopic={selectedTopic} />
                    </ModalBody>
                </Modal>
            </div>
        </>
    );

}

export { DictionaryList };