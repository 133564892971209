import React, { useContext } from 'react';
import { Collapse, Container, Navbar, NavbarToggler } from 'reactstrap';
import { Notification } from './Notification';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import '../../assets/css/nav.css';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { UserLogin } from './UserLogin';

const TopNav=({ toggled, handleToggleSidebar }) => {
    const { caseData } = useContext(CaseDataContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    const handleClick = () => {
            handleToggleSidebar(!toggled);
    };

        return (
            <header>
                <Navbar className="navbar navbar-expand-lg navbar-light" light style={{ marginLeft: "18px" }}>
                    {/*this.state.visible && <FontAwesomeIcon icon={faBars} className="top-menu" onClick={this.props.handleCollapsedChange} style={{ marginRight: "10px" }} />*/}
                    <div className="container">
                        <div className="navbar-wrapper">
                        <NavbarToggler className="mr-2 order-md-1 order-0 btn-toggle" onClick={handleClick}/>
                            {authUserData.selectedCaseId !==undefined && <>
                                <div className="col-3-sm line-breadcrump align-text-bottom"> {caseData.caseInsurerReference}</div>
                                {caseData.caseNumber !== undefined && <div className="col-1-sm line-breadcrump align-text-bottom"><FontAwesomeIcon icon={faChevronRight} /> </div>}
                                <div className="col-3-sm line-breadcrump align-text-bottom"> {caseData.caseNumber} </div>
                                {caseData.caseClienName !== undefined && <div className="col-1-sm line-breadcrump align-text-bottom"><FontAwesomeIcon icon={faChevronRight} /> </div>}
                                <div className="col-4-sm line-breadcrump align-text-bottom"> {caseData.caseClienName}</div>
                            </>
                            }
                        </div>
                    </div>
                    <Container>                        
                        <Collapse className="d-sm-inline-flex flex-lg-row-reverse" navbar>
                            <ul className="navbar-nav flex-grow">
                                <Notification/>
                                <UserLogin />
                            </ul>
                        </Collapse>
                        {caseData.insurerProfileImage!==undefined && caseData.insurerProfileImage!==null &&<img src={caseData.insurerProfileImage} alt="InsurerPicture" className="insurer-profile-image" />}                    
                    </Container>
                </Navbar>
            </header>
        );
    }
export default TopNav