import { faPen } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import moment from "moment";
import '../assets/css/dashboard.css';
import { MultiLanguageSupportContext } from '../ctx/MultiLanguageSupportProvider';
import { useContext } from 'react';
import { LoggedUserDataContext } from '../ctx/LoggedUserDataProvider';
interface IProps {
    caseItem: any
}
export const CaseInfoCard = ({ caseItem }: IProps) => {
    const { authUserData } = useContext(LoggedUserDataContext);
    moment.locale(authUserData.currUserData.profileLanguage);
    const { t } = useContext(MultiLanguageSupportContext);
    return (
        <div className="row">
            <div className="col-lg-12 col-md-12">
                <div className="card">
                    <div className="card-header card-header-tabs card-header-primary header-top">
                        <h4 className="card-title">{t("CAM.Case.InfoCard")}</h4>
                        <p className="card-category">{authUserData.currUserData.roleType === "CaseManager" &&
                            <Link className="btn btn-md btn-icon-grey mr-1" to={`../edit-case/${caseItem.caseData.id}/Yes`} ><FontAwesomeIcon icon={faPen} /> {t("CAM.Case.Edit")}</Link>}
                        </p>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="card-body">
                                    <h4 className="card-title">{t("ALL.Dictionary.Role.CaseManager")}</h4>
                                    <p className="card-category">{caseItem.caseManagerData ? caseItem.caseManagerData.displayName : "-"}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="card-body">
                                    <h4 className="card-title">{t("ALL.Dictionary.Role.Client")}</h4>
                                    <p className="card-category">{caseItem.clientData ? caseItem.clientData.displayName : "-"}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="card-body">
                                    <h4 className="card-title">{t("INS.Common.InsurerRefNumber")}</h4>
                                    <p className="card-category"> {caseItem.caseData.referenceNumberOfInsurance}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="card-body">
                                    <h4 className="card-title">{t("CAM.Case.CreateDate")}</h4>
                                    <p className="card-category">{moment(caseItem.caseData.created).format("L")}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="card-body">
                                    <h4 className="card-title">{t("CAM.Case.IntegrationStartDate")}</h4>
                                    <p className="card-category">{moment(caseItem.caseData.startOfIntegration).year().toString() === "1" ? "-" : moment(caseItem.caseData.startOfIntegration).format("L")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="card-body">
                                    <h4 className="card-title">{t("CAM.Case.IntegrationMainGoal")}</h4>
                                    <p className="card-category">
                                        {caseItem.caseData.mainGoal ? caseItem.caseData.mainGoal : "-"}
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}