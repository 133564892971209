import BaseService from '../../BaseService';
import Insurer from '../../../models/Insurer';
import Response from "../../../models/Response";
import User from '../../../models/AdminSetting';
import AdminSetting from '../../../models/AdminSetting';
import NotificationTemplates from '../../../models/NotificationTemplates';
import Template from '../../../models/Template';
import EventScheduler from '../../../models/EventScheduler';
import { Dictionary, IDicScope } from '../../../models/Dictionary';
import NotificationSettings from '../../../models/NotificationSettings';

export const adminService = {
    getInsurers,
    postNewInsurer,
    getSingleInsurer,
    postUpdateInsurer,
    addUserToInsurerGroup,
    getAllSettings,
    getSingleSetting,
    postUpdateSetting,
    addUserToAdminGroup,
    deleteCase,
    deleteInsurer,
    getAllTemplates,
    getSingleTemplate,
    deleteContact,
    postUpdateTemplate,
    postUploadProfilePicture,
    getAllEventSchedulers,
    getAllDictionaries,
    getScopedDictionaries,
    getInsurerDictionaries,
    getCaseDictionaries,
    getContactDictionaries,
    postUpdateDictionaryItem,
    postNewDictionaryItem,
    postNewDictionaryItemTranslation,
    deleteDictionaryItem,
    checkIfDictionaryInUse,
    postNewNotificationSettingItem,
    postUpdateNotificationSettingItem,
    getAllNotificationSettingDictionaries,
    getScopedNotificationSetting,
    getNotificationSetting,
    getDictionaryItemTranslations,
    deleteNotificationSetting,
    runWebJob,
    updateNotificationSettingsNextRunDate,
    updateNotificationSettingIsEnabled

};
async function postNewInsurer(data: Insurer) {
    let res = BaseService.create<Insurer>("Insurer/CreateInsurer", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateInsurer(data: Insurer) {

    let res = BaseService.update<Insurer>("Insurer/UpdateInsurer", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUploadProfilePicture(data:any) {      
    let res=BaseService.update<Insurer>("Insurer/UploadProfilePicture", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
    };
async function getInsurers(): Promise<Array<Insurer>> {
    let res = BaseService.getAll<Insurer>("Insurer/GetAllInsurers").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

async function getSingleInsurer(insurerId: number): Promise<Insurer> {
    let res = BaseService.get<Insurer>("Insurer/GetSingelInsurer?insurerId=", insurerId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteInsurer(insurerId: number): Promise<any> {
    let res = BaseService.delete("Administration/DeleteInsurer?insurerId=", insurerId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteCase(caseId: number): Promise<any> {
    let res = BaseService.delete("Administration/DeleteCase?caseId=", caseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteContact(contactId: number): Promise<any> {
    let res = BaseService.delete("Administration/RemoveUserById?userId=", contactId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllSettings(): Promise<Array<AdminSetting>> {
    let res = BaseService.getAll<AdminSetting>("Administration/GetAllSettings").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllEventSchedulers(): Promise<Array<EventScheduler>> {
    let res = BaseService.getAll<EventScheduler>("Administration/GetAllEventSchedulers").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllTemplates(): Promise<Array<NotificationTemplates>> {
    let res = BaseService.getAll<NotificationTemplates>("Administration/GetAllTemplates").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleTemplate(templateId: number): Promise<Template> {
    let res = BaseService.get<Template>("Administration/GetSingelTemplate?templateId=", templateId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function addUserToInsurerGroup(data: any) {

    let res = BaseService.update<User>("Insurer/AddUserToInsurerGroups", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function addUserToAdminGroup(data: any) {

    let res = BaseService.update<User>("Administration/AddUserToAdministratorGroups", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleSetting(SettingId: number): Promise<AdminSetting> {
    let res = BaseService.get<AdminSetting>("Administration/GetSingelSetting?settingId=", SettingId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateSetting(data: AdminSetting) {

    let res = BaseService.update<AdminSetting>("Administration/UpdateSetting", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateTemplate(data: Template) {

    let res = BaseService.update<Template>("Administration/UpdateTemplate", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllDictionaries(): Promise<Array<Dictionary>> {
    let res = BaseService.getAll<AdminSetting>("Dictionary/GetAllDictionaries").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getScopedDictionaries(topicId:number, dictionaryScopes:IDicScope[], lng:string|null): Promise<Array<Dictionary>> {
    let res = BaseService.post<any>("Dictionary/GetScopedDictionaries",{topicId:topicId,scopes:dictionaryScopes, language:lng}).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getInsurerDictionaries(insurerId: number, scopeId:number): Promise<Dictionary[]> {
    let res = BaseService.get<Dictionary[]>("Dictionary/GetInsurerDictionaries",`?insurerId=${insurerId}&scopeId=${scopeId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseDictionaries(caseId: number, scopeId:number): Promise<Dictionary> {
    let res = BaseService.get<Dictionary>("Dictionary/GetCaseDictionaries",`?caseId=${caseId}&scopeId=${scopeId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getContactDictionaries(contactADId: string, scopeId:number): Promise<Dictionary> {
    let res = BaseService.get<Dictionary>("Dictionary/GetContactDictionaries?",`?contactADId=${contactADId}&scopeId=${scopeId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getDictionaryItemTranslations(dictionaryGuid: string): Promise<Dictionary[]> {
    let res = BaseService.get<string>("Dictionary/GetDictionaryItemTranslations?",`dictionaryGuid=${dictionaryGuid}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateDictionaryItem(data: Dictionary) {

    let res = BaseService.update<Dictionary>("Dictionary/UpdateDictionaryItem", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postNewDictionaryItem(data: Dictionary) {
    let res = BaseService.create<Dictionary>("Dictionary/CreateDictionaryItem", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postNewDictionaryItemTranslation(data: Dictionary) {
    let res = BaseService.create<Dictionary>("Dictionary/CreateDictionaryItemTranslation", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteDictionaryItem(dictionaryGuid: string): Promise<any> {
    let res = BaseService.delete("Dictionary/RemoveDictionary?dictionaryGuid=", dictionaryGuid).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function checkIfDictionaryInUse(dictionaryGuid: string): Promise<number> {
    let res = BaseService.get<number>("Dictionary/CheckIfDictionaryInUse",`?dictionaryGuid=${dictionaryGuid}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
///Notification Settings services
async function postUpdateNotificationSettingItem(data: NotificationSettings) {

    let res = BaseService.update<NotificationSettings>("NotificationSetting/UpdateNotificationSetting", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postNewNotificationSettingItem(data: NotificationSettings) {
    let res = BaseService.create<NotificationSettings>("NotificationSetting/CreateNotificationSetting", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllNotificationSettingDictionaries(): Promise<Array<any>> {
    let res = BaseService.getAll<any>("NotificationSetting/GetAllNotificationSettingDictionaries").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getScopedNotificationSetting(scopes:IDicScope[]): Promise<Array<NotificationSettings>> {
    let res = BaseService.post<any>("NotificationSetting/GetScopedNotificationSetting",{scopes}).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getNotificationSetting(notificationSettingItemId: number): Promise<NotificationSettings> {
    let res = BaseService.get<NotificationSettings>("NotificationSetting/GetSingelNotificationSetting?notificationSettingId=", notificationSettingItemId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function runWebJob(webJobName: string): Promise<string> {
    let res = BaseService.get<string>("Administration/RunWebJob?webJobName=", webJobName).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteNotificationSetting(notificationSettingId: number): Promise<any> {
    let res = BaseService.delete("NotificationSetting/RemoveNotificationSetting?notificationSettingId=", notificationSettingId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function updateNotificationSettingsNextRunDate(notificationSettings: NotificationSettings[]): Promise<string> {
    let res = BaseService.post<NotificationSettings[]>("NotificationSetting/UpdateNotificationSettingsNextRunDate", notificationSettings).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function updateNotificationSettingIsEnabled(notificationSettings: any): Promise<string> {
    let res = BaseService.post<NotificationSettings>("NotificationSetting/UpdateNotificationSettingIsEnabled", notificationSettings).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
export enum ContactType{
    CAM,
    CLN,
    DOC,
    EMP
}