
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { useTable, useSortBy } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlusCircle} from '@fortawesome/pro-light-svg-icons'
import { AddEditTask } from './AddEditTask';
import { Modal, ModalBody } from 'reactstrap';
import { useContext, useMemo, useState } from 'react';
import { ITaskViewModel, Task } from '../../models/Task';
import React from 'react';
import { faArrowUpArrowDown } from '@fortawesome/pro-light-svg-icons';


export interface IListTasksProps {
    deleteTask: (id: number) => void,
    add: (task: Task) => void,
    edit: (task: Task) => void,
    tasks?: Array<ITaskViewModel>,
    loading?: boolean,
    taskTemplateId?:number,
    taskTagsOptions:any[],
    casePhaseOptions:any[],
    taskPlanOptions:any[],
    listType?:string,
    addMultipleTasks?: (task: ITaskViewModel[]) => void,
}

const ListTasks = ({listType, taskTemplateId,deleteTask, edit, add, tasks, loading,taskTagsOptions, casePhaseOptions, taskPlanOptions }: IListTasksProps) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const [open, setOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState<any>({})
    const editTask = (task: Task) => {
        setSelectedTask(task);
        setOpen(true);
    }
    const removeTask = (task: Task) => {
        deleteTask(task.id);
        //setOpen(false);
    }
    const addTask = () => {
        setOpen(true);
        setSelectedTask({});
    }
    const handleClose = () => {
        setOpen(false);
    };
    const columns = React.useMemo(
        () => [
            {
                Header: t("ALL.Tasks.Title"),
                accessor: 'taskTitle',
            },
            {
                Header: t("ALL.Tasks.DueDate"),
                accessor: 'dueTimeString',
                sortType: customSortFn
            },
            {
                Header: t("ALL.Tasks.Status.StatusTitle"),
                accessor: 'taskStatusString',
            },
            {
                Header: t("ALL.Tasks.Priority.PriorityTitle"),
                accessor: 'priorityString',
            },
            {
                Header: t("CAM.Case.Phases.PhaseTitle"),
                accessor: 'casePhaseTagString',
            },
            {
                Header: t("ALL.Tasks.Actions"),
                accessor: 'action',
                disableSortBy: true,
                Cell: row => (<div>
                    <button onClick={() => editTask(row.row.original)} className="btn btn-md btn-icon-grey">
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button onClick={() => removeTask(row.row.original)} className="btn btn-md btn-icon-red">
                        <FontAwesomeIcon icon={faTrash} />{row.row.original.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    </button>
                </div>
                ),
            },

        ],
        []
    );   
   const data = useMemo(() => [...tasks?tasks:[]], [tasks?tasks:[]])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    },
    useSortBy)

    function customSortFn(a, b) {
        var a1 = new Date(a.original.dueTime).getTime();
        var b1 = new Date(b.original.dueTime).getTime();
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
    }
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header card-header-tabs card-header-primary header-top">
                        <div className="card-category">
                            {/* <div className="btn-group">
                                <button type="submit" className="btn btn-md btn-icon-grey mr-1" onClick={addTask}>
                                    <FontAwesomeIcon icon={faPlusCircle} />{t("ALL.Tasks.Button.AddTask")}
                                </button>
                            </div> */}
                                {/* <div>
                                    <ReactMultiSelectCheckboxes placeholderButtonLabel="Filter" options={options} /> 
                                </div> */}
                                
                                <div className="d-flex justify-content-between">
                                    <div className="p-2 col-example text-left">
                                        <div className="btn-group">
                                            <button type="submit" className="btn btn-md btn-icon-grey mr-1" onClick={addTask}>
                                                <FontAwesomeIcon icon={faPlusCircle} />{t("ALL.Tasks.Button.AddTask")}
                                            </button>
                                        </div>
                                    </div>
                                    {/* filter to be implemented */}
                                    {/* <div className="p-2 col-example text-left"></div>
                                    <div className="p-2 col-example text-left" style={{marginRight: "142px"}}>
                                        <div>
                                            <ReactMultiSelectCheckboxes placeholderButtonLabel="Filter" options={options} /> 
                                        </div>
                                    </div> */}
                                </div>

                               
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped" {...getTableProps()}>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼'  : column.Header!=="Task Aktion" && column.Header!=="Task Action" ?<FontAwesomeIcon icon={faArrowUpArrowDown} /> : ""}
                                            </span>
                                            </th>
                                        ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {loading ?
                                        <tr>
                                            <td colSpan={6} className="text-center">
                                                <div className="spinner-border spinner-border-lg align-center"></div>
                                            </td>
                                        </tr> :
                                        (rows.length > 0 && rows.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    })}
                                                </tr>
                                            );
                                        })) ||
                                        <tr>
                                            <td colSpan={6} className="text-center">
                                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                                            </td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={open} style={{ maxWidth: "1000px" }}>
                <ModalBody>
                    <AddEditTask task={selectedTask} 
                    taskTemplateId={taskTemplateId} 
                    close={handleClose} 
                    add={add} edit={edit} 
                    view={false} cleanSelectedNode={setSelectedTask} 
                    listType={listType}
                    taskTagsOptions={taskTagsOptions} casePhaseOptions={casePhaseOptions} taskPlanOptions={taskPlanOptions} selectedChip={""}/>
                </ModalBody>
            </Modal>
        </div>
    );

}

export { ListTasks };