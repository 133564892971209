import { faBusinessTime, faClock, faSackDollar } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Col, Container, Row } from "react-bootstrap";
import "../../assets/css/costs.css";
import logo from '../../assets/images/logo.png';
import logoMobile from '../../assets/images/mobile.png';
import drop from '../../assets/images/drop.svg';
import { useContext } from 'react';

interface IWebDetailClientCalculationProps {
    selectedCalc:any
    close?: any;
}
const WebDetailClientCalculation=({selectedCalc,close}:IWebDetailClientCalculationProps)=> {
  const { t } = useContext(MultiLanguageSupportContext);
  return (<Container className="web-calc">
    <Row className="justify-content-md-center row-margins">
      <Col xs={3}>
        <span className="head-text box">{t("ALL.Settings.WebSite.Common.StandardCase")}</span>
      </Col>
      <Col xs={3}></Col>
      <Col xs={3}>
        <Row>
          <Col>
            <img src={logo} className="appIcon" alt="logo" />
            <img src={logoMobile} className="appIconMobile" alt="logo" />
          </Col>
        </Row>
      </Col>
      <Col xs={3}></Col>
    </Row>
    <Row className="justify-content-md-center row-margins">
      <Col xs={3}>
        <div className="p-4 mb-2 bg-allowance-left text-white" style={{ width: `${selectedCalc.barCalculationResult.dailyAllowanceLeft}%` }}></div>
      </Col>
      <Col xs={3} className="text-allowance">
        <div>
          <div><FontAwesomeIcon icon={faBusinessTime} className="fas fa-2x" /></div>
          <div>{t("ALL.Settings.WebSite.Common.SavingsDailyAllowance")}</div>
        </div>
      </Col>
      <Col xs={3} className="mobile-padding-right">
        <div className="p-4 mb-2 bg-allowance-right text-white" style={{ width: `${selectedCalc.barCalculationResult.dailyAllowanceRight}%` }}></div>
      </Col>
      <Col xs={3} className="mobile-padding-left">
        <div className="tear">
          <img src={drop} className="drop" alt="logo" />
          <p className="tear-number">{`${selectedCalc.calculationResult.savings}%`}</p>
          <p className="tear-text">{t("ALL.Settings.WebSite.Common.SavingsCost")}</p>
        </div>
      </Col>
    </Row>
    <Row className="justify-content-md-center row-margins">
      <Col xs={3}>
        <div className="p-4 mb-2 bg-time-left text-white" style={{ width: `${selectedCalc.barCalculationResult.timeLeft}%` }}></div>
      </Col>
      <Col xs={3} className="text-time">
        <div>
          <div><FontAwesomeIcon icon={faClock} className="fas fa-2x" /></div>
          <div>{t("ALL.Settings.WebSite.Common.SavingsTime")}</div>
        </div>
      </Col>
      <Col xs={3}>
        <div className="p-4 mb-2 bg-time-right text-white" style={{ width: `${selectedCalc.barCalculationResult.timeRight}%` }}></div>
      </Col>
      <Col xs={3}></Col>
    </Row>
    <Row className="justify-content-md-center row-margins">
      <Col xs={3}>
        <div className="p-4 mb-2 bg-fee-left-coordinator text-white" style={{ width: `${selectedCalc.barCalculationResult.feeCoordinatorLeft}%` }}></div>
        <div className="p-4 mb-2 bg-fee-left-doctor text-white" style={{ width: `${selectedCalc.barCalculationResult.feeDoctorLeft}%` }}></div>
      </Col>
      <Col xs={3}>
        <div>
          <div className="text-fee"><FontAwesomeIcon icon={faSackDollar} className="fas fa-2x" /></div>
          <div className="text-fee">{t("ALL.Settings.WebSite.Common.FeesLicense")}</div>
          <div className='legend'>
            <div className='legend-scale'>
              <ul className='legend-labels'>
                <li><span className="fee bg-fee-right-coordinator"></span>{t("ALL.Settings.WebSite.Common.FeeCoordinator")}</li>
                <li><span className="fee bg-fee-right-doctor"></span>{t("ALL.Settings.WebSite.Common.FeeDoctor")}</li>
                <li><span className="fee bg-fee-license"></span>{t("ALL.Settings.WebSite.Common.FeeLicense")}</li>

              </ul>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={3}>
        <div className="box">
          <div className="p-4 mb-2 bg-fee-right-coordinator text-white" style={{ width: `${selectedCalc.barCalculationResult.feeCoordinatorRight}%`, float: "left" }}></div>
          <div className="p-4 mb-2 bg-fee-right-doctor text-white" style={{ width: `${selectedCalc.barCalculationResult.feeDoctorRight}%`, float: "left" }}></div>
          <div className="p-4 mb-2 bg-fee-license text-white" style={{ width: `${selectedCalc.barCalculationResult.feeLicense}%` }}></div>
        </div>
      </Col>
      <Col xs={3}></Col>
    </Row>
      <hr></hr>
      {t("ALL.Settings.WebSite.Common.SavingsSummary")}
      <hr></hr>
      <Row className="justify-content-md-center">
        <Col xs={3}>
        </Col>
        <Col xs={3}>
          <b>{t("ALL.Settings.WebSite.Common.StandardCase")}</b>
        </Col>
        <Col xs={3}>
        <b>CaseDesk</b>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={3}>
          {t("ALL.Settings.WebSite.Common.DailyAllowence")}
        </Col>
        <Col xs={3}>
          {selectedCalc.calculationResult.dailyAllowanceLeft}
        </Col>
        <Col xs={3}>
          {selectedCalc.calculationResult.dailyAllowanceRight}
        </Col>
      </Row>
      <Row className="justify-content-md-center ">
        <Col xs={3}>
        {t("ALL.Settings.WebSite.Common.Time")}
        </Col>
        <Col xs={3}>
          {selectedCalc.calculationResult.timeLeft}
        </Col>
        <Col xs={3}>
          {selectedCalc.calculationResult.timeRight}
        </Col>
      </Row>
      <Row className="justify-content-md-center ">
        <Col xs={3}>
        {t("ALL.Settings.WebSite.Common.FeeDoctor")}
        </Col>
        <Col xs={3}>
          {selectedCalc.calculationResult.feeDoctorLeft}
        </Col>
        <Col xs={3}>
          {selectedCalc.calculationResult.feeDoctorRight}
        </Col>
      </Row>
      <Row className="justify-content-md-center ">
        <Col xs={3}>
        {t("ALL.Settings.WebSite.Common.FeeCoordinator")}
        </Col>
        <Col xs={3}>
          {selectedCalc.calculationResult.feeCoordinatorLeft}
        </Col>
        <Col xs={3}>
          {selectedCalc.calculationResult.feeCoordinatorRight}
        </Col>
      </Row>
      <Row className="justify-content-md-center ">
        <Col xs={3}>
          {t("ALL.Settings.WebSite.Common.FeeTotal")}
        </Col>
        <Col xs={3}>
          {selectedCalc.calculationResult.feeLeft}
        </Col>
        <Col xs={3}>
          {selectedCalc.calculationResult.feeRight}
        </Col>
      </Row>
      <Row className="justify-content-md-center ">
        <Col xs={3}>
        {t("ALL.Settings.WebSite.Common.FeeLicense")}
        </Col>
        <Col xs={3}>
          -
        </Col>
        <Col xs={3}>
          {selectedCalc.calculationResult.feeLicense}
        </Col>
      </Row>

  </Container>
  );
};
export { WebDetailClientCalculation };