import { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { adminService, alertService} from '../../services/Index';
import { faList } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import NotificationTemplates from '../../models/NotificationTemplates';

const TemplateManagement=()=> {
    const { path } = useParams();
    const [templates, setTemplates] = useState<NotificationTemplates[]>();
    const { t } = useContext(MultiLanguageSupportContext);
    
    useEffect(() => {
        adminService.getAllTemplates().then(
            (x) => {
                setTemplates(x);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );

    }, []);
    return (
        <div className="container">
            <p className="component-header">{t("ALL.Templates.Overview")}</p>
            <hr></hr>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: '15%' }}>{t("ALL.Templates.Title")}</th>
                        <th style={{ width: '20%' }}>{t("ALL.Button.Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {templates && templates.map(s =>
                        <tr key={s.id}>
                            <td>{s.title}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`../edit-template/${s.id}`} className="btn btn-md btn-icon-blue mr-1"><FontAwesomeIcon icon={faList} /></Link>
                            </td>
                        </tr>
                    )}
                    {!templates &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="spinner-border spinner-border-lg align-center"></div>
                            </td>
                        </tr>
                    }
                    {templates && !templates.length &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );

}

export { TemplateManagement };