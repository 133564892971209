
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faEye, faUserInjured } from '@fortawesome/pro-light-svg-icons'
import { AddEditNote } from './AddEditNote';
import { Modal, ModalBody } from 'reactstrap';
import { useContext, useState } from 'react';
import Note from '../../models/Note';
import { Link } from 'react-router-dom';
import { faIndustry, faPlusCircle, faShieldAlt,faStethoscope} from '@fortawesome/pro-light-svg-icons'
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
interface IListNotesProps {
    deleteNote: (id: number) => void,
    add: (note: Note) => void,
    edit: (note: Note) => void,
    notes?: Array<any>,
    contactNotes: boolean,

}

const ListNotes=({ deleteNote, edit, add, notes, contactNotes }: IListNotesProps)=> {
    const { t } = useContext(MultiLanguageSupportContext);
    const [modalIsOpen, setModalVisibility] = useState(false);
    const [selectedNote, setSelectedNote] = useState<any>({});
    const { authUserData } = useContext(LoggedUserDataContext);
    function addNote() {
        setModalVisibility(true);
    }
    function editNote(note: Note) {
        setSelectedNote(note);
        setModalVisibility(true);

    }

    return (
        <div className="row">
            <div className="col-md-8">
                <div className="card">
                    {contactNotes && <div className="card-header card-header-tabs card-header-primary header-top">
                        <div className="card-category">
                            <div className="btn-group">
                                <button type="submit" className="btn btn-md btn-icon-grey mr-1" onClick={addNote}>
                                    <FontAwesomeIcon icon={faPlusCircle} />{t("ALL.Notes.Add")}
                                </button>
                            </div>
                        </div>
                    </div>}
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead className="text-primary">
                                    <tr>
                                        <th>{t("ALL.Notes.Date")}</th>
                                        <th>{t("ALL.Notes.Title")}</th>
                                        <th>{t("ALL.Notes.SharedBy")}</th>
                                        <th>{t("ALL.Notes.SharedNotes")}</th>
                                        <th>{t("ALL.Button.Actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {notes && notes.map(n => <>
                                        <tr key={n.id}>
                                            <td>{moment(n.noteDate).format("L")}</td>
                                            <td>{n.noteTitle}</td>
                                            <td>{n.contactDisplayName}</td>
                                            <td>
                                                {n.recipients.includes("CLN") && <span className="btn-sm btn-icon-cln"><FontAwesomeIcon icon={faUserInjured} /></span>}
                                                {n.recipients.includes("DOC") && <span className="btn-sm btn-icon-doc"><FontAwesomeIcon icon={faStethoscope} /></span>}
                                                {n.recipients.includes("CAM") && <span className="btn-sm btn-icon-cam"><FontAwesomeIcon icon={faShieldAlt} /></span>}
                                                {n.recipients.includes("EMP") && <span className="btn-sm btn-icon-emp"><FontAwesomeIcon icon={faIndustry} /></span>}

                                            </td>
                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                <Link to={`/${authUserData.currUserData.rolePath}/view-note/${n.id}/No`} className="btn btn-md btn-icon-green mr-1"><FontAwesomeIcon icon={faEye} /></Link>
                                                {contactNotes && <>
                                                    <button onClick={() => editNote(n)} className="btn btn-md btn-icon-grey">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button onClick={() => deleteNote(n.id)} className="btn btn-md btn-icon-red">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </>}
                                            </td>
                                        </tr>
                                    </>
                                    )}
                                    {!notes &&
                                        <tr>
                                            <td colSpan={5} className="text-center">
                                                <div className="spinner-border spinner-border-lg align-center"></div>
                                            </td>
                                        </tr>
                                    }
                                    {notes && !notes.length &&
                                        <tr>
                                            <td colSpan={5} className="text-center">
                                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-header card-header-tabs card-header-primary header-top">
                            <h4>{t("ALL.Notes.RoleLegend")}</h4>
                        </div>
                        <div className="card-body">
                            <div className='legend'>
                                <div className='legend-scale'>
                                    <ul>
                                        <li>
                                            <span className="btn-sm btn-icon-cln"><FontAwesomeIcon icon={faUserInjured} /></span>
                                            <label className="form-check-label">{t("ALL.Notes.SharedWithCLN")}</label>
                                        </li>

                                        <li>
                                            <span className="btn-sm btn-icon-doc"><FontAwesomeIcon icon={faStethoscope} /></span>
                                            <label className="form-check-label">{t("ALL.Notes.SharedWithDOC")}</label>
                                        </li>
                                        <li>
                                            <span className="btn-sm btn-icon-cam"><FontAwesomeIcon icon={faShieldAlt} /></span>
                                            <label className="form-check-label">{t("ALL.Notes.SharedWithCAM")}</label>
                                        </li>
                                        <li>
                                            <span className="btn-sm btn-icon-emp"><FontAwesomeIcon icon={faIndustry} /></span>
                                            <label className="form-check-label">{t("ALL.Notes.SharedWithEMP")}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                  
                </div>
            </div>


            <Modal isOpen={modalIsOpen} style={{ maxWidth: "700px" }}>
                <ModalBody>
                    <AddEditNote note={selectedNote} close={setModalVisibility} add={add} edit={edit} cleanSelectedNode={setSelectedNote} />
                </ModalBody>
            </Modal>
        </div >



    );

}

export { ListNotes };