// import './styles.css';
import { useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import { faBell, faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { adminService } from '../../../services/casedesk/admin/AdminService';
import { alertService } from '../../../services/AlertService';
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import { add } from 'date-fns/esm';
import { Dictionary, IDictionaryViewModel } from '../../../models/Dictionary';
import { DictionaryTranslationEdit } from './DictionaryTranslationEdit';



const DictionaryTranslationManagement = ({ updateSelectedDictionary, translationList, closeModal }) => {
  const { t } = useContext(MultiLanguageSupportContext);


  return (
    <div className="container">
      <div className="row table-row" >
        <div className="col-md-12">

          <table className="table table-striped">
            <tbody>

              {translationList && translationList.map(d => <>
                <tr key={d.id}>
                  <td>
                    <DictionaryTranslationEdit translation={d} setSelectedItem={updateSelectedDictionary} closeModal={closeModal}/>
                  </td>
                </tr>
              </>
              )}
              {!translationList &&
                <tr key="spinner">
                  <td colSpan={5} className="text-center">
                    <div className="spinner-border spinner-border-lg align-center"></div>
                  </td>
                </tr>
              }
              {translationList && !translationList.length &&
                <tr key="no-data">
                  <td colSpan={5} className="text-center">
                    <div className="p-2">{t("ALL.Common.NoData")}</div>
                  </td>
                </tr>
              }

            </tbody>
          </table>
        </div>
      </div>
    </div>

  );
};


export default DictionaryTranslationManagement;
